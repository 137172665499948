const transformColumns = (data) => {
  // eslint-disable-next-line no-shadow
  const transformData = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
      return [];
    }

    // Obtener todas las columnas únicas
    const columns = new Set();
    data.forEach((row) => {
      Object.keys(row).forEach((column) => {
        columns.add(column);
      });
    });

    // Crear el formato deseado con render para manejar celdas vacías
    const transformedColumns = Array.from(columns).map((column) => ({
      title: column,
      dataIndex: column,
      key: column,
      render: (text) => text===""? <span style={{whiteSpace:"pre"}}> {"\t"}</span>:text, // Si el valor es vacío, muestra un espacio en blanco
    }));

    return transformedColumns;
  };

  const transformedData = transformData(data);

  return transformedData;
};

export default transformColumns;
