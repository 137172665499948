/* eslint-disable arrow-body-style */
import React from "react";
// import { useQuill } from "react-quilljs";
import { Input, Form, Button, Tooltip } from "antd";
import "quill/dist/quill.snow.css";



const ComparativeAnalysisForm = ({ form, quillRef, onSubmit }) => {
  // eslint-disable-next-line no-unused-vars


  return (
    <div className="w-full ">
      <Form
        form={form}

      >


        <div className="py-0 flex">

          <Tooltip title="Nombra este análisis comparativo" >
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Por favor ingrese un título" }]}
              className="mb-2 w-3/4"
            >

              <Input
                className="text-left font-bold text-2xl text-lightBlue2 bg-white_custom"
                variant="Borderless"
                defaultValue="Nombra este análisis comparativo"
              />

              {/* <Input
              // placeholder=" Sin Titulo"
              className="text-left font-bold text-2xl text-lightBlue2 bg-white_custom "
              variant="Borderless"
              defaultValue="Nombra este análisis comparativo "
            /> */}
            </Form.Item>
          </Tooltip>

          <div className="w-1/4 flex justify-end items-end">

            <Button
              shape="round"
              type="primary"
              className="font-Poppins shadow-lg rounded-full bg-lightBlue2 text-white_custom  font-medium mb-2"
              onClick={onSubmit}
            >
              Guardar cambios
            </Button>

          </div>
        </div>

      </Form>
      <div className="rounded-[9px]">


        <div className=" bg-white" ref={quillRef} />

      </div>
    </div>
  )
};


export default ComparativeAnalysisForm;