import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
// import ROUTES from "../../../constant/router";
import RoutesBreadcrumb from "../../../constant/breadcrumbRoutes";

// Helper function to match dynamic routes
const matchRoute = (routeKey, pathname) => {
  const routeSegments = routeKey.split("/").filter(Boolean);
  const pathSegments = pathname.split("/").filter(Boolean);

  // If segments length is not the same, they don’t match
  if (routeSegments.length !== pathSegments.length) return false;

  return routeSegments.every((segment, index) => segment.startsWith(":") || segment === pathSegments[index]);
};

const AppBreadcrumb = () => {
  const location = useLocation();
  const {pathname} = location;

  // eslint-disable-next-line no-shadow
  const findBreadcrumbPath = (routes, pathname) => {
    // Use .reduce to search for the breadcrumb path
    const path = routes.reduce((acc, route) => {
      // If a path has already been found, stop processing
      if (acc.length > 0) return acc;

      // Check if the route matches, including dynamic segments
      if (route.key === pathname || matchRoute(route.key, pathname)) {
        return [route];
      }

      // Check if the route has children and search within them
      if (route.children) {
        const child = route.children.find(
          (childRoute) =>
            childRoute.key === pathname || matchRoute(childRoute.key, pathname)
        );
        if (child) {
          return [route, child]; // Return parent and child if child matches
        }
      }

      return acc; // Return unchanged accumulator if no match
    }, []);

    return path.length > 0 ? path : null; // Return null if no path is found
  };

  // Get the breadcrumb path for the current pathname
  const breadcrumbPath = findBreadcrumbPath(RoutesBreadcrumb, pathname);

  // Do not render breadcrumb if on the root path "/"
  if (pathname === "/") return null;

  // Map the breadcrumb path to Breadcrumb.Item components
  const breadcrumbItems = breadcrumbPath
    ? [
      {
        href: "/",
        title: <HomeOutlined />,
      },
      ...breadcrumbPath.map((item) => ({
        href: item.key,
        title: item.label,
      })),
    ]
    : [];

  return <Breadcrumb items={breadcrumbItems} />;
};

export default AppBreadcrumb;
