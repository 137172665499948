const COGNITO = {
  REGION: "us-east-1",
  USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
  APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID,
  DOMAIN: process.env.REACT_APP_APP_URL_COGNITO,
};

const amplifyConfig = {
  /* aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
*/
  aws_project_region: COGNITO.REGION,
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
  oauth: {
    domain: COGNITO.DOMAIN,
    scope: ["email", "openid"],
    redirectSignIn: "http://localhost:3000/dashboard",
    redirectSignOut: "http://localhost:3000/login",
    clientId: COGNITO.APP_CLIENT_ID,
    responseType: "code",
  },
};

export default amplifyConfig;
