/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { Form, ConfigProvider } from "antd";
// import AppUpload from "../../molecules/Upload/AppUpload";
// import axios from "axios";
import AppText from "../../atoms/Text/AppText";
import FormSelect from "../../molecules/FormSelect/FormSelect";
import SubmitButton from "../../atoms/Buttons/SubmitButton";
import UploadExcel from "../../molecules/Upload/UploadExcel";
import FormInput from "../../molecules/FormInput/FormInput";
import { selectWhiteStyle } from "../../../assets/theme/themeConfig";

// import FormInput from "../../molecules/FormInput/FormInput";

const DocumentUploadForm = ({ options, onClickNext, docTypeWeb, seturlWebSource }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [uploadValidated, setUploadValidated] = useState(false);
  const [isWebSource, setIsWebSource] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [urlValid, setUrlValid] = useState(true);

  useEffect(() => {
    if (fileList.length > 0 || isWebSource) {
      setUploadValidated(true);
    } else {
      setUploadValidated(false);
    }
  }, [fileList, isWebSource]);

  // const validateURL = async (url) => {
  //   try {
  //     setLoading(true);
  //     const response = await axios.get(url);
  //     setLoading(false);
  //     return response.status === 200;
  //   } catch (error) {
  //     setLoading(false);
  //     return false;
  //   }
  // };
  // const handleUrlBlur = async () => {
  //   const url = form.getFieldValue("urlWebSource");
  //   if (url) {
  //     const isValid = await validateURL(url);
  //     setUrlValid(isValid);
  //     if (!isValid) {
  //       form.setFields([
  //         {
  //           name: "urlWebSource",
  //           errors: ["La URL no es válida, por favor ingrese una URL válida."],
  //         },
  //       ]);
  //     }
  //   }
  // };
  const handleSubmitButton = (data) => {
    if (isWebSource) {
      onClickNext(data);
      // if (urlValid) {
      //   console.log("data", data);

      //   // onClickNext(data);
      // } else {
      //   //mostar mensaje de error en el campo de la url
      //   form.setFields([
      //     {
      //       name: "urlWebSource",
      //       errors: ["La URL no es válida, por favor ingrese una URL válida."],
      //     },
      //   ]);
      // }
      seturlWebSource(data.urlWebPage);
    } else {
      const auxData = { ...data, upload: fileList[0] };
      onClickNext(auxData);
    }
  };

  const onChangeSource = (selectedValue) => {
    if (selectedValue === docTypeWeb) {
      setIsWebSource(true);
    } else {
      setIsWebSource(false);
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: selectWhiteStyle,
        },
      }}
    >
      <Form form={form} onFinish={handleSubmitButton} className="p-5">
        <div className="flex flex-col items-center border rounded-[32px] border-gray_custom px-2 py-5">
          <div className=" flex flex-col items-center">
            <div className=" text-center mx-8 pb-2">
              <AppText
                title="Seleccione el tipo de fuente a la cual pertenecen los datos a cargar."
                className="font-light text-color_text  text-center "
                style={{ maxWidth: "542px" }}
              />
            </div>
            <div className="w-full sm:w-1/2">
              <FormSelect
                name="sourceType"
                label="Tipo de fuente :"
                placeholder="Selecciona una opción"
                className="font-semibold text-white_custom"
                selectClass="bg-lightBlue"
                labelCol={{ span: 24 }}
                size="small"
                options={options}
                rules={[
                  {
                    required: true,
                    message: "El tipo de fuente es obligatorio",
                  },
                ]}
                onChange={onChangeSource}
              />
            </div>
          </div>

          {!isWebSource ? (
            <div className="flex flex-col items-center">
              <div className=" text-center mx-8 pb-2 max-w-[542px] ">
                <AppText
                  title="El documento excel puede tener una o multiples hojas."
                  className="font-light text-color_text  text-center  max-w-[542px]  "
                />
                <br />
                <AppText title="Cargar archivos de tipo XLSX" /* , XLS y CSV */ className="text-color_text text-center  px-2 pb-4 font-semibold " />
              </div>
              <div className="w-full">
                <Form.Item name="upload">
                  <UploadExcel title="Importar" settingFileList={setFileList} />
                </Form.Item>
              </div>
            </div>
          ) : (
            <div className=" flex flex-col items-center">
              <div className=" text-center mx-8 pb-2 max-w-[542px]">
                <AppText
                  title="Por favor, ingrese la URL de la página web que desea guardar para futuras consultas."
                  className="font-light text-color_text  text-center max-w-[542px] "
                />
              </div>

              <FormInput
                name="urlWebPage"
                label="Url de fuente web:"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                  {
                    type: "url",
                    message: "La URL no es válida",
                  },
                  {
                    max: 255,
                    message: "La URL no puede tener más de 255 caracteres",
                  },
                ]}
                // onBlur={handleUrlBlur}
                className="w-[302px]"
              />
            </div>
          )}
        </div>
        <div className="h-10">
          <SubmitButton
            nameButton="Subir"
            className=" mt-5"
            htmlType="submit"
            buttonColor="bg-lightGreen"
            // loading={loading}
            form={form}
            doubleCheckValidation={uploadValidated}
          />
        </div>
      </Form>
    </ConfigProvider>
  );
};

export default DocumentUploadForm;
