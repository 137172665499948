import React from "react";
import { Form, DatePicker } from "antd";
// import { ArrowRightOutlined } from "@ant-design/icons";
// import "../../../index.css";
/* eslint-disable react/jsx-props-no-spreading */

const { RangePicker } = DatePicker;

const FormDateRange = ({ name, label, className = "",  labelCol = "",requiredActive = true, ...props }) => {
  const combinedClassName = `font-Poppins font-light text-sizeBase text-black_custom  ${className}`;
  return (
    <Form.Item
      labelCol={labelCol}
      colon={false}
      name={name}
      label={label ? <span className="font-Poppins text-color_text">{label}</span> : null}
      rules={ requiredActive ? [
        {
          required: true,
          message: "Por favor seleccione un rango de fecha!",
        },
      ] : null}
      className={combinedClassName}
      {...props}
    >
      <RangePicker
        className="font-Poppins font-light  rounded-full text-black_custom"
        placeholder={["Fecha inicio", "Fecha fin"]}
        sharpes="round"
        style={{ width: "100%" }}
        format="YYYY-MM-DD"
        // nextIcon={<ArrowRightOutlined />}
        
      />
      
    </Form.Item>
  )
};

export default FormDateRange;
