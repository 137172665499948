// import "../../../index.css";
import React from "react";
import { Form } from "antd";
import AppInput from "../../atoms/Input/AppInput";
/* eslint-disable react/jsx-props-no-spreading */

const FormEmail = ({ name, label, placeholder, className = "", labelCol="", ...props }) => {
  const combinedClassName = `font-Poppins font-light  text-color_text ${className} !important`; 
  return (
    <Form.Item
      name={name}
      labelCol={labelCol}
      colon={false}
      label={label? <span className="font-Poppins font-light  text-color_text">{label}</span>: null}
      rules={[
        {
          type: "email",
          message: "El correo no es válido",
        },
        {
          required: true,
          message: "Por favor ingrese su correo",
        },
      ]}
      className={combinedClassName}
      {...props}
    >
      <AppInput 
        placeholder={placeholder} 
        className="text-black_custom " 
      />
    </Form.Item>
  )
};

export default FormEmail;

