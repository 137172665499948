// App.js
import React, { useState } from "react";
import { Row, Col } from "antd";
import AppTitle from "../../atoms/Title/AppTitle";
import ReporItems from "../../organisms/ReportItems/ReportItems";
import GenderDistribution from "../../molecules/ReportDistribution/GenderDistribution";
import AppImg from "../../atoms/Img/AppImg";
import indicador from "../../../assets/image/indicaGraf.png";

const ReportTemplate = () => {
  const [zones, setZones] = useState([]);
  const [years, setYears] = useState([]);

  const setSelectedZones = (event, item) => {
    if (event === "select") {
      setZones([...zones, item])
    } else {
      const newZones = zones.filter(zone => zone !== item);
      setZones(newZones)
    }
  }
  const setSelectedYears = (event, item) => {
    if (event === "select") {
      setYears([...years, item])
    } else {
      const newYears = years.filter(year => year !== item);
      setYears(newYears)
    }
  }

  return (
    <Row >
      <Col xs={2} sm={4} md={6} lg="430px" xl="430px">

        <div className="bg-white_custom  h-full  flex flex-col items-center  pt-10 rounded-lg shadow-2xl m-8 py-4" >
          <ReporItems handleItemZone={setSelectedZones}  handleItemyear={setSelectedYears} />

        </div>
      </Col>
      <Col xs={20} sm={16} md={12} lg="878px" xl="878px">

        <div className="h-full bg-white_custom  flex flex-col items-center pt-10 rounded-lg shadow-2xl m-6 py-4" >

          <AppTitle title="Población Femenina y Masculina" level={5} className="text-purple_custom font-bold text-lg text-center !important" />


          <div className="w-4/5  bg-white_custom p-6 rounded-lg shadow-2xl">
            <AppImg src={indicador} alt="Logo" className=" mx-auto w-full  " />
          </div>
        </div>
      </Col>
      <Col xs={2} sm={4} md={6} lg="430px" xl="430px">


        <div className=" bg-white_custom   flex flex-col items-center pt-10 shadow-2xl rounded-lg  m-6 py-4">

          <button type="button" className=" w-11/12 bg-purple_custom text-white_custom font-semibold text-lg py-2 px-4 rounded-lg shadow-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50">
            <span className="block">1.335.521</span>
            <span className="block text-sm">Personas</span>
          </button>
        </div>

        <div className=" h-3/4 bg-white_custom flex flex-col items-center pt-10 shadow-2xl  m-6 py-4">


          <GenderDistribution />






        </div>
      </Col>
    </Row >

  )
};

export default ReportTemplate;

