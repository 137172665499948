import React from "react";
import { Button } from "antd";

const ButtonGroup = () => {
  const classN = "rounded-full bg-lightBlue2 grid text-center text-white_custom font-bold py-2 px-4 mb-4  w-4/5 ";

  return (
    <div className="flex flex-col items-center w-4/5">
      <Button className={classN}  type="primary" shape="round" size="large" >{" Demográficos"}</Button>
      <Button className={classN}  type="primary" shape="round" size="large" >{" Socioeconómicos "}</Button>
      <Button className={classN} type="primary" shape="round" size="large" > {" Mortalidad Materna "}</Button>
      <Button className={classN}   type="primary" shape="round" size="large">{" Mortalidad "}</Button>
      <Button className={classN}   type="primary" shape="round" size="large">{ "Morbilidad "} </Button>
      <Button className={classN}  type="primary" shape="round" size="large" >{" Enfermedades  "}</Button>
    </div>
  );
};

export default ButtonGroup;