import Card from "../../atoms/Card/Card";
import chartTemp from "../../../assets/image/dashboardTemp/chartIndicator.png";
import AppButton from "../../atoms/Buttons/AppButton";

const HomePreviewIndicatorChart = ({ className, onHandleClickChart }) => (
  <Card className={`flex flex-col justify-between ${className}`}>
    <strong>Informe de indicadores trazadores</strong>
    <img src={chartTemp} alt="" />
    <div className="flex justify-between items-center">
      <div>
        <span className="font-medium">Ultima actualización</span>
        <p>2024-07-19T21:18:42</p>
      </div>
      <AppButton
        onClick={onHandleClickChart}
        className="bg-aquamarineBlue !py-0 !px-1 h-7"
        title="Ver gráfica"
      />
    </div>
  </Card>
);

export default HomePreviewIndicatorChart;
