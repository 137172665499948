import React from "react";
import { Input } from "antd";
/* eslint-disable react/jsx-props-no-spreading */

const { TextArea } = Input;
function AppTextArea({ className = "", minRows= 3, maxRows = 8 , value, ...props }) {
  const combinedClassName = `font-Poppins font-light  rounded-[27px]  ${className} !important`;
  return (
    <TextArea {...props}
      className={combinedClassName}
      autoSize={{ minRows, maxRows }}
      value={value}
    />

  );
}
export default AppTextArea;