import { Form } from "antd";
import SubmitButton from "../../atoms/Buttons/SubmitButton";
import FormInput from "../../molecules/FormInput/FormInput";
import FormSelect from "../../molecules/FormSelect/FormSelect";

const ChartCreateForm = ({ handleSubmit, listCharts }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} onFinish={handleSubmit}>
      <FormInput
        name="title"
        label="Título de la gráfica:"
        labelCol={{ span: 24 }}
        styleInput="text-center"
        rules={[
          {
            required: true,
            message: "El título es obligatorio",
          },
        ]}
      />
      <FormSelect
        name="chartType"
        label="Selecciona el tipo de gráfica:"
        className="font-semibold text-color_text text-textTable md:mt-1"
        selectClass="formBgWhite"
        popupclass="font-normal text-color_text text-textTable"
        options={listCharts}
        size="small"
        labelCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: "El tipo de gráfica es obligatorio",
          },
        ]}
      />
      <Form.Item>
        <div className="flex justify-center text-center">
          <SubmitButton nameButton="Guardar" form={form} htmlType="submit" />
        </div>
      </Form.Item>
    </Form>
  );
};

export default ChartCreateForm;
