/* eslint-disable no-unused-vars */
import { EditOutlined, FileDoneOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import AppTitle from "../../atoms/Title/AppTitle";
import transformToTreeSelectRegions from "../../../helpers/transformToTreeSelectRegions";
import InvestigationFormTemplate from "../DocumentTemplate/InvestigationFormTemplate";
import AppModal from "../../molecules/Modal/Modal"; 
import useApi from "../../../api/useApi";
import MapRequest from "../../../api/MapRequest";

import { ResearchState } from "../../../helpers/enums";

const PreviewInfoTemplate = ({ data, onHandleEdit, loadingModal, onchangeState }) => {
  // const [dataDescription, setDataDescription] = useState();
  const [modalActive, setModalActive] = useState(false);
  const getMunicipalityForRegion = useApi(MapRequest.getMunicipalities);
  const [listMunicipalities, setListMunicipalities] = useState([]);
  
  
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState({  
    general: false,
  });

  useEffect(() => {
    if (getMunicipalityForRegion.data) {
      const aux = transformToTreeSelectRegions(
        getMunicipalityForRegion.data.data,
        "subRegion",
        "subRegion",
        "Nariño",
        "municipalityName",
        "municipalityName"
      );
      setListMunicipalities(aux);
      getMunicipalityForRegion.setData();
    }
  }, [data, getMunicipalityForRegion.data]);
  const closeModal = () => {
    setModalActive(false);
    setLoading((prev) => ({ ...prev, general: false }));
  };
  const openModal = () => {
    // setDataDescription();
    setLoading((prev) => ({ ...prev, general: true }));
    setModalActive(true);
  };
  

  const renderModal = () => {
    if (modalActive) {
      return (
        <AppModal
          open={modalActive}
          onCancel={closeModal}
          footer={false}
          className=" text-lightBlue2 text-lg text-center w-full lg:w-1/2"
        >
          <InvestigationFormTemplate
            documentKey={1}
            action="edit"
            dataDescription={data}
            handlerSubmit={onHandleEdit}
            loading={loadingModal}
            listMunicipalities={listMunicipalities}
            hasTitle
          />
        </AppModal>
      );
    }
    return null;
  };
  return (
    <div className="mb-10  w-full   p-5">
      <div className="flex justify-center items-center mb-4">
        <AppTitle
          title={data?.title}
          level={4}
          className="text-lightBlue2 font-bold  text-center !important"
        />
      </div>
      <div className="flex flex-col  w-full">
        
        <div className="flex flex-col space-y-2">
          <p>
            {" "}
            <strong className="text-colorBorder">Resumen</strong>
          </p>
          <p>{data?.abstract}</p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 sm:gap-x-20 gap-y-2 sm:gap-y-5">
          <div className="flex flex-col space-y-2">
            <p>
              Autor:{" "}
              <strong className="text-colorBorder">{data?.author}</strong>
            </p>
            <p className="pt-4">
              Estado de la Investigation: <strong className="text-colorBorder">{ResearchState[data?.state]}</strong>
            </p>
          </div>
          <div className="flex flex-col space-y-2">
            <p>
              Fecha: <strong className="text-colorBorder">{data?.date}</strong>
            </p>
            

          </div>
          <div className="flex flex-col space-y-2">
            <p>
              Localización:{" "}
              <strong className="text-colorBorder">
                {data?.location.length > 0
                  ? data.location.join(", ")
                  : "Ninguno"}
              </strong>
            </p>
          </div>
        </div>
        <div className="flex justify-end items-end pt-4">
          {/* <Button
            className="bg-lightBlue2 shadow-xl text-white_custom hover:text-lightBlue hover:shadow-none hover:bg-white_custom hover:border-lightBlue2"
            icon={<EditOutlined />}
            onClick={() => openModal()}
          >
            Editar
          </Button> */}

          
          <Button
            className="bg-lightBlue2 shadow-xl text-white_custom hover:text-lightBlue hover:shadow-none hover:bg-white_custom hover:border-lightBlue2"
            icon={<FileDoneOutlined />}
            onClick={() => onchangeState()}
          >
            Publicar
          </Button>
        </div>
      </div>
      {renderModal()}
    </div>
  );
};

export default PreviewInfoTemplate;
