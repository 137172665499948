import React, { useState, useEffect } from "react";
import { Upload, message, Form, Button, Tooltip } from "antd";
import { DeleteOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import AppModal from "../Modal/Modal";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const AppImgUpload = ({
  name = "avatar",
  title,
  className,
  settingFileList,
  defaultImageUrl = null,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [flag, setFlag] = useState(false);

  const handleCancel = () => setPreviewOpen(false);

  useEffect(() => {
    if (defaultImageUrl) {
      setFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: defaultImageUrl,
        },
      ]);
    }
  }, [defaultImageUrl]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => {
    settingFileList(newFileList);
    if (
      newFileList.length > 0 &&
      ["image/jpeg", "image/jpg", "image/png"].includes(newFileList[0].type)
    ) {
      const fileStatus = { ...newFileList[0], status: "uploading" };
      handlePreview(fileStatus);
      setFileList([fileStatus]);
    }
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        {title}
      </div>
    </div>
  );

  const onRemove = () => {
    setFileList([]);
    settingFileList([]);
  };

  useEffect(() => {
    if (flag)
      if (fileList && fileList.length > 0) {
        if (fileList[0].originFileObj && fileList[0].status === "uploading") {
          const fileStatus = { ...fileList[0], status: "done" };
          setFileList([fileStatus]);
        }
      }
  }, [fileList]);

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Solo puedes subir archivos JPG/PNG!");
      return true;
    }
    setFileList([file]);
    setFlag(true);
    return false;
  };

  return (
    <Form.Item name={name} className="font-Poppins text-black_custom">
      <div className="flex justify-center">
        {fileList.length > 0 ? (
          <div className="flex justify-center items-center relative rounded-full w-28 h-28">
            <img
              className="absolute rounded-full"
              src={previewImage || fileList[0]?.url}
              alt="Uploaded"
              style={{ maxWidth: "100%" }}
            />
            <div className="absolute flex justify-center items-center opacity-0 hover:opacity-80 rounded-full hover:bg-light_gray w-full h-full">
              <Tooltip title="Previsualizar">
                <Button
                  icon={<EyeOutlined />}
                  onClick={() => setPreviewOpen(true)}
                  className="border-none"
                />
              </Tooltip>
              <Tooltip title="Eliminar">
                <Button
                  icon={<DeleteOutlined />}
                  onClick={onRemove}
                  className="border-none"
                />
              </Tooltip>
            </div>
          </div>
        ) : (
          <Upload
            name="file"
            accept=".jpg, .jpeg,.png"
            multiple={false}
            listType="picture-circle"
            className={className}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            maxCount={1}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
        )}
        <AppModal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img
            alt="example"
            style={{ width: "100%" }}
            src={previewImage || fileList[0]?.url}
          />
        </AppModal>
      </div>
    </Form.Item>
  );
};

export default AppImgUpload;
