import React, {  useEffect, useState } from "react";
import { Drawer, Switch, Input, List,Spin,Button } from "antd";
import { useNavigate } from "react-router-dom";
import { SearchOutlined,ReloadOutlined } from "@ant-design/icons";
import notificationRequest from "../../../api/notificationRequest";
import useApi from "../../../api/useApi";
import ROUTES from "../../../constant/router";

const NotificationsDrawer = ({ visible, onClose, notifications,loading }) => {
  const [onlyUnread, setOnlyUnread] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [listNotifications, setListNotifications] = useState(notifications);
  const updatedNotify = useApi(notificationRequest.UpdateNotification);
  const getNotifications = useApi(notificationRequest.getAllNotifications);
  const navigate = useNavigate();

  const handleToggleUnread = (checked) => {
    setOnlyUnread(checked);
  };
  useEffect(() => {
    
    setListNotifications(notifications);
    
  }, []);

  useEffect(() => {
    if (getNotifications.data) {
      const documents = getNotifications.data.data;
      setListNotifications(documents.items);
      getNotifications.setData();
    }
  }, [getNotifications.data]);

  const filteredNotifications = listNotifications
    .filter((notification) => (onlyUnread ? !notification.isRead : true))
    .filter((notification) =>
      notification.metadata.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
  const refresh = () => {
    getNotifications.request(10, 1);
  };

  const handleNotificationClick = (notification) => {
    
    // eslint-disable-next-line no-underscore-dangle
    updatedNotify.request(notification._id, { is_read: true });
    

    if (notification.metadata.type === "proposal") {
      const path = ROUTES.GENERAL.INDICATORS
      navigate(path);
    }

    if (notification.metadata.type === "research") {

      // eslint-disable-next-line no-underscore-dangle
      const path = ROUTES.GENERAL.INVESTIGATION_REVIEW.replace(":key",notification._id);
      navigate(path);
    }
    // Puedes agregar la lógica adicional para manejar la notificación al hacer clic.
  };
  return (
    <Drawer
      title="Últimas postulaciones"
      placement="right"
      onClose={onClose}
      visible={visible}
      width={700}
    >
      {/* Barra de búsqueda y filtro de no leídas */}
      <div className="flex items-center justify-between mb-4">
        <Input
          placeholder="Buscar"
          prefix={<SearchOutlined />}
          className="rounded-full"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="flex items-center ml-4">
          <span className="mr-2 text-gray-600">Solo no leídas</span>
          <Switch onChange={handleToggleUnread} />
        </div>
        <Button
          type="primary"
          icon={<ReloadOutlined />}
          onClick={refresh}
          className="ml-4"
          loading={loading}
        />

      </div>

      {loading ? (
        <Spin tip="Cargando notificaciones..." />
      ) : (
        <List
          dataSource={filteredNotifications}
          renderItem={(notification) => (
            <div
              role="button"
              tabIndex={0}
              onClick={() => handleNotificationClick(notification)}
              onKeyPress={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleNotificationClick(notification);
                }
              }}
              className={`shadow-2xl rounded-lg p-4 mb-4  cursor-pointer ${
                notification.isRead
                  ? "bg-gray-400 border-gray-200"
                  : "bg-ybg-white border-yellow-300"
              }`}
            >
              <h2 className="font-semibold text-gray-800">
                {notification.type === "proposal" ? `Nueva postulación a : ${notification.metadata.title}` : " "}
              </h2>

              <h3 className="font-semibold text-gray-800">
                {notification.message}
              </h3>
              <p className="text-gray-600 text-sm">
                {new Date(notification.createdAt).toLocaleDateString()}
              </p>
              <p className="text-gray-500 text-sm italic">
                Realizada por:{" "}
                <span className="text-gray-500 font-medium">
                  {notification.userDetails
                    ? `${notification.userDetails.firstName} ${notification.userDetails.lastName}`
                    : "Usuario desconocido"}
                </span>
              </p>
            </div>
          )}
        />
      )}
    </Drawer>
  );
};

export default NotificationsDrawer;
