/* eslint-disable react/no-danger */
import React, { useLayoutEffect, useRef } from "react";
import DOMPurify from "dompurify";
import ReactDOM from "react-dom/client";
import CardChart from "../CardChart/CardChart";
import CardImg from "../CardImg/CardImg";
import "quill/dist/quill.snow.css";
import "../../../assets/css/quill-overrides.css";


const PlotlyContentRenderer = ({ content, dataCharts, handleEditChart, listImg, classRender, isViewInvestigation = false }) => {
  const contentRef = useRef();

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const node = entry.target;
            const { textContent, className } = node;

            // Check if text contains chart marker
            if (textContent.includes("[Gráfica dinámica")) {
              const chartFound = dataCharts.find((item) =>
                textContent.includes(item.id)
              );
              const auxNodeSplit = textContent.split(",");
              const auxNodeName = auxNodeSplit[1].trim();
              const chartWrapper = document.createElement("div");

              // Asignar clase de alineación según la clase del nodo actual
              if (className.includes("ql-align-center")) {
                chartWrapper.classList.add("chart-container-center");
              } else if (className.includes("ql-align-right")) {
                chartWrapper.classList.add("chart-container-right");
              } else if (className.includes("ql-align-left")) {
                chartWrapper.classList.add("chart-container-left");
              } else {
                // Si no hay clase de alineación específica, por defecto centra
                chartWrapper.classList.add("chart-container-center");
              }

              const auxChart = (
                <CardChart
                  typeChart={chartFound?.type}
                  title={auxNodeName}
                  data={chartFound?.data}
                  titleX={chartFound?.columnName?.xAxis}
                  titleY={chartFound?.columnName?.yAxis}
                  isInvestigation
                  isViewInvestigation={isViewInvestigation}
                  handleEdit={() => handleEditChart(chartFound?.id)}
                />
              );

              // Replace node with the chart only when it's visible
              const root = ReactDOM.createRoot(chartWrapper);
              node.replaceWith(chartWrapper); // Replace the node with the wrapper
              root.render(auxChart); // Render the chart

              // Unobserve after rendering the chart
              observer.unobserve(node);
            }

            // Check if text contains image marker
            if (textContent.includes("[Imagen dinámica")) {
              
              
              // const auxNodeSplit = textContent.split(",");
              // const imageId = auxNodeSplit[1].trim();
              if (listImg && listImg.length === 0) return;
              const imageFound = listImg.find((img) => textContent.includes(img.key));
              
              if (imageFound) {
                // Create a wrapper div for the image
                
                const imageWrapper = document.createElement("div");

                // Determine the alignment class
                let alignment = "left";
                if (className.includes("ql-align-center")) {
                  alignment = "center";
                } else if (className.includes("ql-align-right")) {
                  alignment = "right";
                }

                const imageUrl = imageFound.url || URL.createObjectURL(imageFound.file);

                // Create a CardImg component with the image information
                const auxImage = (
                  <CardImg
                    key={imageFound?.key}
                    id={imageFound?.key}
                    url={imageUrl}
                    alignment={alignment}
                  />
                );

                // Replace node with the CardImg only when it's visible
                const root = ReactDOM.createRoot(imageWrapper);
                node.replaceWith(imageWrapper); // Replace the node with the wrapper
                root.render(auxImage); // Render the image card

                // Unobserve after replacing the node with the image
                observer.unobserve(node);
              }
            }
          }
        });
      },
      { threshold: 0.1 } // Adjust this as needed to trigger at different scroll points
    );

    // Attach observer to each child node containing text
    const textNodes = [...contentRef.current.childNodes].filter(
      (node) => node.nodeType === Node.ELEMENT_NODE
    );
    textNodes.forEach((node) => observer.observe(node));

    return () => {
      // Cleanup observer on unmount
      observer.disconnect();
    };
  }, [content, dataCharts, listImg]);

  const sanitizedContent = DOMPurify.sanitize(content, {
    ALLOWED_TAGS: ["h1", "h2", "h3", "h4", "h5", "h6", "p", "div", "span", "b", "i", "strong", "em", "ul", "ol", "li", "img"],
    ALLOWED_ATTR: ["class", "id", "style", "align"] // Permitir los atributos que controlan el estilo
  });

  return (
    <div
      ref={contentRef}
      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      className={`p-4 ${classRender}`}
    />
  );
};

export default PlotlyContentRenderer;
