import { CloseOutlined } from "@ant-design/icons";

const DisplayCards = ({
  list,
  onDeleteCard,
  title,
  messageEmpty = "La lista esta vacía",
}) => {
  const renderCards = () => {
    if (Array.isArray(list)) {
      if (list.length === 0)
        return (
          <p className="font-light text-sm text-color_text mb-3 italic">
            {messageEmpty}
          </p>
        );
      return list.map((item, i) => (
        <div key={item.name} className="flex bg-lightBlue2  rounded-full py-2 px-4 mx-2 mb-2">
          <p className="text-white_custom">{item.name}</p>
          <button
            type="button"
            className="text-white_custom hover:text-[#E23B30] ml-2"
            aria-label="button"
            onClick={() => onDeleteCard(i)}
          >
            <CloseOutlined />
          </button>
        </div>
      ));
    }
    return [];
  };

  return (
    <div className="flex flex-col w-full">
      <p className="font-light text-sizeBase text-color_text mb-3">{title}</p>
      <div className="flex flex-wrap w-full">{renderCards()}</div>
    </div>
  );
};

export default DisplayCards;
