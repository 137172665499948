import React, { useState, useEffect } from "react";
import { Upload, message, Form, } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import AppModal from "../Modal/Modal";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const AppAvatarUpload = ({
  title,
  className,
  settingFileList,
  defaultImageUrl = null,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [flag, setFlag] = useState(false);

  const handleCancel = () => setPreviewOpen(false);

  useEffect(() => {
    if (defaultImageUrl) {
      setFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: defaultImageUrl,
          // thumbUrl: defaultImageUrl,
        },

      ]);
    }
  }, [defaultImageUrl]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  // const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const handleChange = ({ fileList: newFileList }) =>{
    settingFileList(newFileList);
    if (newFileList.length > 0 && [
      "image/jpeg",
      "image/jpg",
      "image/png"
    ].includes(newFileList[0].type)) {
      // const fileStatus = { ...newFileList[0], status: "uploading" };
      setFileList([newFileList[0]]);
    }
  } ;
  
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        {title}
      </div>
    </div>
  );

  const onRemove= (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };
  useEffect(() => {
    if (flag)
      if (fileList && fileList.length > 0) {
        if (fileList[0].originFileObj && fileList[0].status === "uploading") {
          const fileStatus = { ...fileList[0], status: "done" };
          setFileList([fileStatus]);
        }
      }
  }, [fileList]);

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Solo puedes subir archivos JPG/PNG!");
      return true;
    }
    setFileList([file]);
    setFlag(true);
    return false;
  };

  return (
    <Form.Item
      name="avatar"
      className="font-Poppins text-black_custom"
    >
      <div>
        <Upload
          name="file"
          accept=".jpg, .jpeg,.png"
          multiple={false}
          onRemove={onRemove}
          listType="picture-circle"
          className={className}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          onPreview={handlePreview}
          maxCount={1}
          fileList={[...fileList]}
        >
          {fileList.length >= 1 ? null : uploadButton}
        </Upload>
        <AppModal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </AppModal>
      </div>
    </Form.Item>
  );
};

export default AppAvatarUpload;
