import React from "react";
import { Form} from "antd";
import AppTextArea from "../../atoms/TextArea/AppTextArea";
// import "../../../index.css";
/* eslint-disable react/jsx-props-no-spreading */
const  FormTextArea = ({ name, label, placeholder, value,  className = "", classText="",rules, labelCol="", ...props }) => {
  const combinedClassName = `font-Poppins font-light text-black_custom  ${className} !important`;
  const combinedClassText = `font-Poppins font-light text-black_custom ${classText} !important`;
  return (
    <Form.Item
      labelCol={labelCol}
      colon={false}
      name={name}
      label=  {label? <span className="font-Poppins font-light text-color_text pl-2">{label}</span>: null}
      rules={rules}
      className={combinedClassName}
      {...props}
    > 
      <AppTextArea
        placeholder={placeholder}
        className={combinedClassText}
        minRows={3}
        maxRows={50}
        // heightArea={heightArea}
        value={value}
        
      />
    </Form.Item>
  )
};

export default FormTextArea;
