import React from "react";
import { Form } from "antd";
// import FormDateRange from "../../molecules/FormDate/FormDateRange";
// import FormSelect from "../../molecules/FormSelect/FormSelect";
import FormTreeSelect from "../../molecules/FormTree/FormTreeSelect";
import SubmitButton from "../../atoms/Buttons/SubmitButton";

const FiltersStatementIndicatorsForm = ({
  handleValues,
  dataSelectors,
  handleRequestMunicipalities,
  // handleRequestRegion,
  loading,
}) => {
  const [form] = Form.useForm();

  /*   const handleChangeGroup = (val) => {
    handleRequestIndicators(val);
    form.resetFields(["indicators"]);
  }; */

  const handleChangeIndicators = (val) => {
    handleRequestMunicipalities(val);
    form.resetFields(["municipalities"]);
  }; 

  return (
    <Form
      form={form}
      onFinish={handleValues}
      className="flex flex-col w-full justify-items-center pr-5"
    >
      {/* Dropdown for selecting a group indicator */}
      <FormTreeSelect
        name="indicators"
        label="Indicador:"
        labelCol={{ span: 24 }}
        treeData={dataSelectors.groupIndicators}
        treeCheckable={false}
        onChange={handleChangeIndicators}
        treeExpandAction="doubleClick"
        placeholder="Seleccione un indicador"
        className="font-Poppins font-medium text-color_text"
        showCheckedStrategy={false}
        loading={loading.indicatorsByGroup}
        rules={[
          {
            required: true,
            message: "Por favor seleccione una opción",
          },
        ]}
        allowClear
      />

      <FormTreeSelect
        name="municipalities"
        placeholder="Seleccione una opción"
        label="Seleccione una ubicación:"
        labelCol={{ span: 24 }}
        treeData={dataSelectors.municipalitiesByIndicator}
        maxTagCount={1}
        rules={[
          {
            required: true,
            message: "Por favor seleccione una opción",
          },
        ]}
        loading={loading.municipalitiesByIndicator}
      />

      <SubmitButton
        className="bg-darkAquamarineBlue text-white_custom rounded-full w-full h-10 mt-2 md:mt-10"
        nameButton="Aplicar"
        htmlType="submit"
        form={form}
        loading={loading.chart}
      />
    </Form>
  );
};

export default FiltersStatementIndicatorsForm;
