/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
// import "../../../assets/css/styleSelect.css";
// import FormInputName from "../../molecules/FormInput/FormInputName";
import FormEmail from "../../molecules/FormInput/FormEmail";
// import FormEntity from "../../molecules/FormInput/FormEntity";
import FormPhone from "../../molecules/FormInput/FormPhone";
// import FormRole from "../../molecules/FormSelect/FormRole";
import FormGender from "../../molecules/FormSelect/FormGender";
import AppUploadAvatar from "../../molecules/Upload/AppAvatarUpload";
// import AppUpload from "../../molecules/Upload/AppUpload";
// import AppTitle from "../../atoms/Title/AppTitle";
import FormInput from "../../molecules/FormInput/FormInput";
import AppButton from "../../atoms/Buttons/AppButton";
import ValidatePermissions from "../../../helpers/ValidatePermissions";

const UpdateUserForm = ({
  optionsGender,
  handlerDelete,
  imgUrl,
  isGovernment,
  handlerChangePassword,
  setFileList
}) => {
  // const [form] = Form.useForm();
  // const [entityCol, setEntityCol] = useState(null);
  // const [classCol, setClassCol] = useState("grid gap-4 mb-8grid-cols-1 sm:grid-cols-2 md:grid-cols-3");
  // const [isEdit, setIsEdit] = useState(false);
  const [defaultImageUrl, setDefaultImageUrl] = useState(null);
  useEffect(() => {
    if (imgUrl) {
      setDefaultImageUrl(imgUrl);
    }
  }, [imgUrl]);

  return (
    <div className="grid gap-4 mb-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-4 sm:divide-x divide-colorBorder2/25">
      <div>
        <AppUploadAvatar
          title="Subir Foto"
          className="text-center"
          defaultImageUrl={defaultImageUrl||null}
          settingFileList={setFileList}
        />
        <br />
        <Form.Item
          labelCol={{ span: 24 }}
          colon={false}
          name="role"
          label={
            <span className="font-Poppins font-semibold  text-color_text">
              Rol
            </span>
          }
        >
          <Input
            className="text-color_text rounded-full"
            size="small"
            disabled
          />
        </Form.Item>
        <br />
        {isGovernment ? (
          <Form.Item
            labelCol={{ span: 24 }}
            colon={false}
            name="entity"
            label={
              <span className="font-Poppins font-semibold  text-color_text">
                Asociación
              </span>
            }
          >
            <Input
              className="text-color_text rounded-full"
              size="small"
              disabled
            />
          </Form.Item>
        ) : null}
      </div>
      <div className=" px-2 ">
        <FormInput
          name="first_name"
          label="Nombre(s):"
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Por favor ingrese su Nombre(s)",
              whitespace: true,
            },
            {
              pattern: /^[a-zA-ZÁÉÍÓÚáéíóú#\s]+$/,
              message: "Solo se permiten letras",
            },
            {
              max: 50,
              message: "Máximos 50 caracteres",
            },
          ]}
        />
        <FormInput
          name="last_name"
          label="Apellidos(s):"
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Por favor ingrese su Apellido(s)",
              whitespace: true,
            },
            {
              pattern: /^[a-zA-ZÁÉÍÓÚáéíóú#\s]+$/,
              message: "Solo se permiten letras",
            },
            {
              max: 50,
              message: "Máximos 50 caracteres",
            },
          ]}
        />
        <FormEmail name="email" label="Correo:" labelCol={{ span: 24 }} />
      </div>
      <div className="px-2 max-[768px]:border-none">
        <FormPhone name="phone" labelCol={{ span: 24 }} label="Teléfono:" />
        <FormGender
          name="gender"
          label="Sexo:"
          labelCol={{ span: 24 }}
          options={optionsGender}
        />
      </div>
      <ValidatePermissions
        func="oneButton"
        container={false}
        permission="edit"
        modulePermission="userown"
      >
        <div className="flex flex-col justify-center items-center px-4">
          <AppButton
            type="primary"
            className=" bg-lightBlue2 w-full !px-0 !py-1"
            title="Nueva Contraseña"
            onClick={handlerChangePassword}
            disabled
          />

          <AppButton
            type="primary"
            danger
            title="Eliminar mi Cuenta"
            onClick={handlerDelete}
            className="mt-4 bg-red-500 text-white_custom w-full !px-0 !py-1"
          />
        </div>
      </ValidatePermissions>
    </div>
  );
};

export default UpdateUserForm;
