/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import { Button, FloatButton, Spin, Switch } from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import AppModal from "../../components/molecules/Modal/Modal";
import FormAutoSearch from "../../components/molecules/FormAutoSearch/FormAutoSearch";
import useApi from "../../api/useApi";
import TableTemplate from "../../components/templates/TableTemplate";
import indicator from "../../api/indicator";
import JsonUploadForm from "../../components/organisms/DocumentUpload/JsonUploadForm";
import TaxonomyEditTemplate from "../../components/templates/TaxonomyTemplate/TaxonomyEditTemplate";
// import sanitizeFileName from "../../helpers/sanitizeFileName";
import { useAuth } from "../../contexts/AuthContext";
import ModalConfirm from "../../components/molecules/Modal/ModalConfirm";
import downloadFile from "../../helpers/downloadFile";
import document from "../../api/document";
import fetchingFiles from "../../api/fetchingFiles";


const Indicator = () => {
  const getListTaxonomies = useApi(indicator.getListTaxonomies);
  const updateTaxonomies = useApi(indicator.updateTaxonomies);
  const deleteTaxonomy = useApi(indicator.deleteTaxonomy);
  const createTaxonomy = useApi(indicator.createTaxonomy);
  const downloadTaxonomy = useApi(indicator.downloadTaxonomy);
  const downloadDocumentByUrl = useApi(document.downloadDocumentByUrl);
  const [loading, setLoading] = useState({
    modal: false,
    list: false,
    create: false,
    search: false,
    update: false,
    delete: false,
    download: false,
  });
  const [modalActive, setModalActive] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [modeSelected, setModeSelected] = useState("none");
  const [data, setData] = useState();
  const [dataTax, setDataTax] = useState();
  const [action, setAction] = useState("");
  const [metadata, setMetaData] = useState({
    total: 10,
    page: 1,
    page_size: 10,
  });
  const [file, setFile] = useState();
  const { setMessage } = useAuth();

  //   const navigate = useNavigate();

  const gettingTaxonomies = () => {
    setLoading((prev) => ({ ...prev, list: true }));
    getListTaxonomies.request(metadata.page_size, metadata.page);
  };

  const closeModal = () => {
    setModalActive(false);
    setLoading((prev) => ({ ...prev, modal: false }));
  };

  useEffect(() => {
    // hacer la petición a la API para obtener los datos de la página
    gettingTaxonomies();
  }, []);

  const catchErrorUploadFile = () => {
    setMessage({
      type: "error",
      title: "Error",
      description:
        "Hubo un problema con la subida del archivo. Revise que el JSON tenga la estructura adecuada.",
    });
    closeModal();
    setLoading((prev) => ({ ...prev, create: false }));
  };

  const uploadJson = async (arrUrl) => {
    const results = await fetchingFiles(arrUrl);
    const failed = results.every((val) => !val);
    if (failed) {
      catchErrorUploadFile();
    } else {
      setMessage({
        type: "success",
        title: "Resultado",
        description: "El archivo JSON se ha subido correctamente.",
      });
      gettingTaxonomies();
      closeModal();
      setLoading((prev) => ({ ...prev, create: false }));
    }
  };

  useEffect(() => {
    if (getListTaxonomies.data) {
      setData(getListTaxonomies.data.data.items);
      setMetaData({
        total: getListTaxonomies.data.data.total,
        page: getListTaxonomies.data.data.page,
        page_size: getListTaxonomies.data.data.page_size,
      });
      setLoading((prev) => ({ ...prev, list: false }));
      getListTaxonomies.setData();
    }
    if (createTaxonomy.data) {
      const auxData = createTaxonomy.data.data;
      const urlDoc = auxData?.url;
      uploadJson([{ file, url: urlDoc }]);
      createTaxonomy.setData();
    }
    if (updateTaxonomies.data) {
      if (updateTaxonomies.data.success) {
        setLoading((prev) => ({ ...prev, update: false }));
        if (action === "delete") {
          setMessage({
            type: "success",
            title: "Resultado",
            description: "La taxonomía ha sido eliminada exitosamente.",
          });
          setSelectedRowKeys([]);
        } else {
          setMessage({
            type: "success",
            title: "Resultado",
            description: "La taxonomía ha sido actualizada exitosamente.",
          });
        }
        setAction("");
        closeModal();
        gettingTaxonomies();
      } else {
        setMessage({
          type: "error",
          title: "Error",
          description:
            "Hubo un error al intentar actualizar la taxonomía. Revise los datos e inténtelo de nuevo.",
        });
      }
      updateTaxonomies.setData();
    }
    if (downloadTaxonomy.data) {
      downloadDocumentByUrl.request(downloadTaxonomy.data.data?.url);
      downloadTaxonomy.setData();
    }
    if (downloadDocumentByUrl.data) {
      downloadFile(downloadDocumentByUrl.data, dataTax?.name, "json");
      setMessage({
        type: "success",
        title: "Resultado",
        description: "El archivo fue descargado exitosamente.",
      });
      downloadDocumentByUrl.setData();
    }
  }, [
    getListTaxonomies.data,
    createTaxonomy.data,
    updateTaxonomies.data,
    downloadTaxonomy.data,
    downloadDocumentByUrl.data,
  ]);

  useEffect(() => {
    if (updateTaxonomies.error) {
      setLoading((prev) => ({ ...prev, update: false }));
      if (action === "delete") {
        closeModal();
        setMessage({
          type: "error",
          title: "Error",
          description:
            "Hubo un error al intentar eliminar la taxonomía. Revise los datos e inténtelo de nuevo.",
        });
      } else {
        setMessage({
          type: "error",
          title: "Error",
          description:
            "Hubo un error al intentar actualizar la taxonomía. Revise los datos e inténtelo de nuevo.",
        });
      }
    }
    if (createTaxonomy.error) {
      catchErrorUploadFile();
    }
    if (getListTaxonomies.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar obtener la lista de taxonomías. Por favor recargue la pagina.",
      });
      setLoading((prev) => ({ ...prev, list: false }));
    }
    if (downloadTaxonomy.error || downloadDocumentByUrl.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar descargar el archivo. Por favor inténtelo de nuevo mas tarde",
      });
      setLoading((prev) => ({ ...prev, download: false }));
    }
  }, [
    updateTaxonomies.error,
    createTaxonomy.error,
    deleteTaxonomy.error,
    getListTaxonomies.error,
    downloadTaxonomy.error,
    downloadDocumentByUrl.error,
  ]);

  const handlePageChange = (page) =>{
    setMetaData((prev) => ({ ...prev, page }));
    gettingTaxonomies();
  };

  const openModal = () => {
    setLoading((prev) => ({ ...prev, modal: true }));
    setModalActive(true);
  };

  const onEdit = (record) => {
    openModal();
    setDataTax(record);
    setAction("edit");
  };

  const onCreate = () => {
    openModal();
    setAction("create");
  };

  const onUpload = (record) => {
    setDataTax(record);
    openModal();
    setAction("upload");
  };

  const onDownload = (record) => {
    setDataTax(record);
    setLoading((prev) => ({ ...prev, download: true }));
    downloadTaxonomy.request(record._id);
  };

  const onDownloadTemplate = () => {
    setDataTax({name: "template"});
    downloadTaxonomy.request(null, true);

  };

  const onDelete = (record) => {
    setDataTax(record);
    openModal();
    setAction("delete");
  };

  const getRowKeys = (idsToFind) => {
    const statuses = data
      .filter((item) => idsToFind.includes(item._id))
      .map((item) => item.status);
    const allActive = statuses.every((status) => status === "disponible");
    const allInactive = statuses.every((status) => status === "no disponible");
    if (allActive) {
      setModeSelected("allActive");
    } else if (allInactive) {
      setModeSelected("allInactive");
    } else {
      setModeSelected("none");
    }
    setSelectedRowKeys(idsToFind);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (values) => getRowKeys(values),
  };

  const handlerSearch = async (val) => {
    if (val !== "") {
      setLoading((prev) => ({ ...prev, search: true }));
      getListTaxonomies.request(metadata.page_size, metadata.page, val);
      setIsSearch(true);
    }
  };

  const onResetSearch = () => {
    setIsSearch(false);
    gettingTaxonomies();
    setLoading((prev) => ({ ...prev, search: false }));
  };

  const onInactive = () => {
    setLoading((prev) => ({ ...prev, update: true }));
    updateTaxonomies.request({
      idTax: selectedRowKeys,
      status: "no disponible",
    });
  };
  const onActive = () => {
    setLoading((prev) => ({ ...prev, update: true }));
    updateTaxonomies.request({ idTax: selectedRowKeys, status: "disponible" });
  };

  const renderActionMode = () => {
    if (modeSelected === "none") {
      return null;
    }
    if (modeSelected === "allActive") {
      return (
        <div
          className="bg-lightBlue2 text-white hover:shadow-xl shadow-none w-[110px] !px-2 h-6 rounded"
          onClick={onInactive}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              e.preventDefault();
            }
          }}
          role="button"
          tabIndex={0}
        >
          <div className="flex items-center justify-center h-full">
            <PauseOutlined className="mr-1" />
            <p>Desactivar</p>
          </div>
        </div>
      );
    }
    return (
      <div
        className="bg-lightBlue2 text-white hover:shadow-xl shadow-none w-[110px] !px-2 h-6 rounded"
        onClick={onActive}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <div className="flex items-center justify-center h-full">
          <PlayCircleOutlined className="mr-1" />
          <p>Activar</p>
        </div>
      </div>
    );
  };

  const handleChangeStatus = (record, newValue) => {
    const idTax = [record._id];
    let status = "disponible";
    if (!newValue) status = "no disponible";
    updateTaxonomies.request({ idTax, status });
  };

  // Las columnas de la tabla
  const columns = [
    {
      title: (
        <span className="font-medium text-center text-xl">
          Nombre de la taxonomía
        </span>
      ),
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text, record) => (
        <div>
          <span className="text-center text-black_custom ">{text}</span>
          <Button
            className="text-lightBlue2 border-none hover:text-lightBlue hover:shadow-none "
            onClick={() => onEdit(record)}
            icon={<EditOutlined />}
          />
        </div>
      ),
      className: "border-b-0",
    },
    {
      title: <span className="font-medium text-center text-xl">Estado</span>,
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record) => (
        <Switch
          value={text === "disponible"}
          // defaultChecked={text === "disponible"}
          onChange={(newValue) => handleChangeStatus(record, newValue)}
          className="self-end"
        />
      ),
      className: "border-b-0",
    },
    {
      title: <span className="font-medium text-center text-xl ">Acciones</span>,
      key: "acciones",
      align: "center",
      ellipsis: true,
      render: (_, record) => (
        <div className="flex space-x-2 justify-center  ">
          <Button
            className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none "
            icon={<SyncOutlined />}
            onClick={() => onUpload(record)}
          />
          <Button
            className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none "
            icon={<DownloadOutlined />}
            onClick={() => onDownload(record)}
          />
          <Button
            className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none "
            icon={<DeleteOutlined />}
            onClick={() => onDelete(record)}
          />
        </div>
      ),
      width: 150,
      className: "border-b-0",
    },
  ];

  const onSubmitEdit = (values) => {
    const { name } = values || {};
    setLoading((prev) => ({ ...prev, update: true }));
    updateTaxonomies.request({ idTax: dataTax._id, name });
  };

  const onSubmitFile = (dataFile) => {
    setFile(dataFile.upload.originFileObj);
    const formData = new FormData();
    formData.append("file", dataFile.upload.originFileObj);
    if (action === "create") {
      createTaxonomy.request(formData);
    } else {
      updateTaxonomies.request({ idTax: dataTax?._id, file: formData });
    }
  };

  const handleDeleteTaxonomy = () => {
    setLoading((prev) => ({ ...prev, update: true }));
    updateTaxonomies.request({ idTax: dataTax._id, isDeleted: true });
  };

  const handleDeleteMultiple = () => {
    setLoading((prev) => ({ ...prev, update: true }));
    updateTaxonomies.request({ idTax: selectedRowKeys, isDeleted: true });
    setAction("delete");
    // deleteTaxonomy.request(selectedRowKeys);
  };

  const renderModalContent = () => {
    if (action === "create" || action === "upload")
      return <JsonUploadForm onSubmitFile={onSubmitFile} onDownloadTemplate={onDownloadTemplate} />;
    if (action === "edit")
      return <TaxonomyEditTemplate onSubmit={onSubmitEdit} dataTax={dataTax} />;
    return null;
  };

  return (
    <div className="min-h-screen bg-white_custom flex flex-col items-center pt-10 ">
      <div className="w-10/12 mb-2 grid justify-items-end ">
        <Button
          shape="round"
          size="large"
          type="primary"
          className="font-Poppins shadow-lg rounded-full bg-lightBlue text-white_custom"
          loading={loading.modal}
          onClick={onCreate}
        >
          Crear nueva taxonomía
        </Button>
      </div>

      <div className=" w-11/12 bg-white_custom p-6 rounded-[45px] border border-colorBorder/25 shadow-2xl">
        <div>
          <FormAutoSearch
            loading={loading.search}
            submitSearch={handlerSearch}
            resetValue={!isSearch}
          />
          {isSearch && (
            <div style={{ textAlign: "right" }}>
              <Button
                shape="round"
                size="large"
                type="primary"
                className="font-Poppins shadow-lg rounded-full bg-lightBlue text-white_custom"
                onClick={onResetSearch}
              >
                Restablecer búsqueda
              </Button>
            </div>
          )}
        </div>
        {!loading.list ? (
          <TableTemplate
            enableScroll
            data={data}
            handlePageChange={handlePageChange}
            total={metadata.total}
            columns={columns}
            page_size={metadata.page_size}
            currentPage={metadata.page}
            rowSelection={rowSelection}
            rowKey="_id"
            onlyView
            className=" "
          />
        ) : (
          <div className="flex h-96 justify-center items-center">
            <Spin />
          </div>
        )}
        {modalActive && action !== "delete" ? (
          <AppModal
            title={action === "create" ? "Nueva taxonomía" : "Editar taxonomía"}
            open={modalActive}
            onCancel={closeModal}
            footer={false}
            className=" text-lightBlue2 font-bold text-lg text-center w-1/2"
          >
            {renderModalContent()}
          </AppModal>
        ) : null}
        {modalActive && action === "delete" ? (
          <ModalConfirm
            centered
            okText="Eliminar"
            handleCancel={closeModal}
            handleOK={handleDeleteTaxonomy}
            closeModal={closeModal}
            modalActive={modalActive}
            text="¿Está seguro de que desea eliminar esta taxonomía?"
          />
        ) : null}
        {selectedRowKeys.length > 0 ? (
          <FloatButton
            shape="square"
            className="min-w-fit px-5 py-2 bg-white cursor-default left-1/2 -translate-x-1/2"
            style={{ insetInlineEnd: 24 }}
            description={
              <div className="flex items-center md:space-x-2">
                <p>{selectedRowKeys.length} Elementos seleccionados</p>
                <div
                  className="bg-[#c85647] text-white hover:shadow-xl shadow-none w-[110px] h-6 rounded"
                  onClick={handleDeleteMultiple}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <div className="flex items-center justify-center h-full">
                    <DeleteOutlined className="mr-1" />
                    Eliminar
                  </div>
                </div>
                {renderActionMode()}
              </div>
            }
          />
        ) : null}
      </div>
    </div>
  );
};
export default Indicator;
