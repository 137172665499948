import { Form } from "antd";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import SheetEditForm from "../../organisms/SheetForm/SheetEditForm";
import SubmitButtonNotValidate from "../../atoms/Buttons/SubmitButtonNotValidate";

const SheetFormTemplate = ({
  listColumns,
  listCodification,
  listMunicipalities,
  dataDescription,
  handlerSubmit,
  loading,
  WebSource,
  listSheet,
  isMultiSheet,
  onHandleResetConfig,
}) => {
  const [form] = Form.useForm();
  const dayFormat = "DD/MM/YYYY";
  const [isWebSource, setIsWebSource] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (dataDescription) {
      const {
        name,
        locationData = {},
        headerSize = [],
        dataSize = [],
        fileExtension,
        keyWords = [],
      } = dataDescription;

      const [headerStart, headerEnd] = Array.isArray(headerSize)
        ? headerSize
        : ["", ""];
      const [dataStart, dataEnd] = Array.isArray(dataSize)
        ? dataSize
        : ["", ""];
      const {
        municipalityColumn = "",
        municipalityCode = "",
        municipality = [],
        dateColumn = "",
        dateRange = "",
      } = locationData || {};

      const joinDates = dateRange ? dayjs(dateRange.trim(), dayFormat) : "";

      const auxData = {
        name,
        municipalityColumn: municipalityColumn || "0",
        municipalityCode,
        municipality,
        dateColumn: dateColumn || "0",
        dateRange: joinDates,
        keyWords,
        headerStart,
        headerEnd,
        dataStart,
        dataEnd,
      };

      form.setFieldsValue(auxData);
      setIsWebSource(fileExtension === "web" || fileExtension === ".pdf");
    } else {
      setIsWebSource(WebSource);
    }
  }, [dataDescription, WebSource]);

  const handleInputKeyDown = (event) => {
    if (["Enter", ","].includes(event.key)) {
      event.preventDefault();
      const tags = form.getFieldValue("keyWords") || [];
      const newTag = inputValue.trim();
      if (newTag && !tags.includes(newTag)) {
        form.setFieldValue("keyWords", [...tags, newTag]);
        setInputValue("");
      }
    }
  };

  const validatorheaderSize = (fileInput) => [
    {
      validator: (_, value) => {
        if (value && !Number.isInteger(value)) {
          return Promise.reject(new Error("El número debe ser un entero"));
        }
        const startValue = form.getFieldValue(fileInput);
        if (value < startValue) {
          return Promise.reject(
            new Error(
              "El valor de 'Hasta' debe ser mayor que el valor de 'Desde'"
            )
          );
        }
        return Promise.resolve();
      },
    },
  ];

  const codificationInputValidator = () => [
    {
      validator: (_, value) => {
        const valueCode = form.getFieldValue("municipalityCode");
        if (!value && valueCode === "0") {
          return Promise.reject(
            new Error(
              "Este campo es requerido si 'Codificación Municipio' está vacío o en Ninguno"
            )
          );
        }
        return Promise.resolve();
      },
    },
    {
      required: true,
      message: "",
    },
  ];

  const onSubmit = (values) => {
    handlerSubmit(values);
    form.resetFields();
  };

  const onClickReset = () => {
    form.resetFields(["headerStart", "headerEnd", "dataStart", "dataEnd"]);
    onHandleResetConfig();
  };

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      className="font-Poppins"
      scrollToFirstError
    >
      <div className="flex flex-col items-center">
        <div className="p-2">
          <div className="flex justify-center items-center">
            <SheetEditForm
              listColumns={listColumns}
              listMunicipalities={listMunicipalities}
              listCodification={listCodification}
              validatorCode={codificationInputValidator}
              dataDescription={dataDescription}
              WebSource={isWebSource}
              setInputValue={setInputValue}
              searchValue={inputValue}
              handleInputKeyDown={handleInputKeyDown}
              validatorHeader={validatorheaderSize}
              listSheet={listSheet}
              isMultiSheet={isMultiSheet}
              onHandleResetConfig={onClickReset}
            />
          </div>
          <Form.Item>
            <div className="flex justify-center text-center">
              <SubmitButtonNotValidate
                nameButton="Guardar"
                form={form}
                loading={loading}
                htmlType="submit"
              />
            </div>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default SheetFormTemplate;
