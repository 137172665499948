import React from "react";
import { Carousel, Divider } from "antd";
import MinSalud from "../../../assets/image/minsalud.svg";
import MinCiencias from "../../../assets/image/minciencias.svg";
import UniNari from "../../../assets/image/uninariño.svg";
import UniCauca from "../../../assets/image/unicauca.svg";
import LogoTBBC from "../../../assets/image/TBBC.svg";
import LogoCpdlv from "../../../assets/image/cpdlv.svg";
import CIDTCA from "../../../assets/image/CIDTCA.svg";

const Footer = () => (
  <div className="relative flex justify-center bottom-0 w-full mb-5 mt-10">
    <div className="w-[calc(100%-40px)] rounded-[20px] bg-backGroundBanner">
      <div className="absolute h-full pt-5 pl-5 text-white_custom">
        <span>Apoyan:</span>
      </div>
      <div className="hidden lg:block">
        <div className="flex h-full justify-center items-center">
          <img className="w-[80px]" src={CIDTCA} alt="" />
          <Divider type="vertical" className="bg-white_custom h-10" />
          <img className="w-[100px]" src={MinSalud} alt="" />
          <Divider type="vertical" className="bg-white_custom h-10" />
          <img className="w-[120px]" src={MinCiencias} alt="" />
          <Divider type="vertical" className="bg-white_custom h-10" />
          <img className="w-[100px]" src={UniNari} alt="" />
          <Divider type="vertical" className="bg-white_custom h-10" />
          <img className="w-[100px]" src={UniCauca} alt="" />
          <Divider type="vertical" className="bg-white_custom h-10" />
          <img className="w-[100px]" src={LogoTBBC} alt="" />
          <Divider type="vertical" className="bg-white_custom h-10" />
          <img className="w-[100px]" src={LogoCpdlv} alt="" />
        </div>
      </div>
      <div className="block mx-auto w-1/2 lg:hidden">
        <Carousel autoplay infinite autoplaySpeed={5000}>
          <div className="flex justify-center pt-4">
            <img className="w-[80px]" src={CIDTCA} alt="" />
          </div>
          <div className="flex justify-center pt-2">
            <img className="w-[100px]" src={MinSalud} alt="" />
          </div>
          <div className="flex justify-center">
            <img className="w-[120px]" src={MinCiencias} alt="" />
          </div>
          <div className="flex justify-center">
            <img className="w-[100px]" src={UniNari} alt="" />
          </div>
          <div className="flex justify-center">
            <img className="w-[100px]" src={UniCauca} alt="" />
          </div>
          <div className="flex justify-center">
            <img className="w-[100px]" src={LogoTBBC} alt="" />
          </div>
          <div className="flex justify-center">
            <img className="w-[100px]" src={LogoCpdlv} alt="" />
          </div>
        </Carousel>
      </div>
    </div>
  </div>
);

export default Footer;
