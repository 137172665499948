import api from ".";
import newApi from "./newApi";
// import { instance as client_file } from "./client_file";
// import {
//   instance2 as download_file,
//   instance4 as requestToS3,
// } from "./client_document";
// import client_img from "./client_img";


// #region Advertisements




const getAllResearchMeta = (page_size, page, state ,keyword ) => {

  let url = `/research/research/list?limit=${page_size}&offset=${page}`;
  if (keyword) url = url.concat("&", `keyword=${keyword}`);
  if (state) url = url.concat("&", `state=${state}`);
  return newApi.get(url);
};

const createResearchMeta = (data) => {
  const url = "/research/research/create";
  return newApi.post(url, data);
};

const getResearchContentById = (id) => {
  const url = `/research/research-content/${id}`;
  return newApi.get(url);
};

const getResearchMetaById = (id,markdown=false) => {
  
  const url = `/research/research/${id}?markdown=${markdown}`;
  return newApi.get(url);
};

const updateResearchMeta = (id, data) => {
  const url = `/research/research/${id}`;
  return newApi.put(url, data);
};

const updateResearchContent = (id, data) => {
  const url = `/research/research-content/${id}`;
  return newApi.put(url, data);
};

const getUrlImageResearchMeta = (id) => {
  const url = `/files/research-meta/image-url/${id}`;
  return api.get(url);
};

const previousStateResearchMeta = (id) => {
  const url = `/files/research-meta/previous-state/${id}`;
  return api.put(url);
};


export default {
  
  getAllResearchMeta,
  getResearchContentById,
  createResearchMeta,
  getResearchMetaById,
  updateResearchMeta,
  updateResearchContent,
  getUrlImageResearchMeta,
  previousStateResearchMeta,
 
};
