import React, { useEffect, useState } from "react";
import { Form, Button, Spin } from "antd";
import Tour from "reactour";
import AppImg from "../../atoms/Img/AppImg";
import logoAune from "../../../assets/image/Logo AUNE.png";
import AppTitle from "../../atoms/Title/AppTitle";
import AppButton from "../../atoms/Buttons/AppButton";
import UpdateUserForm from "../../organisms/UserForm/UpdateForm";
import ValidatePermissions from "../../../helpers/ValidatePermissions";

const UpdateTemplate = ({
  editData,
  dataUser,
  optionsGender,
  onDelete,
  onChangePassword,
  goHome,
  loading,
}) => {
  const [form] = Form.useForm();
  const [isGovernment, setIsGovernment] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isTourOpen, setIsTourOpen] = useState(false);

  useEffect(() => {
    if (dataUser) {
      if (dataUser.role === "Administrador") {
        setIsGovernment(true);
        form.setFieldsValue(dataUser);
      } else {
        setIsGovernment(false);
        form.setFieldsValue(dataUser);
        form.setFieldValue("gender", dataUser.gender);
      }
    }
  }, [dataUser]);

  const handlerSubmitButton = (data) => {
    const axiosData = { ...data, photo: fileList[0] };
    editData(axiosData);
  };

  const steps = [
    {
      selector: ".logo",
      content: "This is the logo of the application.",
    },
    {
      selector: ".user-info-title",
      content: "This section contains the title for the user information.",
    },
    {
      selector: ".user-form",
      content: "Here you can update the user information.",
    },
    {
      selector: ".cancel-button",
      content: "Click here to cancel and go back to the home page.",
    },
    {
      selector: ".update-button",
      content: "Click here to update the user information.",
    },
  ];

  return (
    <div className="container mx-auto items-center justify-center text-center">
      <AppImg src={logoAune} alt="Logo" className="mx-auto w-60 logo" />
      <div className="flex justify-center items-center mt-8">
        <div className="w-5/6 bg-white_custom p-6 rounded-[54px] border border-colorBorder/25 shadow-2xl">
          <div className="mb-2 grid justify-items-center">
            <AppTitle
              title="Información de usuario"
              level={2}
              className="font-bold text-lightBlue mb-6 user-info-title"
            />
          </div>
          <div className="grid justify-center items-center mb-2 user-form">
            <Form
              form={form}
              layout="vertical"
              className="font-Poppins"
              scrollToFirstError
              onFinish={handlerSubmitButton}
            >
              {!loading ? (
                <UpdateUserForm
                  handlerDelete={onDelete}
                  optionsGender={optionsGender}
                  imgUrl={dataUser?.photo || null}
                  isGovernment={isGovernment}
                  handlerChangePassword={onChangePassword}
                  setFileList={setFileList}
                />
              ) : (
                <div className="flex justify-center items-center h-56">
                  <Spin />
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>
      <ValidatePermissions
        func="oneButton"
        container={false}
        permission="edit"
        modulePermission="userown"
      >
        <Form.Item>
          <div className="flex justify-center mt-4">
            <Button
              shape="round"
              size="large"
              type="default"
              className="font-Poppins shadow-lg rounded-full text-color_text mr-8 !important cancel-button"
              onClick={goHome}
            >
              Cancelar
            </Button>
            <AppButton
              type="primary"
              className="ml-6 bg-lightBlue2 update-button"
              title="Actualizar"
              size="large"
              onClick={() => form.submit()}
            />
          </div>
        </Form.Item>
      </ValidatePermissions>
      <Button
        type="primary"
        onClick={() => setIsTourOpen(true)}
        className="mt-4"
      >
        Start Tour
      </Button>
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
    </div>
  );
};

export default UpdateTemplate;
