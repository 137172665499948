import { EllipsisOutlined } from "@ant-design/icons";
import AppPaginationHorizontal from "../../organisms/AppTable/AppPaginationHorizontal";
import AppTable from "../../organisms/AppTable/AppTable";
import TableTemplate from ".";

const TableJoined = ({
  dataInit,
  dataFinal,
  columns,
  columnsFinal,
  rowKey,
  onlyView,
  total,
  breakWords,
  page_size = 10,
  currentPageInit,
  currentPageFinal,
  handlePageChangeInit,
  handlePageChangeFinal,
  oneTable,
  rowSelection
}) => {
  if (oneTable)
    return (
      <TableTemplate
        enableScroll
        data={dataInit}
        columns={columns}
        rowKey={rowKey}
        className="!max-w-[calc(100vw-40px)] md:!max-w-[calc(100vw-200px)] "
        onlyView
        hasPagination={false}
        rowSelection={rowSelection}
      />
    );
  return (
    <div className="flex flex-col !max-w-[calc(100vw-200px)] md:!max-w-[calc(100vw-350px)]">
      <div className="flex">
        <AppPaginationHorizontal
          page={currentPageInit}
          total={total}
          page_size={page_size}
          onChange={handlePageChangeInit}
          text="SECCIÓN INICIAL"
        />
        <AppTable
          onlyView={onlyView}
          enableScroll
          data={dataInit}
          columns={columns}
          rowKey={rowKey}
          breakWords={breakWords}
        />
      </div>
      <div className="flex w-full items-center space-x-2 ml-10">
        <div className="w-[50%] border-b-[2px] border-[#cdcdcd]" />
        <EllipsisOutlined className="text-3xl" />
        <div className="w-[50%] border-b-[2px] border-[#cdcdcd]" />
      </div>
      <div className="flex">
        <AppPaginationHorizontal
          page={currentPageFinal}
          total={total}
          page_size={page_size}
          onChange={handlePageChangeFinal}
          text="SECCIÓN FINAL"
        />
        <AppTable
          onlyView={onlyView}
          enableScroll
          data={dataFinal}
          columns={columnsFinal}
          rowKey={rowKey}
          breakWords={breakWords}
        />
      </div>
    </div>
  );
};

export default TableJoined;
