import React from "react";
import { Form } from "antd";
import FormMultiSelect from "../../molecules/FormSelect/FormMultiSelect";
import FormDateRange from "../../molecules/FormDate/FormDateRange";
import FormSelect from "../../molecules/FormSelect/FormSelect";
import SubmitButtonNotValidate from "../../atoms/Buttons/SubmitButtonNotValidate";

const FiltersInvestigationForm = ({ handleValues, listMunicipalities }) => {
  const [form] = Form.useForm();
  const listVariables = [
    { label: "Demográficos", value: "Demográficos" },
    { label: "Socioeconómicos", value: "Socioeconómicos" },
    { label: "Mortalidad Materna", value: "Mortalidad Materna" },
    { label: "Mortalidad", value: "Mortalidad" },
    { label: "Morbilidad", value: "Morbilidad" },
    { label: "Enfermedades", value: "Enfermedades" },
  ];
  const listIndicators = [
    { label: "Total hombres", value: "total_population_men" },
    { label: "Total mujeres", value: "total_population_women" },
  ];
  return (
    <Form
      form={form}
      onFinish={handleValues}
      className=" grid justify-items-center pr-5"
    >
      {/* Dropdown for selecting a municipality */}
      <div className="flex flex-col items-center w-full text-center mb-3">
        <div className="text-sm  mb-1 italic font-light">
          Seleccione un municipio:
        </div>
        <FormSelect
          name="municipalities"
          className="font-Poppins text-black_custom formhome w-full text-center"
          selectClass="formBgWhite "
          placeholder="Seleccione una opción"
          labelCol={{ span: 24 }}
          options={listMunicipalities}
          rules={[
            {
              required: true,
              message: "Por favor seleccione una opción",
            },
          ]}
        />
      </div>

      {/* Dropdown for selecting a year */}
      <div className="flex flex-col items-center w-full text-center mb-3">
        <div className="text-sm italic font-light mb-1 ">
          Seleccione un rango de fechas:
        </div>
        <FormDateRange name="dateRange" className="w-full !mb-0" />
      </div>

      {/* Menu for other options */}
      <div className="flex flex-col items-center w-full text-center mb-3">
        <div className="text-sm italic font-light mb-1 w-4/5">
          Seleccione el informe del tipo de indicador que desea visualizar:
        </div>
        <FormSelect
          name="variable"
          className="font-Poppins text-black_custom formhome w-full text-center"
          selectClass="formBgWhite "
          placeholder="Seleccione una opción"
          labelCol={{ span: 24 }}
          options={listVariables}
          rules={[
            {
              required: true,
              message: "Por favor seleccione una opción",
            },
          ]}
        />
      </div>
      <div className="flex flex-col items-center w-full text-center">
        <div className="text-sm italic font-light mb-1 w-4/5">
          Seleccione uno o varios indicadores que desee visualizar:
        </div>
        <FormMultiSelect
          className="mb-0 mt-1 w-full"
          options={listIndicators}
          name="indicators"
        />
      </div>
      <SubmitButtonNotValidate
        nameButton="Aplicar filtros"
        form={form}
        className="bg-darkAquamarineBlue text-white_custom rounded-full w-80 h-10 mt-10"
        htmlType="submit"
      />
    </Form>
  );
};

export default FiltersInvestigationForm;
