import React from "react";
import { Select } from "antd";
/* eslint-disable react/jsx-props-no-spreading */

const AppMultiSelect = ({
  className = "",
  onSelectOption,
  stylePopup = "text-white_custom bg-lightBlue",
  ref,
  ...props
}) => {
  const combinedClassName = `font-Poppins text-center rounded-full ${className}`;
  return (
    <Select
      {...props}
      ref={ref}
      onSelect={(event) => onSelectOption("select", event)}
      onDeselect={(event) => onSelectOption("deselect", event)}
      mode="multiple"
      className={combinedClassName}
      popupClassName={`font-Poppins ${stylePopup}`}
    />
  );
};

export default AppMultiSelect;
