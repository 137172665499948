import React, { useState, useEffect } from "react";
import { Form } from "antd";
import RegisterForm from "../../organisms/UserForm/RegisterForm";
import AppImg from "../../atoms/Img/AppImg";
import logoAune from "../../../assets/image/Logo AUNE.png";
import AppTitle from "../../atoms/Title/AppTitle";
import SubmitButton from "../../atoms/Buttons/SubmitButton";


const UserRegisterTemplate = ({ saveData, loading, roles, optionsGender }) => {
  const [form] = Form.useForm();
  const [isGovernment, setIsGovernment] = useState(false);
  const [dni, setDni] = useState([]);
  const [entityCard, setEntityCard] = useState([]);
  const [widthDiv, setWidthDiv] = useState("w-4/6");
  
  useEffect(() => {
    if (isGovernment) {
      setWidthDiv("w-4/5");
    } else {
      setWidthDiv("w-4/6");
      
    }
  }, [isGovernment]);
  const onSubmit = (values) => {
    if (isGovernment) {
      saveData({...values,  dni: dni[0], entityCard: entityCard[0]});
    }
    else {
      saveData({...values});
    }
    
  };
  return (
    <div className="container mx-auto items-center justify-center text-center">
      <AppImg src={logoAune} alt="Logo" className=" mx-auto w-60 " />
      <div className="flex justify-center items-center mt-8">

        {/* <div className="w-full max-w-5xl p-8 bg-white_custom rounded-lg shadow-lg"> */}
        <div className={ `${widthDiv} bg-white_custom p-6 rounded-[54px] border border-colorBorder/25 shadow-2xl`}>
          <div className=" mb-2 grid justify-items-center ">

            <AppTitle
              title="Registro de Usuario"
              level={2}
              className="font-bold text-lightBlue mb-6"
            />

          </div>
          <div className="flex justify-center items-center mb-2 w-auto">
            <Form
              form={form}
              // initialValues={{ remember: true }}
              layout="vertical"
              className="font-Poppins"
              scrollToFirstError
            >
              <RegisterForm
                roles={roles}
                optionsGender={optionsGender}
                setIsGovernment={setIsGovernment}
                setDni={setDni}
                setEntityCard={setEntityCard}
              />

            </Form>
          </div>

        </div>
      </div>
      <Form.Item>
        <div className="flex justify-center mt-4">
          
          <SubmitButton
            form={form}
            nameButton="Registrarme"
            loading={loading}
            onClick={() => onSubmit(form.getFieldsValue())}
          />
        </div>
      </Form.Item>

    </div>
  )
};

export default UserRegisterTemplate;