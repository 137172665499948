const listCodification = [
  {
    id: 1,
    value: "1",
    label: "DANE COMPLETO",
  },
  {
    id: 2,
    value: "2",
    label: "DANE SIN PREFIJO",
  },
  {
    id: 2,
    value: "3",
    label: "OTRO",
  },
];

export default listCodification
