import React from "react";
import { useNavigate } from "react-router-dom";
/* eslint-disable react/jsx-props-no-spreading */

const ButtonLink = ({ className = "",title, href="" }) => {
  const navigate = useNavigate()
  const combinedClassName = `font-Poppins ${className}`;

  const onNavigate = () => navigate(href)
  return (
    <button
      className={combinedClassName}
      onClick={onNavigate}
      type="button"
    >
      {title}
    </button>

  );
}
export default ButtonLink;