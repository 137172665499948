// import api from ".";
import newApi from "./newApi";
// import { instance as client_file } from "./client_file";
// import {
//   instance2 as download_file,
//   instance4 as requestToS3,
// } from "./client_document";
// import client_img from "./client_img";


// #region Advertisements




const getAllNotifications = (page_size, page) => {

  const url = `/notification/notification/list?limit=${page_size}&offset=${page}`;
  return newApi.get(url);
};



const UpdateNotification = (id, data) => {
  const url = `/notification/notification/update/${id}`;
  return newApi.put(url, data);
};



export default {
  getAllNotifications,
  UpdateNotification,
};
