import { Layout } from "antd";
// import DocumentList from "../../components/templates/DocumentTemplate/DocumentList";
import DocumentTabs from "../../components/templates/DocumentTemplate/DocumentTabs";

const { Content } = Layout;

const DataManagement = () => (
  <Layout>
    <Content>
      {/* <DocumentList /> */}
      <DocumentTabs />
    </Content>
  </Layout>
);

export default DataManagement;
