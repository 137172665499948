import React from "react";
import { Input } from "antd";
/* eslint-disable react/jsx-props-no-spreading */

const AppInput = ({ className = "", ...props }) => {
  const combinedClassName = `font-Poppins font-light text-sizeBase text-black_custom rounded-full ${className}`;
  return (
    <Input {...props}
      className={combinedClassName}
      sharpes="round"
      // size="large"
      // style={{ "height": "38px", "lineHeight": "38px" }}
    />

  )
};
export default AppInput;