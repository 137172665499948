import React from "react";
import { Form, DatePicker} from "antd";
// import "../../../index.css";
/* eslint-disable react/jsx-props-no-spreading */
const FormDate =({ name,  label, className = "", rules, labelCol="", ...props }) =>{
  const combinedClassName = `font-Poppins font-light text-black_custom  ${className} !important`;
  return (
    <Form.Item
      labelCol={labelCol}
      colon={false}
      name={name}
      label=  {label? <span className="font-Poppins font-light  text-color_text">{label}</span>: null}
      rules={rules}
      className={combinedClassName}
      {...props}
    > 
      <DatePicker
        className="font-Poppins font-light  rounded-full text-black_custom"
        placeholder="Seleccione fecha"
        sharpes="round"
        style={{ width: "100%" }}
        format="YYYY-MM-DD"
      />
    </Form.Item>
  )
};

export default FormDate;
