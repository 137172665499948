import { useEffect } from "react";
import { notification } from "antd";
import { useAuth } from "../../../contexts/AuthContext";

export default function Messages() {
  const { message } = useAuth();
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (message) {
      if (message.code) {
        if (
          typeof message.code === "string" ||
          message.code instanceof String
        ) {
          if (message.code.includes("Insufficient permissions")) {
            api[message.type]({
              message: message.title,
              description: "No tienes permiso para hacer esto",
            });
          }
        }
      } else {
        api[message.type]({
          message: message.title,
          description: message.description,
          duration: message.duration || 4
        });
      }
    }
  }, [message]);

  return <div>{message && contextHolder}</div>;
}
