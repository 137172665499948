/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from "react";
import { Layout } from "antd";
import axios from "axios";
import AppModal from "../../components/molecules/Modal/Modal";
import ChartForm from "../../components/organisms/ChartForm/ChartForm";
import useApi from "../../api/useApi";
import document from "../../api/document";
import transformToSelectArray from "../../helpers/transformToSelectArray";
import { useAuth } from "../../contexts/AuthContext";
/* eslint-disable arrow-body-style */
// import { useEffect, useState } from "react";
import ComparativeAnalysisTemplate from "../../components/templates/CompararativeAnalysisTemplate/ComparativeAnalysisTemplate";
import ChartCreateForm from "../../components/organisms/ChartForm/ChartCreateForm";
import analysis from "../../api/analysisRequest";
import listCharts from "../../constant/listCharts";
import listAggregations from "../../constant/listAggregations";
// import analysisRequest from "../../api/analysisRequest";


const { Content } = Layout;

const ComparativeAnalysis = () => {
  const getAllDocuments = useApi(document.getAllDocuments);
  const getColumnsFile = useApi(document.getColumnsFile);
  const updateComparative = useApi(document.updateComparative);
  // const getAnalysisById = useApi(analysisRequest.getAnalysisById);
  const { setMessage } = useAuth();
  const [dataCharts, setDataCharts] = useState([]);
  const [modalActive, setModalActive] = useState(false);
  const [action, setAction] = useState("");
  const [detailChart, setDetailChart] = useState("");
  const [iterationChart, setIterationChart] = useState(0);
  const [listColumnsXY, setListColumnsXY] = useState([]);
  const [listFiles, setListFiles] = useState([]);
  // const [ItemSelected, setItemSelected] = useState();
  const [loading, setLoading] = useState({
    columns: true,
    submit: false,
  });

  useEffect(() => {
    getAllDocuments.request(100, 1, "all-files");
  }, []);

  useEffect(() => {
    if (!modalActive) {
      setListColumnsXY([]);
      setLoading((prev) => ({ ...prev, columns: true }));
    }
  }, [modalActive]);

  useEffect(() => {
    if (getAllDocuments.data) {
      const auxData = getAllDocuments.data.data?.items;
      const filterNotWeb = auxData.filter(
        (item) => item.fileExtension !== "web"
      );
      const filterNotPdf = filterNotWeb.filter(
        (item) => item.fileExtension !== ".pdf"
      );
      const filterOnlyReady = filterNotPdf.filter(
        (item) => item.status === "ready"
      );
      const auxList = transformToSelectArray(filterOnlyReady, "name", "_id");
      setListFiles(auxList);
      getAllDocuments.setData();
    }
    if (getColumnsFile.data) {
      const columns = getColumnsFile.data.data;
      if (Array.isArray(columns)) {
        const auxCols = transformToSelectArray(columns, "name", "name");
        setLoading((prev) => ({ ...prev, columns: false }));
        setListColumnsXY(auxCols);
      }
      getColumnsFile.setData();
    }
    if (updateComparative.data) {
      setLoading({ columns: true, submit: false });
      setModalActive(false);
      setMessage({
        type: "success",
        title: "Resultado",
        description: "Los datos de la gráfica se han actualizado correctamente",
      });
      updateComparative.setData();
    }
  }, [getAllDocuments.data, getColumnsFile.data, updateComparative.data]);

  useEffect(() => {
    if (getAllDocuments.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar obtener las fuentes. Por favor recargue la pagina.",
        code: getAllDocuments.error,
      });
    }
    if (getColumnsFile.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar obtener las columnas para la fuente seleccionada. Por favor seleccione otra vez la fuente.",
        code: getColumnsFile.error,
      });
    }
    if (updateComparative.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar guardar los datos de la gráfica. Revise los datos e inténtelo de nuevo.",
        code: updateComparative.error,
      });
    }
  }, [getAllDocuments.error, getColumnsFile.error, updateComparative.error]);

  const closeModal = () => {
    setLoading((prev) => ({ ...prev, submit: false }));
    setModalActive(false);
  };

  const handleEdit = (i) => {
    setIterationChart(i);
    setAction("edit");
    setDetailChart(dataCharts[i]);
    setModalActive(true);
  };

  const handleButtonAdd = () => {
    setAction("create");
    setModalActive(true);
  };

  const handleDelete = (i) => {
    const auxListChart = [...dataCharts];
    auxListChart.splice(i, 1);
    setDataCharts(auxListChart);
  };

  const handleSubmit = async (values) => {
    setLoading((prev) => ({ ...prev, submit: true }));
    let responseStatus = "";
    const { title, columnX, columnY, resource } = values;
    let auxDataChart = {
      name: title,
      type: detailChart.type,
      columnName: {
        xAxis: columnX,
        yAxis: columnY,
      },
      fileId: resource,
    };
    if (detailChart.type === "pie") {
      let responseStatusY = "";
      if (columnX && columnY && resource) {
        const auxRequests = [
          analysis.getColumnData(resource, detailChart.type, columnX),
          analysis.getColumnData(resource, detailChart.type, columnY),
        ];
        const columnsXY = [];
        await axios.all(auxRequests).then((responses) => {
          responses.forEach((resp) => columnsXY.push(resp.data));
          responseStatus = responses[0].request.status;
          responseStatusY = responses[1].request.status;
        });
        const valuesX = columnsXY[0]?.data;
        const valuesY = columnsXY[1]?.data;

        auxDataChart = {
          ...auxDataChart,
          columnName: { ...auxDataChart.columnName, label: columnY },
          data: { x: valuesX, y: valuesY },
        };
      }
      if (responseStatus !== 200 || responseStatusY !== 200) {
        setMessage({
          type: "error",
          title: "Error",
          description: "No hay datos de estas columnas",
        });
      }
    }
    if (detailChart.type === "bar") {
      if (columnX && columnY && resource) {
        const auxRequests = [
          analysis.getColumnData(resource, detailChart.type, columnX, columnY),
        ];
        const columnsXY = [];
        await axios.all(auxRequests).then((responses) => {
          responses.forEach((resp) => columnsXY.push(resp.data));
          responseStatus = responses[0].request.status;
        });
        const columnInfo = columnsXY[0];
        const auxColumnData = columnInfo?.data;

        auxDataChart = {
          ...auxDataChart,
          columnName: { ...auxDataChart.columnName, label: columnY },
          data: auxColumnData,
        };
      }
      if (responseStatus !== 200) {
        setMessage({
          type: "error",
          title: "Error",
          description: "No hay datos de estas columnas",
        });
      }
    }
    if (detailChart.type === "stackedBar" || detailChart.type === "scatter") {
      // const { aggregation, columnLabels } = values;
      let agg = values.aggregation || "";
      const widthData = values.widthData || "";
      if (columnY === "none") agg = "count";

      const labels = values?.columnLabels;
      const auxRequests = [
        analysis.getColumnData(
          resource,
          detailChart.type,
          columnX,
          columnY,
          labels,
          agg,
          widthData
        ),
      ];
      const columnsXY = [];
      await axios.all(auxRequests).then((responses) => {
        responses.forEach((resp) => columnsXY.push(resp.data));
        responseStatus = responses[0].request.status;
      });
      const columnInfo = columnsXY[0];
      const auxColumnData = columnInfo?.data;
      if (detailChart.type === "stackedBar") {
        auxDataChart = {
          ...auxDataChart,
          columnName: {
            ...auxDataChart.columnName,
            label: labels,
            aggregation: agg,
          },
          data: auxColumnData,
        };
      }
      if (detailChart.type === "scatter") {
        auxDataChart = {
          ...auxDataChart,
          columnName: {
            ...auxDataChart.columnName,
            label: labels,
            values: widthData,
          },
          data: auxColumnData,
        };
      }
      if (responseStatus !== 200) {
        setMessage({
          type: "error",
          title: "Error",
          description: "No hay datos de esta columna",
        });
      }
    }

    if (detailChart.type === "line") {
      const auxRequests = [
        analysis.getColumnData(resource, "line", columnX, columnY),
      ];
      const columnsXY = [];
      await axios.all(auxRequests).then((responses) => {
        responses.forEach((resp) => columnsXY.push(resp.data));
        responseStatus = responses[0].request.status;
      });
      const columnInfo = columnsXY[0]?.data;
      const valuesX = columnInfo?.x;
      const valuesY = columnInfo?.y;

      // const auxColumnName = columnInfo?.name;
      auxDataChart = {
        ...auxDataChart,
        columnName: { ...auxDataChart.columnName, yAxis: columnY },
        data: { x: valuesX, y: valuesY },
      };
      if (responseStatus !== 200) {
        setMessage({
          type: "error",
          title: "Error",
          description:
            "La fuente seleccionada no tiene datos de tiempo para mostrar en este gráfico de linea",
        });
      }
    }
    const auxArray = [...dataCharts];
    const chartSelected = auxArray.map((item, i) =>
      i === iterationChart ? auxDataChart : item
    );
    if (responseStatus === 401) {
      window.location.reload();
    }
    if (responseStatus === 200) {
      setDataCharts(chartSelected);
      setModalActive(false);
    }
    setLoading((prev) => ({ ...prev, submit: false }));
  };

  const onChangeCol = (val) => {
    setLoading((prev) => ({ ...prev, columns: true }));
    getColumnsFile.request(val);
  };

  const handleCreateChart = (values) => {
    const { title, chartType } = values;
    const lengthCharts = dataCharts.length;
    if (lengthCharts < 5) {
      setDataCharts((prev) => [
        ...prev,
        {
          name: title,
          data: {
            x: [1, 2, 3, 4, 5],
            y: [1, 2, 3, 4, 5],
          },
          type: chartType,
        },
      ]);
      setModalActive(false);
    } else {
      setMessage({
        type: "warning",
        title: "Error",
        description: "No puedes agregar mas de 5 gráficas",
        code: getColumnsFile.error,
      });
    }
  };

  const renderModal = () => {
    if (modalActive) {
      return (
        <AppModal
          open={modalActive}
          onCancel={closeModal}
          footer={false}
          className=" text-lightBlue2 text-lg text-center w-full lg:w-1/2"
        >
          <>
            <strong className="text-lg">
              {action === "edit"
                ? "Editar datos de la gráfica"
                : "Crear nueva gráfica"}
            </strong>
            {action === "edit" ? (
              <ChartForm
                detailChart={detailChart}
                listColumnsXY={listColumnsXY}
                listFiles={listFiles}
                loading={loading}
                handleSubmit={handleSubmit}
                onChangeCol={onChangeCol}
                isOpenModal={modalActive}
                listAggregations={listAggregations}
              />
            ) : (
              <ChartCreateForm
                listCharts={listCharts}
                handleSubmit={handleCreateChart}
              />
            )}
          </>
        </AppModal>
      );
    }
    return null;
  };

  

  return (
    <Layout className="bg-white_custom">
      <Content>
        <ComparativeAnalysisTemplate
          dataCharts={dataCharts}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleButtonAdd={handleButtonAdd}
          onDeleteChart={handleDelete}
          setDataCharts={setDataCharts}
        />
      </Content>
      {renderModal()}
    </Layout>
  );
};

export default ComparativeAnalysis;
