import axios from "axios";
import { fetchAuthSession } from "@aws-amplify/auth";
import Config from "../config";
import token from "./token";

const STATUS_CODES_TO_RETRY = [401, 403];

const refreshToken = () => fetchAuthSession({ forceRefresh: true });

const MAX_RETRIES = 3;

const instance = axios.create({
  baseURL: `${Config.urlBackendNew}`,
  timeout: Config.timeOut,
  headers: {
    Accept: "application/json; charset=utf-8",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const auxToken = token.getToken();
    config.headers.setAuthorization(`Bearer ${auxToken}`, true);
    return config;
  },
  (error) => error
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config } = error;

    if (!config.retryCount) {
      config.retryCount = 0;
    }
    if (
      config.retryCount < MAX_RETRIES &&
      STATUS_CODES_TO_RETRY.includes(error.status)
    ) {
      config.retryCount += 1;

      const response = await refreshToken();

      // Force to save token on sessionStorage

      const { tokens: session } = response;
      const accessToken = session.idToken.toString();

      token.saveToken(accessToken);

      // Retry the original request
      return instance(config);
    }
    if (
      error?.config?.headers?.Authorization === "Bearer undefined" &&
      token.getToken()
    ) {
      window.location.reload();
    }
    return error;
  }
);

export default instance;
