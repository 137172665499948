import React from "react";
import { Typography } from "antd";

const { Text } = Typography;
function AppText({ title, type, className="", style }) {
  const combinedClassName = `font-Poppins ${className} !important`;
  return (
    <Text type={type} className={combinedClassName} style={style}   >{title} </Text>
  );
}
export default AppText;