import React from "react";
import { Form, Select } from "antd";

/* eslint-disable react/jsx-props-no-spreading */

const FormSelect = ({
  className = "",
  name,
  label,
  rules,
  popupclass,
  selectClass,
  placeholder,
  options,
  spanCls,
  valueDefault = null,
  disable = false,
  onChange,
  loading = false,
  onInputKeyDown,
  tokenSeparators,
  mode = "",
  onSearch,
  searchValue,
  maxTagCount,
  ...props
}) => {
  const combinedClassName = `font-Poppins text-white_custom  ${className} !important`;
  const combinedClassSelect = `font-Poppins  rounded-full   text-white_custom  ${selectClass} !important`;
  const combinedClassPoppup = `font-Poppins   ${popupclass} !important`;
  const combinedSpanCls = `font-Poppins font-light text-sizeBase text-color_text text-center  ${spanCls} !important`;
  return (
    <Form.Item
      name={name}
      colon={false}
      label={label ? <span className={combinedSpanCls}>{label}</span> : null}
      rules={rules}
      {...props}
      className={combinedClassName}
    >
      <Select
        searchValue={searchValue}
        value={valueDefault}
        options={options}
        placeholder={placeholder}
        className={combinedClassSelect}
        popupClassName={combinedClassPoppup}
        disabled={disable}
        onChange={onChange}
        onSearch={onSearch}
        loading={loading}
        mode={mode}
        onInputKeyDown={onInputKeyDown}
        tokenSeparators={tokenSeparators}
        maxTagCount={maxTagCount}
      />
    </Form.Item>
  );
};

export default FormSelect;
