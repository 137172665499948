/* eslint-disable no-useless-escape */
import React, { useEffect } from "react";
import { ConfigProvider, Divider, Form } from "antd";
// import FormInput from "../../molecules/FormInput/FormInput";
import FormSelect from "../../molecules/FormSelect/FormSelect";
import { selectWhiteStyle } from "../../../assets/theme/themeConfig";
import FormInput from "../../molecules/FormInput/FormInput";
import FormPhone from "../../molecules/FormInput/FormPhone";
import FormEmail from "../../molecules/FormInput/FormEmail";
import SubmitButton from "../../atoms/Buttons/SubmitButton";
import FormPassword from "../../molecules/FormInput/FormPassword";

const CreateForm = ({ dataUser, roles, loading, onSubmit, action }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (dataUser) {
      form.setFieldsValue(dataUser);
    }
  }, [dataUser]);

  return (
    <Form form={form}>
      <ConfigProvider
        theme={{
          components: {
            Select: selectWhiteStyle,
          },
        }}
      >
        <div className="flex flex-col md:flex-row mb-5">
          <div className="w-full md:w-1/2">
            <FormInput
              name="firstName"
              label="Nombre(s):"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su Nombre(s)",
                  whitespace: true,
                },
                {
                  pattern: /^[a-zA-ZÁÉÍÓÚáéíóú#\s]+$/,
                  message: "Solo se permiten letras",
                },
                {
                  max: 50,
                  message: "Máximos 50 caracteres",
                },
              ]}
            />
            <FormInput
              name="lastName"
              label="Apellidos(s):"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su Apellido(s)",
                  whitespace: true,
                },
                {
                  pattern: /^[a-zA-ZÁÉÍÓÚáéíóú#\s]+$/,
                  message: "Solo se permiten letras",
                },
                {
                  max: 50,
                  message: "Máximos 50 caracteres",
                },
              ]}
            />
            <FormSelect
              name="role"
              label="Rol:"
              placeholder="Seleccionar una opción"
              options={roles}
              className="font-semibold text-white_custom text-sizeBase"
              // selectClass="formData bg-lightBlue"
              size="small"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione una opción",
                },
              ]}
              labelCol={{ span: 24 }}
            />
          </div>
          <Divider
            type="vertical"
            className="h-auto border border-gray_custom hidden md:block"
          />
          <div className="w-full md:w-1/2">
            <FormPhone
              className="w-full"
              name="phone"
              labelCol={{ span: 24 }}
              label="Teléfono:"
            />
            <FormEmail name="email" label="Correo:" labelCol={{ span: 24 }} />
            {action === "create" ? (
              <FormPassword
                name="password"
                label="Contraseña:"
                hasFeedback
                labelCol={{ span: 24 }}
              />
            ) : null}
          </div>
        </div>
        <Form.Item>
          <div className="flex justify-center mt-4">
            <SubmitButton
              form={form}
              nameButton="Guardar"
              loading={loading}
              onClick={() => onSubmit(form.getFieldsValue())}
            />
          </div>
        </Form.Item>
      </ConfigProvider>
    </Form>
  );
};

export default CreateForm;
