// import "../../../index.css";
import React from "react";
import { Form, Input } from "antd";
/* eslint-disable react/jsx-props-no-spreading */
// import "../../../assets/css/stylesPassword.css"

const  FormPassword =({ name, label, placeholder, className = "", labelCol="", ...props })=> {
  const combinedClassName = `font-Poppins font-light  text-color_text ${className} !important`; 
  return (
    <Form.Item
      name={name}
      labelCol={labelCol}
      colon={false}
      label={label? <span className="font-Poppins font-light  text-color_text">{label}</span>: null}
      tooltip="La contraseña debe contener al menos una letra minúscula, una letra mayúscula, un número y un carácter especial"
      rules={[
        {
          required: true,
          message: "Por favor ingrese una contraseña",
        },
        {
          max: 20,
          message: "Máximos 20 caracteres",
        },
        {
          min: 8,
          message: "Mínimos 8 caracteres",
        },
        {
          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#%*^&()_+{}|:;,.?/])[A-Za-z\d!@#%*^&()_+{}|:;,.?/].{8,}$/,
          message:
            "La contraseña debe contener al menos una letra minúscula, una letra mayúscula, un número y un carácter especial",
        },
      ]}
      className={combinedClassName}
      {...props}
    >
      <Input.Password  className="text-black_custom font-Poppins text-sizeBase rounded-full !important" />
    </Form.Item>
  )
};
export default FormPassword;