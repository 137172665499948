/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { /* Form, */ Button } from "antd";

export default function SubmitButtonNotValidate({
  // form,
  onClick,
  loading,
  nameButton = "Guardar",
  className,
  htmlType = "",
}) {

  return (
    <Button
      shape="round"
      htmlType={htmlType}
      size="large"
      type="primary"
      className={`font-Poppins font-normal shadow-lg rounded-full min-w-28 bg-lightBlue2 text-white_custom ${className}`}
      onClick={onClick}
      loading={loading}
    >
      {nameButton}
    </Button>
  );
}