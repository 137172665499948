import React from "react";
import { Button } from "antd";
import { DownCircleOutlined, UpCircleOutlined } from "@ant-design/icons";

const AppPaginationHorizontal = ({
  page = 1,
  total,
  page_size,
  onChange,
  text,
}) => {
  const totalPages = Math.ceil(total / page_size);

  return (
    <div className="flex flex-col justify-evenly items-center">
      <Button
        className="bg-white_custom border-none "
        icon={
          <UpCircleOutlined style={{ color: "#4CACBC", fontSize: "17px" }} />
        }
        onClick={() => {
          onChange(page - 1);
        }}
        disabled={page === 1}
      />
      <span className="text-dark_gray font-normal text-center text-sm -rotate-90">{text}</span>
      <Button
        className="bg-white_custom border-none "
        icon={
          <DownCircleOutlined style={{ color: "#4CACBC", fontSize: "17px" }} />
        }
        onClick={() => {
          onChange(page + 1);
        }}
        disabled={page === totalPages}
      />
    </div>
  );
};

export default AppPaginationHorizontal;
