import React from "react";
import { Form } from "antd";
import AppGroupRadio from "../../atoms/Radio/AppRadio";
/* eslint-disable react/jsx-props-no-spreading */

const FormGender = ({ className = "", name, label, labelCol, options ,...props }) => {
  const combinedClassName = `font-Poppins text-black_custom text-sizeBase ${className} !important`;
  
  return (
    <Form.Item
      name={name}
      label={label? <span className="font-Poppins font-light text-sizeBase text-color_text">{label}</span>: null}
      labelCol={labelCol}
      colon={false}
      {...props}
      className={combinedClassName}
     
    >
      <AppGroupRadio  options={options} initialValue="NoInfo" className=" font-Poppins font-light text-black_custom text-sizeBase"/>
    </Form.Item>

  )
};

export default FormGender;
