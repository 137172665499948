const transformToDateFormat = (
  values,
  separator = "/",
  format = "yyyy/mm/dd"
) => {
  if (!values) return "";
  if (
    typeof values === "object" &&
    Object.prototype.hasOwnProperty.call(values, "$d")
  ) {
    const day = values.$D;
    const month = +values.$M + 1;
    const year = values.$y;
    const formatDay = day < 10 ? `0${day}` : day;
    const formatMonth = month < 10 ? `0${month}` : month;
    let formatDate = `${formatDay}${separator}${formatMonth}${separator}${year}`;
    if (format === "dd/mm/yyyy")
      formatDate = `${year}${separator}${formatMonth}${separator}${formatDay}`;

    return formatDate;
  }
  return "";
};

export default transformToDateFormat;
