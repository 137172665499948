/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
// import React, { useState } from "react";
// import "../../../assets/css/stylesLogin.css";
// import "../../../index.css"
import { Form } from "antd";
import SubmitButton from "../../atoms/Buttons/SubmitButton";
import FormPassLogin from "../../molecules/FormInput/FormPassLogin";

const RecoveryForm = ({ loading, onSubmitForm, handleBackButton }) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      onFinish={onSubmitForm}
      layout="vertical"
      className="font-Poppins py-4 space-y-4 md:space-y-6  "
      scrollToFirstError
    >
      <div className="justify-items-center">
        <div className=" text-center text-base">
          <label
            className="font-light  text-center"
            style={{ fontSize: "16px" }}
          >
            Contraseña:
          </label>
          <FormPassLogin
            name="password"
            className="font-normal text-base text-center"
          />
        </div>
        <div className=" text-center text-base">
          <label
            className="font-light  text-center"
            style={{ fontSize: "16px" }}
          >
            Confirmar contraseña:
          </label>
          <FormPassLogin
            name="confirmPassword"
            className="font-normal text-base text-center"
          />
        </div>
      </div>

      <Form.Item>
        <div className="flex flex-col items-center justify-center text-center">
          <SubmitButton
            className="font-normal text-center"
            nameButton="Guardar"
            loading={loading}
            htmlType="submit"
            form={form}
          />
          <a
            href="#"
            onClick={handleBackButton}
            className=" mt-5 underline font-semibold text-sm text-center text-lightBlue  hover:text-blue-700"
          >
            Volver
          </a>
        </div>
      </Form.Item>
    </Form>
  );
};

export default RecoveryForm;
