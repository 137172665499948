import axios from "axios";
import Config from "../config";

const instance = axios.create({
  baseURL: `${Config.urlBackend}`,
  timeout: Config.timeOut,
  headers: {
    Accept: "application/json; charset=utf-8",
    "Content-Type": "application/json"
  },
});

export default instance ;