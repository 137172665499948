import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tabs, Spin } from "antd";
// import IframeView from "../../components/organisms/Iframeview/IframeView";
import InvestigationQuillEditor from "../../components/templates/InvestigationPreviewTemplate/InvestigationQuillEditor";
import useApi from "../../api/useApi";
// import document from "../../api/document";
import research from "../../api/research";
// import PreviewInfoTemplate from "../../components/templates/InvestigationPreviewTemplate/PreviewInfoTemplate";
import InvestigationPreviewTemplate from "../../components/templates/InvestigationPreviewTemplate/InvestigationPreviewTemplate";
import "quill/dist/quill.snow.css"; // Quill CSS
// import researchDummy from "../../assets/Json/researchDataDummy.json"


const viewInvestigation = () => {
  // const getResearchById = useApi(document.getResearchById);
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [dataResearch, setDataResearch] = useState({});
  const [idResearch, setIdResearch] = useState("");
  const [isOwner, setIsOwner] = useState(false);
  const getResearchById = useApi(research.getResearchMetaById);
  const [tabs, setTabs] = useState([]);
  const [itemSelected, setItemSelected] = useState("3");
  // const getResearchContent = useApi(research.getResearchContentById);

  useEffect(() => {
    if (params.key) {
      const auxId = params.key;
      setIdResearch(auxId);
      // getResearchById.request(auxId)
      setLoading(true);
      getResearchById.request(auxId, true);
      // getResearchById.setData(researchDummy);
      // getResearchContent.request(auxId);
    }
  }, [params.key]);

  useEffect(() => {
    if (getResearchById.data) {
      // setDataResearch(getResearchById.data.data);
      const researchData = getResearchById.data.data;
      setDataResearch(researchData);
      setIsOwner(researchData.isOwner);
    }
  }, [getResearchById.data]);
  const reloadPage = () => {
    getResearchById.request(params?.key, true);
    setLoading(true);
  };


  useEffect(() => {
    const items = [
      // {
      //   key: "2",
      //   label: "Datos",
      //   children: (
      //     <div className="rounded-[6px] border border-colorBorder/25 shadow-2xl relative top-[-1px]">
      //       {!loading ? (
      //         <PreviewInfoTemplate data={dataResearch} onHandleEdit={()=>handlerSubmit()} />
      //       ) : (
      //         <div className="flex h-96 justify-center items-center">
      //           <Spin />
      //         </div>
      //       )}
      //     </div>
      //   ),
      //   destroyInactiveTabPane: true,
      // },
      {
        key: "3",
        label: "Editar investigación",
        children: (
          <div className="rounded-[6px] border border-colorBorder/25 shadow-2xl relative top-[-1px]">
            {!loading ? (
              <InvestigationQuillEditor
                id={idResearch}
                dataResearch={dataResearch}
                reload={reloadPage}
              />
            ) : (
              <div className="flex h-96 justify-center items-center">
                <Spin />
              </div>
            )}
          </div>
        ),
      },
      {
        key: "4",
        label: "Vista previa",
        children: (
          <div className="rounded-[6px] border border-colorBorder/25 shadow-2xl relative top-[-1px]">
            {!loading ? (
              <InvestigationPreviewTemplate />
            ) : (
              <div className="flex h-96 justify-center items-center">
                <Spin />
              </div>
            )}
          </div>
        ),
        destroyInactiveTabPane: true,
      },
    ];

    // Filtrar las pestañas para mostrar solo vista previa si no es propietario
    setTabs(isOwner ? items : items.filter((item) => item.key === "4"));
    setItemSelected(isOwner ? "3" : "4");
    setLoading(false);
  }, [isOwner, dataResearch]);

  return !loading.data ? (
    <div className="min-h-screen flex flex-col pt-10 px-2 md:px-2">
      <div className="flex flex-col justify-center items-center ">
        <Tabs
          type="card"
          defaultActiveKey={itemSelected}
          items={tabs}
          className="border-none w-9/12"
        />
      </div>
    </div>
  ) : (
    <div className="flex h-96 justify-center items-center">
      <Spin />
    </div>
  );
};

export default viewInvestigation;
