/* eslint-disable indent */
import React, { useState } from "react";
import { Form, ConfigProvider } from "antd";
import FormEmail from "../../molecules/FormInput/FormEmail";
import FormPassword from "../../molecules/FormInput/FormPassword";
import FormPhone from "../../molecules/FormInput/FormPhone";
import FormGender from "../../molecules/FormSelect/FormGender";
// import AppUploadAvatar from "../../molecules/Upload/AppAvatarUpload";
import FormInput from "../../molecules/FormInput/FormInput";
import FormSelect from "../../molecules/FormSelect/FormSelect";
// import FormUpload from "../../molecules/Upload/FormUpload";
import { selectBlueStyle } from "../../../assets/theme/themeConfig";

const RegisterForm = ({
  optionsGender,
  roles,
  setIsGovernment,
  // setDni,
  // setEntityCard,
}) => {
  const [entityCol, setEntityCol] = useState(null);
  const [classCol, setClassCol] = useState(
    "grid gap-4 mb-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 divide-x  divide-colorBorder2/25 "
  );

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: selectBlueStyle,
        },
      }}
    >
      <div className={classCol}>
        {/* <div className="mb-8 flex justify-center items-center px-0 mx-0">
          <AppUploadAvatar 
            title="Subir Foto" 
            className="text-center"
            settingFileList={settingFilePhoto}
          />
        </div> */}
        <div className=" px-2 ">
          <FormInput
            name="firstName"
            label="Nombre(s):"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Por favor ingrese su Nombre(s)",
                whitespace: true,
              },
              {
                pattern: /^[a-zA-ZÁÉÍÓÚáéíóú#\s]+$/,
                message: "Solo se permiten letras",
              },
              {
                max: 50,
                message: "Máximos 50 caracteres",
              },
            ]}
          />
          <FormInput
            name="lastName"
            label="Apellidos(s):"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Por favor ingrese su Apellido(s)",
                whitespace: true,
              },
              {
                pattern: /^[a-zA-ZÁÉÍÓÚáéíóú#\s]+$/,
                message: "Solo se permiten letras",
              },
              {
                max: 50,
                message: "Máximos 50 caracteres",
              },
            ]}
          />
          <FormSelect
            name="role"
            label="Rol:"
            placeholder="Seleccionar una opción"
            options={roles}
            className="font-semibold text-white_custom text-sizeBase"
            // selectClass="formData bg-lightBlue"
            size="small"
            rules={[
              {
                required: true,
                message: "Por favor seleccione una opción",
              },
            ]}
            labelCol={{ span: 24 }}
          />
          <FormGender
            name="gender"
            label="Sexo:"
            labelCol={{ span: 24 }}
            options={optionsGender}
          />
        </div>
        <div className="px-2">
          <FormPhone name="phone" labelCol={{ span: 24 }} label="Teléfono:" />
          <FormEmail
            name="email"
            label="Correo:"
            labelCol={{ span: 24 }}
            shouldUpdate={(prevValues, currentValues) => {
              setEntityCol(currentValues.role);
              // eslint-disable-next-line no-console
              if (currentValues.role === "Usuario General") {
                setIsGovernment(false);
                setClassCol(
                  "grid gap-4 mb-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 divide-x  divide-colorBorder2/25"
                );
              } else {
                setIsGovernment(true);
                setClassCol(
                  "grid gap-4 mb-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 divide-x  divide-colorBorder2/25"
                );
              }
              return currentValues.role !== prevValues.role;
            }}
          />
          <FormPassword
            name="password"
            label="Contraseña:"
            hasFeedback
            labelCol={{ span: 24 }}
          />
          <FormPassword
            name="confirmPassword"
            label="Confirmar contraseña:"
            labelCol={{ span: 24 }}
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Por favor confirme la contraseña!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("¡Las contraseñas que ingresó no coincide!")
                  );
                },
              }),
            ]}
          />
        </div>
        {entityCol === "Funcionario de Gobierno" ||
        entityCol === "Investigador" ? (
          <div className="  px-2 ">
            <Form.Item noStyle>
              <div>
                <FormInput
                  name="entity"
                  label="Entidad:"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: entityCol === "Funcionario de Gobierno",
                      message: "Por favor ingrese el nombre de la entidad",
                      whitespace: true,
                    },
                    {
                      max: 200,
                      message: "Máximos 200 caracteres",
                    },
                  ]}
                />
                {/*  <FormUpload
                  name="dni"
                  label="Documento de Identidad:"
                  labelCol={{ span: 24 }}
                  settingFile={setDni}
                  rules={[
                    {
                      required: true,
                      message:
                        "Por favor suba la foto del documento de identidad",
                    },
                  ]}
                />
                <FormUpload
                  name="entityCard"
                  label="Carnet de la Entidad:"
                  labelCol={{ span: 24 }}
                  settingFile={setEntityCard}
                  rules={[
                    {
                      required: entityCol === "Funcionario de Gobierno",
                      message:
                        "Por favor suba la foto del carnet de la entidad a la que pertenece",
                    },
                  ]}
                /> */}
              </div>
            </Form.Item>
          </div>
        ) : null}
      </div>
    </ConfigProvider>
  );
};

export default RegisterForm;
