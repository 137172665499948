import React from "react";
import { Select, ConfigProvider } from "antd";
import { selectWhiteStyle } from "../../../assets/theme/themeConfig";

/* eslint-disable react/jsx-props-no-spreading */

const SelectWhite = ({
  className = "",
  selectClass,
  placeholder,
  options,
  onChange,
  loading,
  valueInitial,
}) => {
  const combinedClassSelect = `font-Poppins  rounded-full    text-textCustom  ${className} !important`;
  const combinedClassPoppup = `font-Poppins text-textCustom  ${selectClass} !important`;
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: selectWhiteStyle,
        },
      }}
    >
      <Select
        showSearch
        options={options}
        placeholder={placeholder}
        className={combinedClassSelect}
        popupClassName={combinedClassPoppup}
        optionFilterProp="children"
        filterOption={filterOption}
        onChange={onChange}
        loading={loading}
        defaultValue={valueInitial}
      />
    </ConfigProvider>
  );
};

export default SelectWhite;
