import { useAuth } from "../contexts/AuthContext";
import findElementInArray from "./findElementInArray";

const ValidatePermissions = ({
  children,
  modulePermission,
  func = "actionsTable",
  container = true,
  permission = "",
}) => {
  if (!children) return null;
  const { permissions } = useAuth();
  const validateActions = () => {
    if (func === "actionsTable") {
      if (Array.isArray(permission)) {
        const arrayHasPermission = [];
        permission.forEach((item) => {
          arrayHasPermission.push(
            findElementInArray(permissions, `${modulePermission}${item}`)
          );
        });
        return arrayHasPermission.map((bool, i) => {
          if (bool) return children[i]
          return []
        });
      }
      return [];
    }
    if (func === "removeLastTab") {
      const hasPermission = findElementInArray(
        permissions,
        `${modulePermission}${permission}`
      );
      if (hasPermission) return children;
      if (Array.isArray(children)) {
        const auxChildren = [...children];
        const lengthCh = auxChildren.length;
        auxChildren.splice(lengthCh - 1, 1);
        return auxChildren;
      }
      return [];
    }
    if (func === "oneButton") {
      const hasPermission = findElementInArray(
        permissions,
        `${modulePermission}${permission}`
      );
      if (hasPermission) return children;
      return [];
    }
    return [];
  };
  return container ? (
    <div className="flex" style={{ justifyContent: "space-evenly" }}>
      {validateActions()}
    </div>
  ) : (
    validateActions()
  );
};





export default   ValidatePermissions;
