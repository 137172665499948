import React, { useEffect, useState } from "react";
import { List } from "antd";
import { DeleteOutlined } from "@ant-design/icons";


const ComparativeAnalysisList = ({
  dataList,
  onSelectItem,
  isSelected,
  onDelete,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleSelect = (item) => {
    setSelectedItem(item);
    onSelectItem(item);
  };

  useEffect(() => {
    if (!isSelected) {
      setSelectedItem(null);
    }
  }, [isSelected]);

  return (
    <div className=" max-h-[630px] overflow-y-auto  w-full ">
      {dataList?.length === 0 && (
        <p className="text-center text-color_text">No hay datos</p>
      )}
      {Array.isArray(dataList) &&
        dataList.map((section) => (
          <div
            key={section.key}
            className="border-b-2 border-colorBorder/[.29] pt-2 "
          >
            <h3 className="text-lightBlue2 font-medium mb-2 w-full">
              {section.title}
            </h3>
            <List
              className="w-full"
              dataSource={section.items}
              renderItem={(item) => (
                <List.Item
                  className={` cursor-pointer text-color_text py-1 w-full  ${selectedItem === item ? "bg-lightBlue2 text-white_custom font-medium rounded-[9px]" : ""}`}
                  onClick={() => handleSelect(item)}
                >
                  {item.analysis.name}
                  {item.analysis.isOwner && (
                    <DeleteOutlined
                      className="float-right"
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete(item);
                      }}
                    />
                  )}
                </List.Item>
              )}
            />
          </div>
        ))}
    </div>
  );
};
export default ComparativeAnalysisList;
