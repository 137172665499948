import React from "react";
import { Form,InputNumber} from "antd";

// import "../../../index.css";
/* eslint-disable react/jsx-props-no-spreading */

const FormNumber = ({ 
  name, 
  label, 
  placeholder, 
  styleInput = "", 
  className = "",
  rules, 
  minNumber,
  ...props }) =>{
  const combinedClassName = `font-Poppins font-light text-sizeBase text-black_custom ${className} `;
  return (
    <Form.Item
      colon={false}
      name={name}
      label= {
        label?(
          <span className="font-Poppins font-light text-color_text text-center">
            {label}
          </span>
        ): null}
      rules={rules}
      className={combinedClassName}
      {...props}
    > 
      <InputNumber
        placeholder={placeholder}
        className={`font-Poppins  text-sizeBase text-black_custom  ${styleInput}`}
        min={minNumber}
        // sharpes="round"
      />
    </Form.Item>
  )
};

export default FormNumber;
