/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const AppInputSearch = ({
  onSearch,
  onChange,
  options,
  placeholder,
  className,
  popupClassName,
  mode = "",
  loading
}) => (
  <Select
    loading={loading}
    mode={mode}
    showSearch
    className={className}
    placeholder={placeholder}
    defaultActiveFirstOption={false}
    suffixIcon={<SearchOutlined />}
    filterOption={false}
    onSearch={onSearch}
    onChange={onChange}
    notFoundContent={null}
    options={options}
    popupClassName={popupClassName}
  />
);

export default AppInputSearch;
