import React from "react";
import { Form } from "antd";
import AppMultiSelect from "../../atoms/MultiSelect/AppMultiSelect";
/* eslint-disable react/jsx-props-no-spreading */

const FormReportyears = ({ className = "", name, label, onSelectZone, ...props }) => {
  const combinedClassName = `font-Poppins text-black_custom formhome ${className}`;
  const options = [
    {
      id: 1,
      value: "2023",
      label: "2023",
    },
    {
      id: 2,
      value: "2022",
      label: "2022",
    },
    {
      id: 3,
      value: "2021",
      label: "2021",
    },
    {
      id: 4,
      value: "2020",
      label: "2020",
    },
    
    {
      id: 5,
      value: "2019",
      label: "2019",
    },
    {
      id: 6,
      value: "2018",
      label: "2018",
    },
    {
      id: 7,
      value: "2017",
      label: "2017",
    },
    {
      id: 8,
      value: "2016",
      label: "2016",
    },
    {
      id: 9,
      value: "2015",
      label: "2015",
    },
    {
      id: 10,
      value: "2014",
      label: "2014",
    },
    
  ];
  return (
    <Form.Item
      name={name}
      label={label}
      {...props}
      className={combinedClassName}
      rules={[
        {
          required: true,
          message: "Por favor seleccione una opción!",
        },
      ]}
    >
      <AppMultiSelect 
        onSelectOption={onSelectZone}
        maxTagCount={2}
        maxTagTextLength={10} 
        options={options} 
        placeholder="Selecciona una opción" 
        className="rounded-full text-white_custom" />
    </Form.Item>

  );
}

export default FormReportyears;