import React from "react";
import { Typography } from "antd";
/* eslint-disable react/jsx-props-no-spreading */


const { Title } = Typography;
function AppTitle({ title, level,className="" , ...props}) {
  const combinedClassName = `font-Poppins ${className}`;
  return (
    <Title level={level} className={combinedClassName} {...props}>{title} </Title>
  );
}
export default AppTitle;
