/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import { Button, FloatButton, Spin } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
// import { useNavigate } from "react-router-dom";
import AppTitle from "../../atoms/Title/AppTitle";
import AppModal from "../../molecules/Modal/Modal";
// import ROUTES from "../../../constant/router";
import TableTemplate from "../TableTemplate";
import FormAutoSearch from "../../molecules/FormAutoSearch/FormAutoSearch";
import CreateForm from "../../organisms/UserForm/CreateForm";
import useApi from "../../../api/useApi";
import user from "../../../api/user";
import ModalConfirm from "../../molecules/Modal/ModalConfirm";
import { localeTime } from "../../../helpers/transformUTCtoColombiaTime";
import { useAuth } from "../../../contexts/AuthContext";
import { listCreateUserAdmin } from "../../../constant/listCreateUserRoles";

const UsersList = () => {
  const getAllUsers = useApi(user.getAllUsers);
  const createUser = useApi(user.createUser);
  const updateMultipleUsers = useApi(user.updateMultipleUsers);
  const updateUser = useApi(user.updateUser);
  const [loading, setLoading] = useState({
    modal: false,
    create: false,
    search: false,
    list: false,
  });
  const [modalActive, setModalActive] = useState(false);
  const [modalActiveConfirm, setModalActiveConfirm] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isSearch, setIsSearch] = useState(false);

  const [data, setData] = useState();
  const [dataUser, setDataUser] = useState();
  const [action, setAction] = useState("");
  const [statusSelected, setStatusSelected] = useState("");
  const [idUserSelected, setIdUserSelected] = useState([]);
  const { setMessage } = useAuth();
  const [metadata, setMetaData] = useState({
    total: 10,
    page: 1,
    page_size: 10,
  });

  //   const navigate = useNavigate();
  const closeModal = () => {
    setModalActive(false);
    setLoading((prev) => ({ ...prev, modal: false }));
  };

  const gettingUsersData = () => {
    setLoading((prev) => ({ ...prev, list: true }));
    getAllUsers.request(null, metadata.page, metadata.page_size);
  };

  // getAllUsers.request(null, metadata.page, metadata.page_size);

  useEffect(() => {
    // hacer la petición a la API para obtener los datos de la página
    gettingUsersData();
  }, [metadata.page]);

  useEffect(() => {
    if (getAllUsers.data) {
      setLoading((prev) => ({ ...prev, list: false, search: false }));
      const { items, ...rest } = getAllUsers.data.data;
      setData(items);
      setMetaData(rest);
      getAllUsers.setData();
    }
    if (updateMultipleUsers.data) {
      gettingUsersData();
      setMessage({
        type: "success",
        title: "Resultado",
        description: "Los usuarios fueron editados exitosamente.",
      });
      updateMultipleUsers.setData();
    }
    if (updateUser.data) {
      gettingUsersData();
      setMessage({
        type: "success",
        title: "Resultado",
        description: "El usuario fue editado exitosamente.",
      });
      closeModal();
      updateUser.setData();
    }
  }, [getAllUsers.data, updateMultipleUsers.data, updateUser.data]);

  useEffect(() => {
    if (getAllUsers.error) {
      setLoading((prev) => ({ ...prev, list: false, search: false }));
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar listar los usuarios. Por favor recargue la página.",
      });
    }
    if (updateMultipleUsers.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar actualizar el/los usuario/s. Por favor revise los datos y vuelva a intentarlo.",
      });
    }
    if (updateUser.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar actualizar el usuario. Por favor revise los datos y vuelva a intentarlo.",
      });
    }
  }, [getAllUsers.error, updateMultipleUsers.error, updateUser.error]);

  const handlePageChange = (page) => setMetaData((prev) => ({ ...prev, page }));

  const openModal = () => {
    setLoading((prev) => ({ ...prev, modal: true }));
    setModalActive(true);
  };

  const onEdit = (record) => {
    openModal();
    setDataUser(record);
    setAction("edit");
  };

  const onChangeStatus = (record, status) => {
    const auxData = {
      userIds: [record],
      updateField: { status },
    };
    updateMultipleUsers.request(auxData);
  };

  const onCreate = () => {
    openModal();
    setAction("create");
  };

  const closeConfirmModal = () => setModalActiveConfirm(false);

  const confirmCloseModal = () => {
    setModalActive(false);
    setModalActiveConfirm(false);
    const auxData = {
      userIds: idUserSelected,
      updateField: { isDeleted: true },
    };
    updateMultipleUsers.request(auxData);
  };

  const getRowKeys = (idsToFind) => {
    const statuses = data
      .filter((item) => idsToFind.includes(item._id))
      .map((item) => item.status);
    const firstStatus = statuses.length > 0 ? statuses[0] : [];
    let allSame = false;
    if (firstStatus.length > 0)
      allSame = statuses.every((status) => status === firstStatus);
    if (allSame) {
      setStatusSelected(firstStatus);
    } else {
      setStatusSelected("none");
    }
    setSelectedRowKeys(idsToFind);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (values) => getRowKeys(values),
  };

  const handlerSearch = async (value) => {
    if (value !== "") {
      getAllUsers.request(
        `{"firstName":"${value}","lastName":"${value}","role":"${value}","email":"${value}"}`,
        metadata.page,
        metadata.page_size,
        null,
        true
      );
      setLoading((prev) => ({ ...prev, search: true }));
      setIsSearch(true);
    }
  };

  const onResetSearch = () => {
    gettingUsersData();
    setIsSearch(false);
    setLoading((prev) => ({ ...prev, search: false }));
  };

  const onChangeMultipleUsers = (status) => {
    const auxData = {
      userIds: selectedRowKeys,
      updateField: { status },
    };
    updateMultipleUsers.request(auxData);
  };

  const onConfirmDeleteMulti = () => {
    setIdUserSelected(selectedRowKeys);
    setModalActiveConfirm(true);
  };

  const onCreateEditUser = (auXInfoUser) => {
    const { phone, ...rest } = auXInfoUser;
    const auxPhone = phone.toString();
    if (action === "create") {
      createUser.request({ ...rest, phone: auxPhone });
    } else {
      updateUser.request(dataUser._id, { ...rest, phone: auxPhone });
    }
  };

  // Las columnas de la tabla
  const columns = [
    {
      title: <span className="font-medium text-center text-xl">Rol</span>,
      dataIndex: "role",
      key: "role",
      align: "center",
      render: (text) => (
        <span className="text-center text-black_custom ">{text}</span>
      ),
      className: "border-b-0",
      sorter: (a, b) => a.role.localeCompare(b.role),
      /* defaultSortOrder: "descend",
      sorter: (a, b) => a.role.length - b.role.length,
      sortOrder: sortedInfo.columnKey === 'role' ? sortedInfo.order : null, */
    },
    {
      title: (
        <span className="font-medium text-center text-xl ">
          Nombre completo
        </span>
      ),
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_, record) => (
        <span className="  text-center text-sizeBase text-black_custom ">
          {record?.firstName} {record?.lastName}
        </span>
      ),
      className: "border-b-0",
      width: 250,
    },
    {
      title: <span className="font-medium text-center text-xl">Correo</span>,
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (text) => (
        <span className="text-center text-black_custom ">{text}</span>
      ),
      className: "border-b-0",
    },
    {
      title: <span className="font-medium text-center text-xl">Teléfono</span>,
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (text) => (
        <span className="text-center text-black_custom">{text}</span>
      ),
      className: "border-b-0",
    },
    {
      title: (
        <span className="font-medium text-center text-xl">
          Fecha de creación
        </span>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text) => (
        <span className="text-center text-black_custom">
          {localeTime(text)}
        </span>
      ),
      className: "border-b-0",
    },
    {
      title: <span className="font-medium text-center text-xl">Estado</span>,
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text) => (
        <span className="text-center text-black_custom">{text}</span>
      ),
      className: "border-b-0",
    },
    {
      title: <span className="font-medium text-center text-xl ">Acciones</span>,
      key: "acciones",
      align: "center",
      ellipsis: true,
      render: (_, record) => (
        <div className="flex space-x-2 justify-center  ">
          {record?.status === "Pendiente" || record?.status === "Rechazado" ? (
            <Button
              className="bg-aquamarineBlue text-white shadow-xl hover:shadow-none "
              onClick={() => onChangeStatus(record?._id, "Activo")}
            >
              Aprobar
            </Button>
          ) : null}
          {record?.status === "Pendiente" ? (
            <Button
              className="bg-[#939393] text-white shadow-xl hover:shadow-none "
              onClick={() => onChangeStatus(record?._id, "Rechazado")}
            >
              Rechazar
            </Button>
          ) : null}
          {record?.status === "Suspendido" ? (
            <Button
              className="bg-aquamarineBlue text-white shadow-xl hover:shadow-none "
              onClick={() => onChangeStatus(record?._id, "Activo")}
            >
              Activar
            </Button>
          ) : null}
          {record?.status === "Activo" ? (
            <Button
              className="bg-[#939393] text-white shadow-xl hover:shadow-none "
              onClick={() => onChangeStatus(record?._id, "Suspendido")}
            >
              Suspender
            </Button>
          ) : null}
          <Button
            className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none "
            icon={<EditOutlined />}
            onClick={() => onEdit(record)}
          />
          {record?.status === "Suspendido" || record?.status === "Rechazado" ? (
            <Button
              className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none "
              icon={<DeleteOutlined />}
              onClick={() => {
                setModalActiveConfirm(true);
                setIdUserSelected([record?._id]);
              }}
            />
          ) : null}
        </div>
      ),
      width: 150,
      className: "border-b-0",
    },
  ];

  const returnButtonsMulti = () => (
    <>
      {statusSelected === "Pendiente" || statusSelected === "Rechazado" ? (
        <Button
          className="bg-aquamarineBlue text-white hover:shadow-xl shadow-none w-[110px] "
          onClick={() => onChangeMultipleUsers("Activo")}
        >
          Aprobar
        </Button>
      ) : null}
      {statusSelected === "Pendiente" ? (
        <Button
          className="bg-[#939393] text-white hover:shadow-xl shadow-none w-[110px] "
          onClick={() => onChangeMultipleUsers("Rechazado")}
        >
          Rechazar
        </Button>
      ) : null}
      {statusSelected === "Suspendido" ? (
        <Button
          className="bg-aquamarineBlue text-white hover:shadow-xl shadow-none w-[110px] "
          onClick={() => onChangeMultipleUsers("Activo")}
        >
          Activar
        </Button>
      ) : null}
      {statusSelected === "Activo" ? (
        <Button
          className="bg-[#939393] text-white hover:shadow-xl shadow-none w-[110px] "
          onClick={() => onChangeMultipleUsers("Suspendido")}
        >
          Suspender
        </Button>
      ) : null}
      {statusSelected === "Suspendido" || statusSelected === "Rechazado" ? (
        <Button
          className="bg-[#939393] text-white hover:shadow-xl shadow-none w-[110px] "
          icon={<DeleteOutlined />}
          onClick={onConfirmDeleteMulti}
        />
      ) : null}
    </>
  );

  return (
    <div className="min-h-screen bg-white_custom flex flex-col items-center pt-10 ">
      <div className="w-10/12 mb-2 grid justify-items-end ">
        <Button
          shape="round"
          size="large"
          type="primary"
          className="font-Poppins shadow-lg rounded-full bg-lightBlue text-white_custom"
          loading={loading.modal}
          onClick={onCreate}
        >
          Crear usuario
        </Button>
      </div>

      <div className=" w-11/12 bg-white_custom p-6 rounded-[45px] border border-colorBorder/25 shadow-2xl">
        <div>
          <FormAutoSearch
            loading={loading.search}
            submitSearch={handlerSearch}
            resetValue={!isSearch}
          />
          {isSearch && (
            <div style={{ textAlign: "right" }}>
              <Button
                shape="round"
                size="large"
                type="primary"
                className="font-Poppins shadow-lg rounded-full bg-lightBlue text-white_custom"
                onClick={onResetSearch}
              >
                Restablecer búsqueda
              </Button>
            </div>
          )}
        </div>
        <div className="flex justify-center items-center mb-4">
          <AppTitle
            title="Usuarios registrados"
            level={3}
            className="text-lightBlue2 font-bold  text-center w-1/2"
          />
        </div>
        {!loading.list ? (
          <TableTemplate
            enableScroll
            data={data}
            handlePageChange={handlePageChange}
            total={metadata.total}
            columns={columns}
            page_size={metadata.page_size}
            currentPage={metadata.page}
            rowSelection={rowSelection}
            rowKey="_id"
            className=" "
          />
        ) : (
          <div className="flex justify-center items-center h-80">
            <Spin />
          </div>
        )}
        {modalActive ? (
          <AppModal
            title={action === "create" ? "Nuevo usuario" : "Editar usuario"}
            open={modalActive}
            onCancel={closeModal}
            footer={false}
            className=" text-lightBlue2 font-bold text-lg text-center w-1/2"
          >
            <CreateForm
              roles={listCreateUserAdmin}
              loading={loading.create}
              dataUser={dataUser}
              action={action}
              onSubmit={onCreateEditUser}
            />
          </AppModal>
        ) : null}
        {modalActiveConfirm ? (
          <ModalConfirm
            centered
            okText="Aceptar"
            handleCancel={closeConfirmModal}
            handleOK={confirmCloseModal}
            closeModal={closeConfirmModal}
            modalActive={modalActiveConfirm}
            text="¿Estas seguro que deseas eliminar este/os usuario/s?"
          />
        ) : null}

        {selectedRowKeys.length > 0 ? (
          <FloatButton
            shape="square"
            className="min-w-fit px-5 py-2 bg-white cursor-default left-1/2 -translate-x-1/2"
            style={{ insetInlineEnd: 24 }}
            description={
              <div className="flex items-center md:space-x-2">
                <p>{selectedRowKeys.length} Elementos seleccionados</p>
                {returnButtonsMulti()}
              </div>
            }
          />
        ) : null}
      </div>
    </div>
  );
};
export default UsersList;
