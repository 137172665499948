/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import { createContext, useContext, useState, useMemo } from "react";
import PropTypes from "prop-types";

const DataContext = createContext();
export function useData() {
  return useContext(DataContext);
}

export function DataProvider({ children }) {
  const [chartToSnapshot, setChartToSnapshot] = useState();
  const [urlToUploadSnapshot, setUrlToUploadSnapshot] = useState();

  const value = useMemo(
    () => ({
      chartToSnapshot,
      setChartToSnapshot,
      urlToUploadSnapshot,
      setUrlToUploadSnapshot,
    }),
    [
      chartToSnapshot,
      setChartToSnapshot,
      urlToUploadSnapshot,
      setUrlToUploadSnapshot,
    ]
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}

DataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
