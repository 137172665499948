/*
if format empty return a string days,hours,minutes,seconds 
else return number values
d return days
h return hours
m return minutes
s return seconds   */
const calculateDifferenceTimeStamps = (
  timeStampStart,
  timeStampEnd,
  format = ""
) => {
  // Calculate the difference in milliseconds
  const timeDifference = timeStampEnd - timeStampStart;

  // Convert the difference to a readable format
  const millisecondsInSecond = 1000;
  const millisecondsInMinute = millisecondsInSecond * 60;
  const millisecondsInHour = millisecondsInMinute * 60;
  const millisecondsInDay = millisecondsInHour * 24;

  const days = Math.floor(timeDifference / millisecondsInDay);
  const hours = Math.floor(
    (timeDifference % millisecondsInDay) / millisecondsInHour
  );
  const minutes = Math.floor(
    (timeDifference % millisecondsInHour) / millisecondsInMinute
  );
  const seconds = Math.floor(
    (timeDifference % millisecondsInMinute) / millisecondsInSecond
  );

  if (format === "d") return days;
  if (format === "h") return hours;
  if (format === "m") return minutes;
  if (format === "s") return seconds;
  return `Time passed: ${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;
};

export default calculateDifferenceTimeStamps;
