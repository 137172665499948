import React from "react";
import { Form } from "antd";
import AppInputSearch from "../../atoms/Input/AppInputSearch";
// import "../../../index.css";
/* eslint-disable react/jsx-props-no-spreading */

const FormInputSearch = ({
  name,
  label,
  placeholder,
  className = "",
  rules,
  labelCol = "",
  styleForm = "",
  popupclass = "",
  onChange,
  onSearch,
  options,
  mode,
  loading,
}) => {
  const combinedClassName = `font-Poppins text-white_custom  ${styleForm}`;
  const combinedClassSelect = `font-Poppins font-light text-sizeBase text-black_custom rounded-full ${className}`;
  const combinedClassPopup = `font-Poppins  ${popupclass}`;
  return (
    <Form.Item
      labelCol={labelCol}
      colon={false}
      name={name}
      label={
        label ? (
          <span className="font-Poppins font-light text-sizeBase text-color_text">
            {label}
          </span>
        ) : null
      }
      rules={rules}
      className={combinedClassName}
    >
      <AppInputSearch
        loading={loading}
        onSearch={onSearch}
        onChange={onChange}
        placeholder={placeholder}
        className={combinedClassSelect}
        popupClassName={combinedClassPopup}
        options={options}
        mode={mode}
      />
    </Form.Item>
  );
};

export default FormInputSearch;
