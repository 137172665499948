import React from "react";
/* eslint-disable react/jsx-props-no-spreading */

const AppImg = ({ src, alt, className = "", width=20, ...props }) => (
  <img
    src={src}
    alt={alt}
    width={width}
    className={className}
    {...props}
  />
)

export default AppImg;