import { Layout } from "antd";
import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import { confirmResetPassword } from "@aws-amplify/auth";
// import LoginTemplate from "../../components/templates/LoginTemplate/LoginTemplate";
import { useAuth } from "../../contexts/AuthContext";
import ChangePasswordForm from "../../components/organisms/ChangePassword/ChangePasswordForm";

const { Content } = Layout;

const RecoveryPage = () => {
  const [loading, setLoading] = useState(false);
  // const changePsw = useApi(auth.changePswNoAuth);
  const {
    setMessage,
    emailRecovery,
    handleResetPassword,
    handleResetPasswordNextSteps,
  } = useAuth();
  const navigate = useNavigate();

  const handleConfirmResetPassword = async (e) => {
    if (emailRecovery) {
      const { password, confirm, code } = e;
      if (password === confirm) {
        setLoading(true);
        try {
          let output = await confirmResetPassword({
            username: emailRecovery,
            confirmationCode: code,
            newPassword: confirm,
          });
          setLoading(false);
          if (!output) {
            output = {
              nextStep: {
                resetPasswordStep: "DONE",
              },
            };
            const route = await handleResetPasswordNextSteps(output);
            navigate(route);
          }
        } catch (error) {
          setLoading(false);
          if (error.toString().includes("Invalid verification code provided")) {
            setMessage({
              type: "error",
              title: "Error",
              description:
                "El código proporcionado no es valido. Confírmelo y vuelva a intentarlo.",
            });
          } else {
            navigate("/login");
            setMessage({
              type: "error",
              title: "Error",
              description:
                "Hubo un error vuelva a iniciar el proceso de cambio de contraseña",
            });
          }
        }
      } else {
        setMessage({
          type: "error",
          title: "Error",
          description: "Las contraseñas no coinciden. Inténtelo de nuevo.",
        });
        setLoading(false);
      }
    } else {
      setMessage({
        type: "error",
        title: "Error",
        description: "El proceso se hizo incorrectamente. Vuelva a intentarlo.",
        duration: 6,
      });
      navigate("/login");
    }
    /*   } catch (error) {
      setLoading(false);
      setMessage({
        type: "error",
        title: "Error",
        description: error,
      });
    } */
  };

  const handleResendCode = async (e) => {
    e.preventDefault(); // Prevent the page from reloading
    const route = await handleResetPassword(emailRecovery);
    navigate(route);
  };

  /*   const handleFormSubmit = async (e) => {
    const { password, confirmPassword } = e;
    if (password === confirmPassword) {
      changePsw.request({ token, newPassword: confirmPassword });
      setLoading(true);
    } else {
      setMessage({
        type: "error",
        title: "Error",
        description: "Las contraseñas no coinciden. Inténtelo de nuevo.",
      });
      setLoading(false);
    }
  }; */
  /*   useEffect(() => {
    if (changePsw.data) {
      setLoading(false);
      navigate("/login");
      setMessage({
        type: "success",
        title: "Resultado",
        description: "La contraseña fue cambiada correctamente.",
      });
      changePsw.setData();
    }
  }, [changePsw.data]);

  useEffect(() => {
    if (changePsw.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error en el servidor al intentar cambiar la contraseña. Inténtelo de nuevo mas tarde.",
        code: changePsw.error,
      });
      setLoading(false);
    }
  }, [changePsw.error]); */

  const handleBackButton = () => navigate("/login");
  return (
    <Layout>
      <Content>
        {/* <LoginTemplate
          loading={loading}
          handlerPassword={handleConfirmResetPassword}
          handleBackButton={handleBackButton}
          isRecovery
        /> */}
        <ChangePasswordForm
          loading={loading}
          handlerPassword={handleConfirmResetPassword}
          handleBackButton={handleBackButton}
          haveBorder
          handleResendCode={handleResendCode}
        />
      </Content>
    </Layout>
  );
};

export default RecoveryPage;
