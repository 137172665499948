import React from "react";
import { Button } from "antd";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";

const AppPagination = ({ page = 1, total, page_size, onChange }) => {
  const totalPages = Math.ceil(total / page_size);

  return (
    <div className="flex justify-center items-center mt-4">
      <Button
        className="bg-white_custom border-none "
        icon={
          <LeftCircleOutlined style={{ color: "#4CACBC", fontSize: "17px" }} />
        }
        onClick={() => {
          onChange(page - 1);
        }}
        disabled={page === 1}
      />
      <span className="text-dark_gray font-normal text-base ">
        Página {page} de {totalPages}
      </span>
      <Button
        className="bg-white_custom border-none "
        icon={
          <RightCircleOutlined style={{ color: "#4CACBC", fontSize: "17px" }} />
        }
        onClick={() => {
          onChange(page + 1);
        }}
        disabled={page === totalPages}
      />
    </div>
  );
};

export default AppPagination;
