/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css"; // Quill CSS
import { Button, Spin } from "antd";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import PlotlyContentRenderer from "../../molecules/QuillPlotly/PlotlyContentRenderer";
import AppModal from "../../molecules/Modal/Modal";
import ChartCreateForm from "../../organisms/ChartForm/ChartCreateForm";
import { useAuth } from "../../../contexts/AuthContext";
import listCharts from "../../../constant/listCharts";
import ChartForm from "../../organisms/ChartForm/ChartForm";
import documentApi from "../../../api/document";
import analysis from "../../../api/analysisRequest";
import research from "../../../api/research";
import listAggregations from "../../../constant/listAggregations";
import useApi from "../../../api/useApi";
import transformToSelectArray from "../../../helpers/transformToSelectArray";
import PreviewInfoTemplate from "./PreviewInfoTemplate";
import fetchingFiles from "../../../api/fetchingFiles";


const InvestigationQuillEditor = ({ dataResearch, reload  /* id */ }) => {
  const params = useParams();
  const [dataCharts, setDataCharts] = useState([]);
  const [dataImg, setDataImg] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const getAllDocuments = useApi(documentApi.getAllDocuments);
  const getColumnsFile = useApi(documentApi.getColumnsFile);
  const updateResearchContent = useApi(research.updateResearchContent);
  const updateResearchMeta = useApi(research.updateResearchMeta);
  const getResearchById = useApi(research.getResearchMetaById);
  const [listColumnsXY, setListColumnsXY] = useState([]);
  const [listFiles, setListFiles] = useState([]);
  const [action, setAction] = useState("");
  const [detailChart, setDetailChart] = useState("");
  const [idSelectedChart, setIdSelectedChart] = useState("");
  const [renderOnce, setRenderOnce] = useState(false);
  const [loading, setLoading] = useState({
    columns: true,
    submit: false,
    content: false,
  });
  const { setMessage } = useAuth();
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ size: [] }],
          [{ font: [] }],
          ["bold", "italic", "underline", "strike"], // Basic formatting
          ["code-block", "blockquote"], // Additional formatting
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ], // Lists
          [{ color: [] }, { background: [] }], // Text colors
          [{ align: [] }], // Text alignment
          ["clean"], // Clear formatting
          ["image"], // Custom button for inserting images
          ["insertPlotly"], // Custom button for inserting Plotly chart
        ],
        // handlers: {
        //   image: () => handleImageUpload(), // Agregar manejador de imágenes personalizado
        // },
      },
    },
  });
  const [content, setContent] = useState(""); // Track editor content

  const insertImage = (file, key) => {
    const imageExtension = `.${file.name.split(".").pop()}`;

    // Crear un objeto de imagen y guardarlo en el estado
    setDataImg((prev) => [...prev, { key, file, imageExtension }]);

    // Insertar una referencia de imagen en el contenido del editor
    const range = quill.getSelection();
    if (range) {
      quill.insertText(range.index, "\n ");
      const imageText = `[Imagen dinámica, ${imageExtension}, ${key}]`;
      quill.insertEmbed(range.index + 1, imageText);
    }
  };

  const handleImageUpload = () => {
    const key = uuidv4();
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = () => {
      const file = input.files[0];
      if (file) {
        insertImage(file, key);
      }
    };
  };

  
  useEffect(() => {
    getAllDocuments.request(100, 1, "all-files");
    return () => {
      setRenderOnce(false);
    };
  }, []);

  useEffect(() => {
    if (!showModal) {
      setListColumnsXY([]);
      setLoading((prev) => ({ ...prev, columns: true }));
    }
  }, [showModal]);

  useEffect(() => {
    if (getAllDocuments.data) {
      const auxData = getAllDocuments.data.data?.items;
      const filterNotWeb = auxData.filter(
        (item) => item.fileExtension !== "web"
      );
      const filterNotPdf = filterNotWeb.filter(
        (item) => item.fileExtension !== ".pdf"
      );
      const filterOnlyReady = filterNotPdf.filter(
        (item) => item.status === "ready"
      );
      const auxList = transformToSelectArray(filterOnlyReady, "name", "_id");
      setListFiles(auxList);
      getAllDocuments.setData();
      setLoading((prev) => ({ ...prev, content: false }));
    }
    if (getColumnsFile.data) {
      const columns = getColumnsFile.data.data;
      if (Array.isArray(columns)) {
        const auxCols = transformToSelectArray(columns, "name", "name");
        setLoading((prev) => ({ ...prev, columns: false }));
        setListColumnsXY(auxCols);
      }
      getColumnsFile.setData();
    }
    if (updateResearchContent.data) {
      const auxData = updateResearchContent.data.data;

      if (auxData.listUrlImages && auxData.listUrlImages.length > 0) {
        // Crear una lista de archivos a cargar
        const filesToUpload = auxData.listUrlImages.reduce(
          (accumulator, img) => {
            // Encuentra la imagen correspondiente en `dataImg` usando `key`
            const fileImg = dataImg.find((item) => item.key === img.key);

            if (fileImg) {
              // Extraer el archivo y la información necesaria para la carga
              const { file } = fileImg;
              const extension = file.name.split(".").pop();

              // Agregar el archivo y la URL a la lista
              accumulator.push({
                url: img.url, // La URL prefirmada donde se subirá la imagen
                file: {
                  originFileObj: file, // El archivo como tal
                  type: file.type || `image/${extension}`, // Determinar el tipo de archivo si no está definido
                },
              });
            }
            return accumulator;
          },
          []
        );

        // Subir todas las imágenes usando fetchingFiles
        if (filesToUpload.length > 0) {
          try {
            fetchingFiles(filesToUpload);
            setMessage({
              type: "success",
              title: "Exito",
              description: "Las imágenes se han subido exitosamente.",
            });
          } catch (error) {
            setMessage({
              type: "error",
              title: "Error",
              description:
                "Hubo un error al intentar subir las imágenes. Inténtelo de nuevo.",
            });
          }
        }
      }

      setMessage({
        type: "success",
        title: "Exito",
        description: "Se ha guardado la información exitosamente",
      });
      updateResearchContent.setData();
    }
    if (updateResearchMeta.data) {
      
      setMessage({
        type: "success",
        title: "Resultado",
        description: "La investigación fue actualizada exitosamente.",
      });
      reload();	
      
      
      updateResearchMeta.setData();
    }
  }, [getAllDocuments.data, getColumnsFile.data, updateResearchContent.data,  updateResearchMeta.data]);
  useEffect(() => {
    if (getAllDocuments.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar obtener las fuentes. Por favor recargue la pagina.",
        code: getAllDocuments.error,
      });
    }
    if (getColumnsFile.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar obtener las columnas para la fuente seleccionada. Por favor seleccione otra vez la fuente.",
        code: getColumnsFile.error,
      });
    }
    if (updateResearchContent.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar guardar la información. Por favor intente de nuevo.",
      });
    }
    if (updateResearchMeta.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar actualizar la investigación. Inténtelo de nuevo mas tarde.",
      });
      
      updateResearchMeta.setData();
    }
  }, [
    getAllDocuments.error,
    getColumnsFile.error,
    updateResearchContent.error,
    updateResearchMeta.error,
  ]);

  useEffect(() => {
    if (dataResearch.markdown) {
      setContent(dataResearch.markdown);
      // setDataCharts(dataResearch.graphs);
    }
    if (dataResearch.graphs) {
      const charts = dataResearch.graphs.map((item) => {
        let auxDataChart = item.data;
        if (item.type === "stackedBar" || item.type === "scatter")
          auxDataChart = item.data?.arrayStacked;
        return { ...item, data: auxDataChart };
      });
      setDataCharts(charts);
    }
    if (dataResearch.listImages) {
      setDataImg(dataResearch.listImages);
    }
  }, []);

  useEffect(() => {
    if (quill) {
      if (dataResearch.markdown && !renderOnce) {
        quill.clipboard.dangerouslyPasteHTML(dataResearch.markdown);
        setRenderOnce(true);
      }
      const customButton = document.querySelector(".ql-insertPlotly");
      if (customButton) {
        customButton.innerHTML = `
        <svg class="chart-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
          <path d="M4 22h4v-6H4v6zm6-8h4v8h-4v-8zm6-6h4v14h-4V8z"/>
        </svg>
      `;
        customButton.addEventListener("click", () => {
          setAction("create");
          setShowModal(true);
        });
      }

      // Track content changes in the Quill editor
      quill.on("text-change", () => {
        setContent(quill.root.innerHTML); // Update content state with editor content
      });

      quill.getModule("toolbar").addHandler("image", handleImageUpload);
    }
  }, [quill]);

  const insertLineBreak = () => {
    if (quill) {
      quill.focus();
      const range = quill.getSelection();
      if (range) {
        quill.insertText(range.index, "\n");
      }
    }
  };

  const insertPlotlyChart = (title, chartType, id) => {
    if (quill) {
      quill.focus();
      const range = quill.getSelection();
      if (range) {
        quill.insertText(range.index, "\n");
        const chartText = `[Gráfica dinámica(${chartType}), ${title}, ${id}]`;
        quill.insertEmbed(range.index + 1, chartText);
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onChangeCol = (val) => {
    setLoading((prev) => ({ ...prev, columns: true }));
    getColumnsFile.request(val);
  };
  const handleSave = () => {
    const editorContent = quill.root.innerHTML;

    // Regular expressions to match image and chart markers in the editor content
    const chartRegex = /\[Gráfica dinámica.*?,.*?, (.*?)\]/g;
    const imageRegex = /\[Imagen dinámica,.*?, (.*?)\]/g;

    // Collect all chart and image IDs currently in the editor
    const chartIdsInEditor = Array.from(
      editorContent.matchAll(chartRegex),
      (match) => match[1]
    );
    const imageIdsInEditor = Array.from(
      editorContent.matchAll(imageRegex),
      (match) => match[1]
    );

    // Filter `dataCharts` to only include charts present in the editor
    const filteredDataCharts = dataCharts.filter((chart) =>
      chartIdsInEditor.includes(chart.id)
    );

    // Filter `dataImg` to only include images present in the editor
    const filteredDataImg = dataImg.filter((img) =>
      imageIdsInEditor.includes(img.key)
    );

    // This is where you'd typically send the content to the backend
    const dataChartsAux = filteredDataCharts.map((item) => {
      let auxDataChart = item.data;
      if (item.type === "stackedBar" || item.type === "scatter")
        auxDataChart = { arrayStacked: auxDataChart };
      const auxItem = { ...item, data: auxDataChart };
      return auxItem;
    });
    updateResearchContent.request(params?.key, {
      markdown: editorContent,
      graphs: dataChartsAux,
      listImages: filteredDataImg.map((img) => ({
        key: img.key,
        imageExtension: img.imageExtension,
      })),
    });
    // Example: Use axios or fetch to send this content to an API
    // axios.post('/api/save-content', { content })
    //   .then(response => console.log('Content saved successfully'))
    //   .catch(error => console.error('Error saving content:', error));
  };

  const handleCreateChart = (values) => {
    const { title, chartType } = values;
    const lengthCharts = dataCharts.length;
    if (lengthCharts < 5) {
      const id = uuidv4();
      setDataCharts((prev) => [
        ...prev,
        {
          id,
          name: title,
          data: {
            x: [1, 2, 3, 4, 5],
            y: [1, 2, 3, 4, 5],
          },
          type: chartType,
        },
      ]);
      insertPlotlyChart(title, chartType, id);
      setShowModal(false);
    } else {
      setMessage({
        type: "warning",
        title: "Error",
        description: "No puedes agregar mas de 5 gráficas",
      });
    }
  };

  const handleEditChart = (id) => {
    setIdSelectedChart(id);
    setAction("edit");
    const chartFound = dataCharts.find((item) => item.id === id);
    setDetailChart(chartFound);
    setShowModal(true);
  };

  const onPublished = () => {
    // eslint-disable-next-line no-underscore-dangle
    updateResearchMeta.request(params?.key, { status: "published" });
    
  };

  const handleSubmit = async (values) => {
    setLoading((prev) => ({ ...prev, submit: true }));
    let responseStatus = "";
    const { title, columnX, columnY, resource } = values;
    let auxDataChart = {
      name: title,
      type: detailChart.type,
      columnName: {
        xAxis: columnX,
        yAxis: columnY,
      },
      fileId: resource,
      id: idSelectedChart,
    };
    if (detailChart.type === "pie") {
      let responseStatusY = "";
      if (columnX && columnY && resource) {
        const auxRequests = [
          analysis.getColumnData(resource, detailChart.type, columnX),
          analysis.getColumnData(resource, detailChart.type, columnY),
        ];
        const columnsXY = [];
        await axios.all(auxRequests).then((responses) => {
          responses.forEach((resp) => columnsXY.push(resp.data));
          responseStatus = responses[0].request.status;
          responseStatusY = responses[1].request.status;
        });
        const valuesX = columnsXY[0]?.data;
        const valuesY = columnsXY[1]?.data;

        auxDataChart = {
          ...auxDataChart,
          columnName: { ...auxDataChart.columnName, label: columnY },
          data: { x: valuesX, y: valuesY },
        };
      }
      if (responseStatus !== 200 || responseStatusY !== 200) {
        setMessage({
          type: "error",
          title: "Error",
          description: "No hay datos de estas columnas",
        });
      }
    }
    if (detailChart.type === "bar") {
      if (columnX && columnY && resource) {
        const auxRequests = [
          analysis.getColumnData(resource, detailChart.type, columnX, columnY),
        ];
        const columnsXY = [];
        await axios.all(auxRequests).then((responses) => {
          responses.forEach((resp) => columnsXY.push(resp.data));
          responseStatus = responses[0].request.status;
        });
        const columnInfo = columnsXY[0];
        const auxColumnData = columnInfo?.data;

        auxDataChart = {
          ...auxDataChart,
          columnName: { ...auxDataChart.columnName, label: columnY },
          data: auxColumnData,
        };
      }
      if (responseStatus !== 200) {
        setMessage({
          type: "error",
          title: "Error",
          description: "No hay datos de estas columnas",
        });
      }
    }
    if (detailChart.type === "stackedBar" || detailChart.type === "scatter") {
      // const { aggregation, columnLabels } = values;
      let agg = values.aggregation || "";
      const widthData = values.widthData || "";
      if (columnY === "none") agg = "count";

      const labels = values?.columnLabels;
      const auxRequests = [
        analysis.getColumnData(
          resource,
          detailChart.type,
          columnX,
          columnY,
          labels,
          agg,
          widthData
        ),
      ];
      const columnsXY = [];
      await axios.all(auxRequests).then((responses) => {
        responses.forEach((resp) => columnsXY.push(resp.data));
        responseStatus = responses[0].request.status;
      });
      const columnInfo = columnsXY[0];
      const auxColumnData = columnInfo?.data;
      if (detailChart.type === "stackedBar") {
        auxDataChart = {
          ...auxDataChart,
          columnName: {
            ...auxDataChart.columnName,
            label: labels,
            aggregation: agg,
          },
          data: auxColumnData,
        };
      }
      if (detailChart.type === "scatter") {
        auxDataChart = {
          ...auxDataChart,
          columnName: {
            ...auxDataChart.columnName,
            label: labels,
            values: widthData,
          },
          data: auxColumnData,
        };
      }
      if (responseStatus !== 200) {
        setMessage({
          type: "error",
          title: "Error",
          description: "No hay datos de esta columna",
        });
      }
    }

    if (detailChart.type === "line") {
      const auxRequests = [
        analysis.getColumnData(resource, "line", columnX, columnY),
      ];
      const columnsXY = [];
      await axios.all(auxRequests).then((responses) => {
        responses.forEach((resp) => columnsXY.push(resp.data));
        responseStatus = responses[0].request.status;
      });
      const columnInfo = columnsXY[0]?.data;
      const valuesX = columnInfo?.x;
      const valuesY = columnInfo?.y;

      // const auxColumnName = columnInfo?.name;
      auxDataChart = {
        ...auxDataChart,
        columnName: { ...auxDataChart.columnName, yAxis: columnY },
        data: { x: valuesX, y: valuesY },
      };
      if (responseStatus !== 200) {
        setMessage({
          type: "error",
          title: "Error",
          description:
            "La fuente seleccionada no tiene datos de tiempo para mostrar en este gráfico de linea",
        });
      }
    }
    const auxArray = [...dataCharts];
    const chartSelected = auxArray.map((item) =>
      item.id === idSelectedChart ? auxDataChart : item
    );
    if (responseStatus === 401) {
      window.location.reload();
    }
    if (responseStatus === 200) {
      insertLineBreak();
      setDataCharts(chartSelected);
      setShowModal(false);
    }
    setLoading((prev) => ({ ...prev, submit: false }));
  };

  const renderModal = () => {
    if (showModal) {
      return (
        <AppModal
          open={showModal}
          onCancel={closeModal}
          footer={false}
          className=" text-lightBlue2 text-lg text-center w-full lg:w-1/2"
        >
          <>
            <strong className="text-lg">
              {action === "edit"
                ? "Editar datos de la gráfica"
                : "Crear nueva gráfica"}
            </strong>
            {action === "edit" ? (
              <ChartForm
                detailChart={detailChart}
                listColumnsXY={listColumnsXY}
                listFiles={listFiles}
                loading={loading}
                handleSubmit={handleSubmit}
                onChangeCol={onChangeCol}
                isOpenModal={showModal}
                listAggregations={listAggregations}
              />
            ) : (
              <ChartCreateForm
                listCharts={listCharts}
                handleSubmit={handleCreateChart}
              />
            )}
          </>
        </AppModal>
      );
    }
    return null;
  };

  return !loading.content ? (
    <div>
      <PreviewInfoTemplate data={dataResearch} onchangeState={onPublished} />
      {/* Quill Editor */}
      <div ref={quillRef} className="bg-white" />
      <div className="flex justify-end items-end">
        <Button type="primary" onClick={handleSave} className="mt-2 ">
          Guardar contenido
        </Button>
      </div>
      {/* Preview Section */}
      <div className="mt-4 bg-white_custom">
        <h3 className="text-colorBorder">Vista previa:</h3>
        <PlotlyContentRenderer
          content={content}
          dataCharts={dataCharts}
          listImg={dataImg}
          handleEditChart={handleEditChart}
          classRender="bg-white"
        />
      </div>
      {renderModal()}
    </div>
  ) : (
    <div className="flex h-96 justify-center items-center">
      <Spin />
    </div>
  );
};

export default InvestigationQuillEditor;
