import React from "react";
import { Form} from "antd";
import AppInput from "../../atoms/Input/AppInput";
// import "../../../index.css";
/* eslint-disable react/jsx-props-no-spreading */

const FormInput = ({ name, label, placeholder, styleInput = "", className = "",rules, labelCol="", ...props }) =>{
  const combinedClassName = `font-Poppins font-light text-sizeBase text-black_custom  ${className} !important`;
  return (
    <Form.Item
      labelCol={labelCol}
      colon={false}
      name={name}
      label=  {label? <span className="font-Poppins font-light text-sizeBase text-color_text">{label}</span>: null}
      rules={rules}
      className={combinedClassName}
      {...props}
    > 
      <AppInput
        placeholder={placeholder}
        className={`text-black_custom ${styleInput}`}
        
      />
    </Form.Item>
  )
};

export default FormInput;
