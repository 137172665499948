// Definiciones de estilos para diferentes componentes
const selectBlueStyle = {
  "selectorBg": "rgb(138, 205, 208)",
  "colorBgContainer": "rgb(138, 205, 208)",
  "colorBgElevated": "rgb(138, 205, 208)",
  "optionSelectedColor": "rgba(105, 72, 72, 0.88)",
  "colorText": "rgb(240, 240, 240)",
  "colorTextPlaceholder": "rgb(240, 240, 240)",
  "optionFontSize": 16,
  "fontSize": 16,
  "optionSelectedBg": "rgb(230, 243, 255)",
  "colorIcon": "rgb(240, 240, 240)",
  "colorTextQuaternary": "rgb(240, 240, 240)",
  "fontSizeLG": 16,
  "borderRadius": 9999,
  "optionHeight": 38,
  "controlHeight": 38
};
const selectWhiteStyle = {
  "optionSelectedColor": "rgb(44, 51, 51)",
  "optionSelectedBg": "rgb(76, 172, 188)",
  "colorText": "rgb(44, 51, 51)",
  "colorBgElevated": "rgb(255, 255, 255)",
  // "colorTextPlaceholder": "rgb(44, 51, 51)",
  "colorTextQuaternary": "rgba(44, 51, 51)",
  "optionFontSize": 16,
  "fontSize": 16,
  "fontSizeIcon": 14,
  "borderRadius": 9999
};
const inputStyle = {
  "colorText": "rgb(44, 51, 51)",
  "colorPrimaryHover": "rgb(76, 172, 188)",

};
const inputLoginStyle = {
  "borderRadius": 9999,
  "fontSizeIcon": 27,
  "fontSize": 16,
  "colorBgContainer": "rgb(138, 205, 208)"
};

const inputPassLoginStyle = {
  "borderRadius": 9999,
  "fontSizeIcon": 27,
  "fontSize": 16,
  "colorBgContainer": "rgb(138, 205, 208)",
  "colorTextQuaternary": "rgb(255, 255, 255)"
};

const inputPhoneStyle = {
  "borderRadius": 9999,
  "activeBorderColor": "rgb(138, 205, 208)",
  "handleHoverColor": "rgb(138, 205, 208)",
  "colorPrimaryHover": "rgb(138, 205, 208)",
  "colorText": "rgb(44, 51, 51)",
  "inputFontSize": 16,
  "inputFontSizeLG": 16,
  "fontSize": 16,
  "fontSizeLG": 16,
  "borderRadiusLG": 9999,

};

const tableStyle = {
  "fontFamily": "Poppins, sans-serif",
  "fontWeightStrong": 400,
  "headerColor": "rgb(76, 172, 188)",
  "headerSplitColor": "rgb(76, 172, 188)",
  "colorBgContainer": "rgb(240, 240, 240)",
  "colorText": "rgb(44, 51, 51)",
  "fontSize": 16,
  "headerBg": "rgb(240, 240, 240)",
  "borderColor": "rgba(0, 0, 0, 0)",
  "lineWidth": 0,
  "marginXXS": 5,
  "padding": 14,
  "paddingXXS": 5,
  "margin": 14,
  "headerBorderRadius": 9,
  "cellPaddingInline": 14,
  "cellPaddingBlock": 14,
  "selectionColumnWidth": 33,
  "rowHoverBg": "rgb(186, 224, 255)",
  "colorSplit": "rgb(5, 5, 5)",
  "borderRadius": 9,
};

const modalStyle = {
  "contentBg": "rgb(240, 240, 240)",
  // "titleColor": "rgb(44, 51, 51)",
  "titleColor": "rgb(76, 172, 188)",
  "titleFontSize": 24,
  "fontSize": 16,
  "borderRadiusLG": 45,
  "fontWeightStrong": 700,
  "padding": 21,
  "headerBg": "rgb(240, 240, 240)"
};

const menuStyle = {

  "colorText": "rgb(127, 131, 131)",
  "groupTitleColor": "rgb(127, 131, 131)",
  "popupBg": "rgb(43, 52, 51)"
};
// Puedes exportar los estilos individualmente o como un objeto
export {
  selectBlueStyle,
  selectWhiteStyle,
  inputStyle,
  inputLoginStyle,
  inputPassLoginStyle,
  inputPhoneStyle,
  tableStyle,
  modalStyle,
  menuStyle
};