import React, { useState } from "react";
import { Row, Avatar, Dropdown, Space, Col, Badge } from "antd";
import { BellFilled } from "@ant-design/icons";
// import "../../../index.css";
import AppText from "../../atoms/Text/AppText";
import ROUTES from "../../../constant/router";
import getInitials from "../../../helpers/getInitials";
import ButtonLink from "../../atoms/Link/ButtonLink";
import NotificationsDrawer from "../Notification/NotificationDrawer";

const DropdownAvatar = ({
  handleMenuClick,
  infoUser,
  className,
  notifications,
  isNotification,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  // const notifications = [
  //   {
  //     category: "Determinantes sociales",
  //     date: "18 de octubre de 2024",
  //     userName: "Nombre de usuario",
  //     read: false,
  //   },
  //   {
  //     category: "Salud pública",
  //     date: "17 de octubre de 2024",
  //     userName: "Otro usuario",
  //     read: true,
  //   },
  //   // Agrega más notificaciones de ejemplo aquí
  // ];

  const items = [
    ...(["Administrador", "Super Administrador"].includes(infoUser.role)
      ? [
        {
          label: isNotification ? (
            <Badge dot>
              <button
                className="font-Poppins"
                onClick={showDrawer}
                type="button"
              >
                Notificaciones
              </button>
            </Badge>
          ) : (
            <button
              className="font-Poppins"
              onClick={showDrawer}
              type="button"
            >
              Notificaciones
            </button>
          ),
          key: "1",
        },
        { type: "divider" },
      ]
      : []),
    {
      label: (
        <ButtonLink
          href={ROUTES.GENERAL.USER_EDIT}
          title="Ver mi información"
        />
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: "Cerrar Sesión",
      key: "logout",
    },
  ];

  // const handleMenuClick = (e) => {
  //   console.log("click", e);
  //   if (e.key === "logout") {
  //     logoutUser()
  //   }
  // };

  const initials = getInitials(infoUser.firstName, infoUser.lastName);

  return (
    <div className="grid justify-items-end min-w-[262px]">
      {/* <NotificationUpdater setNotifications={setNotifications} /> */}
      <Dropdown
        menu={{
          items,
          onClick: handleMenuClick,
        }}
        trigger={["click"]}
      >
        {/* <button type="button" onClick={(e) => e.preventDefault()} className="shadow-xl h-auto  rounded-xl w-48 inline-block text-sm px-4 py-2 leading-none  text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0 "> */}
        <Row className={className}>
          <Space className="gap-3">
            <Col>
              <Space direction="vertical" className="gap-0 text-right">
                <AppText
                  title={`${infoUser.firstName} ${infoUser.lastName}`}
                  type="secondary"
                  className="text-lightBlue font-bold uppercase text-lg"
                />
                <AppText
                  title={infoUser.role}
                  type="secondary"
                  className="italic text-gray_custom  text-base"
                  style={{
                    marginTop: 0,
                  }}
                />
              </Space>
            </Col>
            <Col>
              {isNotification ? (
                <Badge dot
                  offset={[-3, 39]}
                >
                  <Badge                   
                    count={
                      <BellFilled
                        style={{ color: "#F0F0F0", fontSize: "18px" }}
                      />
                    }
                    offset={[-10, 50]}
                    size="small"
                    style={{
                      backgroundColor: "#4CACBC",
                      border: "2px",
                      color: "#f56a00",
                      borderRadius: "50%",
                      width: "24px",
                      height: "24px",
                      display:"flex",
                      alignItems:"center",
                      justifyContent:"center"
                    }}
                  >
                    <Avatar
                      style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}
                      size={60}
                    >
                      {initials}
                    </Avatar>
                  </Badge>
                </Badge>
              ) : (
                <Avatar
                  style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}
                  size={60}
                >
                  {initials}
                </Avatar>
              )}
              {/* <Avatar
                style={{
                  backgroundColor: "#FFFFFF", 	
                  verticalAlign: "middle",
                }}
                size={60}
                icon={<UserOutlined  style={{ color: "#08c" }}/>}
              /> */}
            </Col>
          </Space>
        </Row>
        {/* </button> */}
      </Dropdown>
      <NotificationsDrawer
        visible={drawerVisible}
        onClose={closeDrawer}
        notifications={notifications?.items ? notifications.items : []}
      />
    </div>
  );
};

export default DropdownAvatar;
