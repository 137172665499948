/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
const processColumns = (columns) => {
  const processedColumns = [];
  const columnMap = {};

  columns.forEach((col) => {
    const parts = col.name.split("_||_").map(part => part.trim());
    let currentLevel = processedColumns;
    let currentMap = columnMap;

    parts.forEach((part, index) => {
      if (!currentMap[part]) {
        const newColumn = {
          title: part,
          children: [],
        };

        // Only set dataIndex and key on the final part
        if (index === parts.length - 1) {
          newColumn.dataIndex = col.name;
          newColumn.key = col.name;
        }

        currentMap[part] = newColumn;
        currentLevel.push(newColumn);
      }

      currentLevel = currentMap[part].children;
      currentMap = currentMap[part];
    });
  });

  // Remove empty children arrays
  const removeEmptyChildren = (columns) => {
    columns.forEach(col => {
      if (col.children.length === 0) {
        delete col.children;
      } else {
        removeEmptyChildren(col.children);
      }
    });
  };

  removeEmptyChildren(processedColumns);

  return processedColumns;
};

export default processColumns;
