import React from "react";
import { ConfigProvider } from "antd";
import { selectWhiteStyle } from "../../../assets/theme/themeConfig";
import FormTextArea from "../../molecules/FormTextArea/FormTextArea";
import FormSelectSearch from "../../molecules/FormSelect/FormSelectSearch";

// eslint-disable-next-line arrow-body-style
const ColumnForm = ({
  listTypeData,
  styleArea = "h-52",
  dataTypeRequire = true,
  multipleEdit = false,
}) => (
  <ConfigProvider
    theme={{
      components: {
        Select: selectWhiteStyle,
      },
    }}
  >
    <div>
      <div className=" flex flex-col">
        <div>
          <FormSelectSearch
            label="Tipo Dato :"
            name="dataType"
            className="font-semibold text-color_text text-textTable"
            // selectClass="formBgWhite "
            options={listTypeData}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: dataTypeRequire,
                message: "Por favor seleccione un tipo de dato",
              },
            ]}
          />
        </div>
      </div>
      {!multipleEdit ? (
        <div>
          <FormTextArea
            name="description"
            className="text-color_text "
            label="Descripción :"
            labelCol={{ span: 24 }}
            classText={styleArea}
          />
        </div>
      ) : null}
    </div>
  </ConfigProvider>
);

export default ColumnForm;
