import { useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import LoginForm from "../../organisms/LoginForm/LoginForm";
import AppImg from "../../atoms/Img/AppImg";
import logoAune from "../../../assets/image/Logo AUNE.png";
import AppTitle from "../../atoms/Title/AppTitle";
import AppModal from "../../molecules/Modal/Modal";
import ForgotPassForm from "../../organisms/ForgotPassForm/ForgotPassForm";
import RecoveryForm from "../../organisms/LoginForm/RecoveryForm";
import AppText from "../../atoms/Text/AppText";

export default function LoginTemplate({
  loading,
  onSubmitForm,
  isRecovery = false,
  onSendEmail,
  handleBackButton,
}) {
  const [modalActive, setModalActive] = useState(false);

  const navigate = useNavigate();

  const closeModal = () => {
    setModalActive(false);
  };

  const openModal = () => setModalActive(true);

  const handlerForgotPass = (data) => {
    onSendEmail(data);
    closeModal();
  };

  const onRegister = () => {
    navigate("/register");
  };

  return (
    <section className="bg-white_custom dark:bg-gray-900">
      <div className="flex flex-col items-center px-6 mt-[-80px]">
        <a
          href="/"
          className="flex items-center mb-4 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <AppImg src={logoAune} alt="Logo" className=" mx-auto w-60 " />
        </a>
        <div className="w-full bg-white_custom rounded-[54px] border-colorBorder/25 shadow-2xl dark:border  md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className=" py-2 px-4 space-y-4 md:space-y-6 sm:p-8  ">
            <AppTitle
              title={!isRecovery ? "Inicio de Sesión" : "Cambiar contraseña"}
              level={3}
              className="text-black_custom font-bold text-3xl leading-tight text-center !important"
            />
            {!isRecovery ? (
              <LoginForm
                loading={loading}
                onSubmitForm={onSubmitForm}
                handlerForgotPass={openModal}
              />
            ) : (
              <RecoveryForm
                loading={loading}
                onSubmitForm={onSubmitForm}
                handleBackButton={handleBackButton}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col justify-center items-center  mt-4 py-6">
          <AppText
            title="¿No se encuentra registrado?"
            type="secondary"
            className="mr-2 text-black_custom text-center font-medium"
          />
          <Button
            onClick={onRegister}
            className="  bg-dark_gray font-Poppins shadow-lg h-[40px] rounded-full min-w-28 text-white_custom font-normal text-lg text-center mt-4 px-8  hover:bg-darkAquamarineBlue hover:shadow-xl"
          >
            Registrarse
          </Button>
        </div>

        <AppModal
          title="Recuperar Contraseña"
          open={modalActive}
          onCancel={closeModal}
          width={500}
          footer={false}
        >
          <ForgotPassForm handleForgot={handlerForgotPass} />
        </AppModal>
      </div>
    </section>
  );
}
