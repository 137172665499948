import React from "react";
import { Tabs } from "antd";
import IndicatorDetailColumn from "../../components/organisms/IndicatorForm/IndicatorDetailColumn";
// import IndicatorDetailFormula from "../../components/organisms/IndicatorForm/IndicatorDetailFormula";

const TabsIndicator = ({
  detailIndicator,
  titleIndicator,
  returnStateCalculation,
  returnValueFormula,
}) => {
  const items = [
    {
      key: "1",
      label: "Asociar indicador a columna",
      children: (
        <div className="shadow-body-indicator ml-[4px] relative top-[-1px] p-5 bg-[#FFFFFF] ">
          <IndicatorDetailColumn
            detailIndicator={detailIndicator}
            titleIndicator={titleIndicator}
            returnStateCalculation={returnStateCalculation}
            returnValueFormula={returnValueFormula}
          />
        </div>
      ),
    },
    /*     {
      key: "2",
      label: "Asociar indicador a formula",
      children: (
        <div className="shadow-body ml-[3px] relative top-[-1px] p-5 bg-[#FFFFFF]">
          <IndicatorDetailFormula
            detailIndicator={detailIndicator}
            titleIndicator={titleIndicator}
            returnStateCalculation={returnStateCalculation}
            returnValueFormula={returnValueFormula}
          />
        </div>
      ),
    }, */
  ];

  return (
    <div className="flex flex-col">
      <Tabs className="shadow-indicator" type="card" defaultActiveKey="1" items={items} />
    </div>
  );
};

export default TabsIndicator;
