import React from "react";
import { Form, Select, ConfigProvider } from "antd";
import { selectWhiteStyle } from "../../../assets/theme/themeConfig";

/* eslint-disable react/jsx-props-no-spreading */

const FormSelectSearch = ({
  className = "",
  name,
  label,
  rules,
  poppupclassName,
  selectClass,
  placeholder,
  options,
  onChange,
  loading,
  ...props
}) => {
  const combinedClassName = `font-Poppins text-black_custom  ${className} !important`;
  const combinedClassSelect = `font-Poppins  rounded-full    text-textCustom  ${selectClass} !important`;
  const combinedClassPoppup = `font-Poppins text-textCustom  ${poppupclassName} !important`;
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: selectWhiteStyle,
        },
      }}
    >
      <Form.Item
        name={name}
        colon={false}
        label={
          label ? (
            <span className="font-Poppins font-light  text-color_text text-center ">
              {label}
            </span>
          ) : null
        }
        rules={rules}
        {...props}
        className={combinedClassName}
      >
        <Select
          showSearch
          options={options}
          placeholder={placeholder}
          className={combinedClassSelect}
          popupClassName={combinedClassPoppup}
          optionFilterProp="children"
          filterOption={filterOption}
          onChange={onChange}
          loading={loading}
        />
      </Form.Item>
    </ConfigProvider>
  );
};

export default FormSelectSearch;
