const transformUTCtoColombiaTime = (time) => {
  // Input UTC time string
  const utcTimeString = time;
  // Parse it as a UTC date
  const utcDate = new Date(utcTimeString);

  // Subtract 5 hours (Colombia Time is UTC-5)
  const colombiaOffset = -5 * 60; // Offset in minutes
  const colombiaTime = new Date(
    utcDate.getTime() + colombiaOffset * 60 * 1000
  ).toISOString();
  return colombiaTime;
};

const timeWithFormat = (time) => {
  if (!time) return "";
  const lengthTime = time.length;
  let auxTime = time;
  const lastCharacter = time.charAt(lengthTime - 1);
  if (lastCharacter.toLowerCase() !== "z") auxTime = `${time}Z`;
  return transformUTCtoColombiaTime(auxTime).replace("T", " ").split(".")[0];
};

const localeTime = (time) => {
  if (!time) return "";
  const lengthTime = time.length;
  let auxTime = time;
  const lastCharacter = time.charAt(lengthTime - 1);
  if (lastCharacter.toLowerCase() !== "z") auxTime = `${time}Z`;
  const auxOff = new Date(auxTime);
  return auxOff.toLocaleString("es-CO");
};

export { transformUTCtoColombiaTime, timeWithFormat, localeTime };
