const findElementInArray = (arr = [], q = "") => {
  if (Array.isArray(arr)) {
    const found = arr.findIndex((val) => {
      if (val.includes(".")) {
        const concatVal = val.split(".").join("");
        let valueToFind = q;
        if (valueToFind.includes("."))
          valueToFind = valueToFind.split(".").join("");
        return concatVal.toLowerCase().includes(valueToFind.toLowerCase());
      }
      return val.toLowerCase().includes(q.toLowerCase());
    });
    if (found === -1) {
      return false;
    }
    return true;
  }
  return null;
};

export default findElementInArray;
