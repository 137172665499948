/* eslint-disable no-underscore-dangle */
const findMaxDepth = (groups, depth = 0) => {
  if (!groups || groups.length === 0) return depth;
  return Math.max(
    ...groups.map((group) => findMaxDepth(group.childGroups, depth + 1))
  );
};

const addLevels = (groups, level = 0) => {
  if (!groups || groups.length === 0) return [];

  groups.forEach((group) => {
    const updatedGroup = { ...group, level }; // Create a new object with the level property
    addLevels(updatedGroup.childGroups, level + 1); // Recursively add levels to child groups
    Object.assign(group, updatedGroup); // Update the original group object
  });
  return groups;
};

function addLevelsAndParents(groups, level = 0, parentsArray = []) {
  if (!groups || groups.length === 0) return [];

  groups.forEach((group) => {
    const newParentsArray = [...parentsArray, group?._id];
    const updatedGroup = { ...group, level, parentsArray: newParentsArray };
    addLevelsAndParents(updatedGroup.childGroups, level + 1, newParentsArray);
    Object.assign(group, updatedGroup);
  });
  return groups;
}

const addPropsToTree = (groups, level = 0) => {
  if (!groups || groups.length === 0) return [];

  return groups.map((group) => {
    const updatedGroup = {
      ...group,
      level,
      value: group.name,
      key: group._id,
      title: group.name,
    };

    if (level === 0) {
      Object.assign(updatedGroup, {
        checkable: false,
        selectable: false,
      });
    }

    if (updatedGroup.childGroups.length === 0) {
      Object.assign(updatedGroup, { isLeaf: true });
    }

    updatedGroup.children = addPropsToTree(updatedGroup.childGroups, level + 1);

    delete updatedGroup._id;
    delete updatedGroup.name;
    delete updatedGroup.childGroups;

    return updatedGroup; 
  });
};

export { findMaxDepth, addLevels, addLevelsAndParents, addPropsToTree };
