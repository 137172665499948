import React from "react";
import { TreeSelect } from "antd";
/* eslint-disable react/jsx-props-no-spreading */

const { SHOW_PARENT } = TreeSelect;

function AppTreeSelect({
  className = "",
  treeData,
  treeCheckable = true,
  showCheckedStrategy = true,
  maxTagCount,
  placeholder,
  ...props
}) {
  const combinedClassName = `font-Poppins font-light  ${className} `;
  return (
    <TreeSelect
      {...props}
      treeData={treeData}
      treeCheckable={treeCheckable}
      showCheckedStrategy={showCheckedStrategy ? SHOW_PARENT : false}
      placeholder={placeholder}
      style={{
        width: "100%",
      }}
      allowClear
      maxTagCount={maxTagCount}
      className={combinedClassName}
    />
  );
}
export default AppTreeSelect;
