import { Layout } from "antd";
import { useState } from "react";
import HomeTemplate from "../../components/templates/HomeTemplate/HomeTemplate";

const { Content } = Layout;

const Home = () => {

  const [zones, setZones] = useState([])
  const [events, setEvents] = useState([])

  const setSelectedZones = (event, item) => {

    if (event === "select") {
      setZones([...zones, item])
    } else {
      const newZones = zones.filter(zone => zone !== item);
      setZones(newZones)
    }
  }

  const setSelectedEvent = (event, item) => {
    if (event === "select") {
      setEvents([...events, item])
    } else {
      const newEvents = events.filter(ev => ev !== item);
      setEvents(newEvents)
    }
  }

  return (
    <Layout className="bg-white_custom">
      <Content >
        <HomeTemplate 
          handleItemZone={setSelectedZones}
          handleItemEvent={setSelectedEvent}
          zonesSelected={zones}
          eventsSelected={events}/>
      </Content>
    </Layout>
  )
  // <div className="bg-white">
  //   <div className="relative isolate px-6 pt-14 lg:px-8">
  //     <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
  //       <div className="hidden sm:mb-8 sm:flex sm:justify-center">
  //         <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20" />
  //       </div>
  //       <div className="text-center">
          
  //         <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
  //           Data to enrich your online business
  //         </h1>
  //         <p className="mt-6 text-lg leading-8 text-gray-600">
  //           Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui
  //           lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat
  //           fugiat aliqua.
  //         </p>
  //         <div className="mt-10 flex items-center justify-center gap-x-6" />
  //       </div>
  //     </div>
  //     <div
  //       className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
  //       aria-hidden="true"
  //     >
  //       <div className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" />
  //     </div>
  //   </div>
  // </div>
}

export default Home;
