import { useEffect, useState } from "react";
import { Divider, Form } from "antd";
import FormInput from "../../molecules/FormInput/FormInput";
import FormSelectSearch from "../../molecules/FormSelect/FormSelectSearch";
import SubmitButtonNotValidate from "../../atoms/Buttons/SubmitButtonNotValidate";

const ChartForm = ({
  handleSubmit,
  listFiles,
  detailChart,
  loading,
  listColumnsXY,
  onChangeCol,
  isOpenModal,
  listAggregations,
}) => {
  const [form] = Form.useForm();
  const [valuesColumn, setValuesColumn] = useState("none");

  const onChangeSource = (val, key) => {
    form.resetFields(["columnX", "columnY", "aggregation", "columnLabels"]);
    onChangeCol(val, key);
  };

  useEffect(() => {
    if (detailChart) {
      form.setFieldValue("title", detailChart.name);
    }
    if (isOpenModal) {
      form.resetFields([
        "resource",
        "columnX",
        "columnY",
        "columnLabels",
        "aggregation",
      ]);
    }
  }, [detailChart, isOpenModal]);

  useEffect(() => {
    if (listFiles && listFiles.length > 0) {
      if (detailChart.fileId) {
        onChangeSource(detailChart.fileId);
        form.setFieldValue("resource", detailChart.fileId);
      }
    }
  }, [listFiles]);

  useEffect(() => {
    if (!loading.columns) {
      const auxAggregation = detailChart.columnName?.aggregation;
      const auxLabel = detailChart.columnName?.label;
      const auxAxisY = detailChart.columnName?.yAxis;
      const auxValues = detailChart.columnName?.values;
      if (auxAxisY !== "none" && auxAggregation) {
        setValuesColumn(true);
      }
      if (detailChart.fileId === form.getFieldValue("resource"))
        form.setFieldsValue({
          columnX: detailChart.columnName?.xAxis,
          columnY: auxAxisY,
          columnLabels: auxLabel,
          aggregation: auxAggregation,
          widthData: auxValues
        });
    }
  }, [loading.columns]);

  const returnTooltip = (type, key) => {
    if (type === "line") {
      if (key === "colX")
        return "En este eje se deben seleccionar columnas de tipo numérico o de fecha";
      return "En este eje solo se deben seleccionar columnas de tipo numérico";
    }
    if (type === "bar") {
      if (key === "colX")
        return "En este eje se deben seleccionar columnas de tipo numérico o de tipo texto";
      return "En este eje solo se deben seleccionar columnas de tipo numérico";
    }
    if (type === "pie") {
      if (key === "colX")
        return "Solo se deben seleccionar columnas de tipo texto";
      return "Solo se deben seleccionar columnas de tipo numérico";
    }
    if (type === "stackedBar") {
      if (key === "aggregation")
        return "Seleccione la operación que se va a hacer para los datos cuantitativos del Eje Y";
      if (key === "colY")
        return "Solo se deben seleccionar columnas de tipo numérico";
      return "Solo se deben seleccionar columnas de tipo texto";
    }
    if (type === "scatter") {
      if (key === "colX")
        return "En este eje se deben seleccionar columnas de tipo numérico o de tipo texto";
      if (key === "colY")
        return "Solo se deben seleccionar columnas de tipo numérico";
      if (key === "widthData")
        return "Solo se deben seleccionar columnas de tipo numérico";
      return "Solo se deben seleccionar columnas de tipo texto (Seleccionar esta columna es opcional, si no se selecciona no habrán grupos de colores)";
    }
    return "";
  };

  const renderFormTypeChart = () => {
    if (
      detailChart.type === "line" ||
      detailChart.type === "pie" ||
      detailChart.type === "bar" ||
      detailChart.type === "map"
    ) {
      return (
        <div className="flex flex-col justify-center">
          <FormSelectSearch
            name="resource"
            label="Seleccione la fuente de la información"
            className=" text-color_text mt-5"
            labelCol={{ span: 24 }}
            options={listFiles}
            onChange={onChangeSource}
          />
          <FormSelectSearch
            name="columnX"
            label={
              detailChart.type === "pie"
                ? "Seleccione la columna para las etiquetas"
                : "Seleccione la columna para el eje X"
            }
            className=" text-color_text"
            labelCol={{ span: 24 }}
            options={listColumnsXY}
            loading={loading.columns}
            tooltip={() => returnTooltip(detailChart.type, "colX")}
          />
          <FormSelectSearch
            name="columnY"
            label={
              detailChart.type === "pie"
                ? "Seleccione la columna para los valores"
                : "Seleccione la columna para el eje Y"
            }
            className=" text-color_text"
            labelCol={{ span: 24 }}
            options={listColumnsXY}
            loading={loading.columns}
            tooltip={() => returnTooltip(detailChart.type, "colY")}
          />
        </div>
      );
    }
    if (detailChart.type === "stackedBar" || detailChart.type === "scatter") {
      return (
        <div className="flex flex-col justify-center">
          <FormSelectSearch
            name="resource"
            label="Seleccione la fuente de la información"
            className=" text-color_text mt-5"
            labelCol={{ span: 24 }}
            options={listFiles}
            onChange={onChangeSource}
          />
          <FormSelectSearch
            name="columnX"
            label="Seleccione la columna para el eje X"
            className=" text-color_text"
            labelCol={{ span: 24 }}
            options={listColumnsXY}
            loading={loading.columns}
            tooltip={() => returnTooltip(detailChart.type, "colX")}
          />
          <FormSelectSearch
            name="columnY"
            label="Seleccione la columna para el eje Y"
            className=" text-color_text"
            labelCol={{ span: 24 }}
            options={ detailChart.type === "stackedBar" ? [ { value: "none", label: "NINGUNA (se hará un conteo de las etiquetas)", }, ...listColumnsXY, ] : listColumnsXY }
            onChange={setValuesColumn}
            loading={loading.columns}
            tooltip={() => returnTooltip(detailChart.type, "colY")}
          />
          <FormSelectSearch
            name="columnLabels"
            label={`Seleccione la columna de etiqueta ${detailChart.type === "scatter" ? "(Opcional)" : ""}`}
            className=" text-color_text"
            labelCol={{ span: 24 }}
            options={
              detailChart.type === "scatter"
                ? [{ value: "none", label: "Ninguna" }, ...listColumnsXY]
                : listColumnsXY
            }
            loading={loading.columns}
            tooltip={() => returnTooltip(detailChart.type, "tag")}
          />
          {detailChart.type === "scatter" ? (
            <FormSelectSearch
              name="widthData"
              label="Seleccione la columna para la configuración del tamaño de las etiquetas (Opcional)"
              className=" text-color_text"
              labelCol={{ span: 24 }}
              options={[{ value: "none", label: "Ninguna" }, ...listColumnsXY]}
              loading={loading.columns}
              tooltip={() => returnTooltip(detailChart.type, "widthData")}
            />
          ) : null}
          {detailChart.type === "stackedBar" && valuesColumn !== "none" ? (
            <FormSelectSearch
              name="aggregation"
              label="Selecciona la función de agregación"
              className=" text-color_text"
              labelCol={{ span: 24 }}
              options={listAggregations}
              tooltip={() => returnTooltip(detailChart.type, "aggregation")}
            />
          ) : null}
        </div>
      );
    }

    return null;
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <FormInput
        name="title"
        label="Título de la gráfica:"
        labelCol={{ span: 24 }}
        styleInput="text-center"
      />
      <Divider />
      <strong className="text-colorBorder">Variables de la gráfica</strong>
      {renderFormTypeChart()}
      <Form.Item>
        <div className="flex justify-center text-center">
          <SubmitButtonNotValidate
            nameButton="Guardar"
            form={form}
            loading={loading.submit}
            htmlType="submit"
          />
        </div>
      </Form.Item>
    </Form>
  );
};

export default ChartForm;
