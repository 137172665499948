import { Avatar } from "antd";
import React from "react";
import AppText from "../../atoms/Text/AppText";

const ItemMenuAvatar = ({infoUser}) => (
  <div className="flex h-full items-center">
    <Avatar style={{ backgroundColor: "#fde3cf", color: "#f56a00" }} size={60}>
      LD
    </Avatar>
    <div className="flex flex-col justify-center">
      <AppText
        title={`${infoUser.firstName} ${infoUser.lastName}`}
        type="secondary"
        className="text-lightBlue font-bold uppercase text-lg"
      />
      <AppText
        title={infoUser.role}
        type="secondary"
        className="italic text-gray_custom  text-base"
        style={{
          marginTop: 0,
        }}
      />
    </div>
  </div>
);

export default ItemMenuAvatar;
