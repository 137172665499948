const downloadFile = async (blob, name, extension) => {
  let fileName = "download";
  let auxExtension = "text/csv";
  if (fileName) {
    fileName = `${name}${extension}`;
    if (extension.includes("csv")) {
      auxExtension = "text/csv";
    } else if (extension.includes("xls")) {
      auxExtension = "application/vnd.ms-excel";
    } else if (extension.includes("pdf")) {
      auxExtension = "application/pdf";
    } else if (extension.includes("json")) {
      auxExtension = "application/json";
    } else {
      auxExtension =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }
  }
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // IE variant
    window.navigator.msSaveOrOpenBlob(
      new Blob([blob], {
        type: auxExtension,
        encoding: "UTF-8",
      }),
      fileName
    );
  } else {
    const url = window.URL.createObjectURL(
      new Blob([blob], {
        type: auxExtension,
        encoding: "UTF-8",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};

/* "application/vnd.ms-excel",
"text/csv", */

export default downloadFile;
