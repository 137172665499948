/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Form } from "antd";
// import "../../../assets/css/styleSelect.css";
import FormPassword from "../../molecules/FormInput/FormPassword";
import logoAune from "../../../assets/image/Logo AUNE.png";
import AppTitle from "../../atoms/Title/AppTitle";
import AppText from "../../atoms/Text/AppText";
import FormInput from "../../molecules/FormInput/FormInput";
import SubmitButton from "../../atoms/Buttons/SubmitButton";
import AppImg from "../../atoms/Img/AppImg";

const ChangePasswordForm = ({
  handlerPassword,
  loading,
  handleBackButton,
  haveBorder = false,
  handleResendCode,
}) => {
  const [form] = Form.useForm();

  const renderBorder = (component) => {
    if (haveBorder) {
      return (
        <section className="bg-white_custom dark:bg-gray-900">
          <div className="flex flex-col items-center px-6 mt-[-80px]">
            <a
              href="/"
              className="flex items-center mb-4 text-2xl font-semibold text-gray-900 dark:text-white"
            >
              <AppImg src={logoAune} alt="Logo" className=" mx-auto w-60 " />
            </a>
            <div className="w-full bg-white_custom rounded-[54px] border-colorBorder/25 shadow-2xl dark:border  md:mt-0 sm:max-w-md xl:p-10 dark:bg-gray-800 dark:border-gray-700">
              <AppTitle
                title="Cambiar de contraseña"
                level={3}
                className="text-lightBlue font-bold text-2xl leading-tight text-center mb-6"
              />
              {component}
            </div>
          </div>
        </section>
      );
    }
    return component;
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className="font-Poppins"
      onFinish={handlerPassword}
    >
      <div className="  flex justify-center items-center mb-2">
        <div className="  p-6 ">
          {!haveBorder ? (
            <div className=" mb-2 grid justify-items-center ">
              <AppTitle
                title="Cambiar de contraseña"
                level={2}
                className="font-bold text-lightBlue mb-6"
              />
            </div>
          ) : null}
          <div className="grid justify-center items-center mb-2">
            {renderBorder(
              <>
                <FormPassword
                  name="password"
                  label="Nueva contraseña:"
                  hasFeedback
                  labelCol={{ span: 24 }}
                />
                <FormPassword
                  name="confirm"
                  label="Confirmación de contraseña:"
                  labelCol={{ span: 24 }}
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Por favor confirme la contraseña!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "¡Las contraseñas que ingresó no coinciden!"
                          )
                        );
                      },
                    }),
                  ]}
                />
                {/*  <AppText
                  className="font-Poppins font-light text-textTable text-color_text text-justify"
                  title="Un código ha sido enviado al correo electrónico para autorizar este cambio, por favor introduzca el código :"
                /> */}

                <FormInput
                  name="code"
                  label="Código de verificación:"
                  tooltip="Un código ha sido enviado al correo electrónico para autorizar este cambio"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese el código",
                      whitespace: true,
                    },
                    {
                      pattern: /^([0-9]{6}$)/,
                      message:
                        "El código debe tener exactamente 6 dígitos y contener solo números del 0 al 9 ",
                    },
                  ]}
                />
              </>
            )}
            <Form.Item>
              <div className="flex justify-center items-center">
                <SubmitButton
                  className="font-normal text-center mt-10"
                  nameButton="Cambiar contraseña"
                  htmlType="submit"
                  form={form}
                  loading={loading}
                />
              </div>
            </Form.Item>
            <a
              href="#"
              onClick={handleBackButton}
              className="-mt-2 mb-5 underline font-semibold text-sm text-center text-lightBlue hover:text-blue-700"
            >
              Volver
            </a>
          </div>

          <div className="flex justify-center items-center">
            <AppText
              className="font-Poppins font-light text-sm text-color_text"
              title="¿No recibió el código?"
            />
          </div>
          <div className="flex justify-center items-center">
            <a
              href=""
              onClick={handleResendCode}
              className="font-light text-sm text-center text-lightBlue  hover:text-blue-700"
            >
              Enviar de nuevo
            </a>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default ChangePasswordForm;
