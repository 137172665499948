import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Spin, Avatar, Card } from "antd";
import {
  UserOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import PlotlyContentRenderer from "../../molecules/QuillPlotly/PlotlyContentRenderer";
// import investigationRequest from "../../../api/investigationRequest";
import research from "../../../api/research";
import useApi from "../../../api/useApi";
import { useAuth } from "../../../contexts/AuthContext";

const { Meta } = Card;

// const Resumen = "La violencia sistemática contra los líderes sociales en el departamento de Nariño, Colombia, ha mostrado un aumento alarmante en muertes desde la firma del acuerdo de paz en 2016. Este fenómeno, que va en contravía de los objetivos de dicho acuerdo, ha encendido alertas tanto en organizaciones nacionales como internacionales. Los datos que han servido para esta investigación provienen de una variedad de fuentes, incluyendo informes de la Defensoría del Pueblo de Colombia, datos recogidos por ONGs como Indepaz, y registros de instituciones como la Oficina de la Alta Comisionada de las Naciones Unidas para los Derechos Humanos.    Estas cifras revelan que los líderes sociales en regiones como Nariño se encuentran en una posición vulnerable, con frecuencia siendo objetivo de grupos armados ilegales y organizaciones criminales que buscan mantener el control sobre territorios estratégicos para actividades como el narcotráfico. La investigación ha identificado patrones en los ataques, señalando a menudo una escalada de amenazas seguidas de acciones violentas, lo que subraya la naturaleza premeditada y sistemática de estas agresiones.Los datos también muestran que, a pesar de los esfuerzos gubernamentales y de la comunidad internacional, la impunidad sigue siendo un grave problema. Muchos de los casos de homicidios de líderes sociales quedan sin resolver, lo que perpetúa un ciclo de violencia y temor que mina los fundamentos de la democracia y la paz. La investigación, por lo tanto, no solo busca cuantificar la tragedia sino también entender las dinámicas detrás de ella para formular recomendaciones dirigidas a la protección efectiva de estos líderes y al fortalecimiento de los mecanismos judiciales y de seguridad.";
const InvestigationPreviewTemplate = () => {
  const params = useParams(); // Extracción correcta del key
  const getResearchById = useApi(research.getResearchMetaById);
  const [loading, setLoading] = useState({
    infoDoc: false,
    description: false,
  });
  const { setMessage } = useAuth();
  const [infoInvestigation, setInvestigation] = useState();
  // const [clickedDropdown, setClickedDropdown] = useState(false);
  // const reloadData = () => {
  //   setLoading((prev) => ({ ...prev, table: true }));
  //   getDataDoc.request(params?.key, metadata.page_size, metadata.page);
  // };
  // const getInfoInvest = () => {
  //   const investigationData = investigationRequest.getInvestigationData(1);
  //   console.log("investigationData", investigationData);
  //   // getResearchById.request(params.key);

  // setInvestigation({
  //   key: "1",
  //   imgUrl:
  //     "https://www.onic.org.co/images/comunicados/internacionales/wola-brand-es.png",
  //   title: "Asalto a Líderes Sociales en Nariño",
  //   avatar: "https://api.dicebear.com/7.x/miniavs/svg?seed=1",
  //   author: "WOLA",
  //   date: "2021",
  //   description:
  //     "Violencia sistemática contra líderes sociales en Nariño con aumento de muertes desde el acuerdo de paz de 2016.",
  //   content:
  //     "Investigación sobre los asesinatos y los impactos en las comunidades.",
  // });
  // };
  useEffect(() => {
    setLoading((prev) => ({ ...prev, infoDoc: false }));
    getResearchById.request(params.key, true);
  }, [params.key]);
    

  // useEffect(() => {
  //   // setLoading((prev) => ({ ...prev, infoDoc: true }));
  //   if (dataResearch) {
  //     setLoading((prev) => ({ ...prev, infoDoc: true }));
  //   } else {
  //     setLoading((prev) => ({ ...prev, infoDoc: false }));
  //   }

  //   setInvestigation(dataResearch);
  //   // getInfoInvest();
  // }, [dataResearch]);

  useEffect(() => {
    if (getResearchById.data) {
      const auxData = getResearchById.data.data;
      const{graphs} = auxData;
      const charts = graphs.map((item) => {
        let auxDataChart = item.data;
        if (item.type === "stackedBar" || item.type === "scatter")
          auxDataChart = item.data?.arrayStacked;
        return { ...item, data: auxDataChart };
      });

      setInvestigation({ ...auxData, graphs: charts });
      setLoading((prev) => ({ ...prev, infoDoc: true }));
      getResearchById.setData();
    }
  }, [getResearchById.data]);

  useEffect(() => {
    if (getResearchById.error) {
      setLoading((prev) => ({ ...prev, infoDoc: false }));
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar obtener los datos. Vuelva a intentarlo.",
      });
      getResearchById.setData();
    }
  }, [getResearchById.error]);

  return (
    <div className="flex justify-center items-center ">
      {loading.infoDoc ? (
        <Card
          title={
            <div className="flex flex-col justify-center items-center">
              <span className="title-views my-5 ">
                {infoInvestigation.title}
              </span>
            </div>
          }
          // style={{ width: "100%" }}
          cover={
            <img
              alt="example"
              src={infoInvestigation.imageUrl}
              className="object-cover w-auto p-6  "
            />
          }
          bordered={false}
          type="inner"
          className="w-full border-none shadow-none"
          // actions={[
          //   <SettingOutlined key="setting" />,
          //   <EditOutlined key="edit" />,
          //   <EllipsisOutlined key="ellipsis" />,
          // ]}
        >
          <Meta
            // avatar={<Avatar src={infoInvestigation.avatar} />}
            avatar=<Avatar size="small" icon={<UserOutlined />} />
            title={
              <span className="text-[13px]">{infoInvestigation.author}</span>
            }
            description={
              <div className="flex items-center space-x-8 text-gray-500">
                {/* Fecha */}
                <div className="flex items-center">
                  <ClockCircleOutlined />
                  <span className="ml-2">{infoInvestigation.date}</span>
                </div>

                {/* Ubicación */}
                <div className="flex items-center">
                  <EnvironmentOutlined />
                  <span className="ml-2">
                    {infoInvestigation?.location.length > 0
                      ? infoInvestigation.location.join(", ")
                      : "Ninguno"}
                  </span>
                </div>
              </div>
            }
            className="mb-2 border-none"
          />

          <p className="text-gray-700 text-base my-6">
            {infoInvestigation.abstract}
          </p>
          <div>
            <PlotlyContentRenderer
              content={infoInvestigation.markdown}
              dataCharts={infoInvestigation.graphs}
              listImg={infoInvestigation.listImages}
              isViewInvestigation
            />
          </div>
        </Card>
      ) : (
        <Spin />
      )}
    </div>
  );
};

export default InvestigationPreviewTemplate;
