/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
// import React, { useState } from "react";
// import "../../../assets/css/stylesLogin.css";
// import "../../../index.css"
import { Form } from "antd";
import SubmitButton from "../../atoms/Buttons/SubmitButton";
import FormEmailLogin from "../../molecules/FormInput/FormEmailLogin";
import FormPassLogin from "../../molecules/FormInput/FormPassLogin";

// import AppTitle from "../../atoms/Title/AppTitle";


function LoginForm({loading, onSubmitForm, handlerForgotPass}) {

  const [form] = Form.useForm();

  return (

    <Form
      form={form}
      initialValues={{ remember: true }}
      layout="vertical"
      className="font-Poppins py-4 space-y-4 md:space-y-6  "
      scrollToFirstError
    >

      <div className="justify-items-center">
        <div className=" text-center text-base ">
          {/* // eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="font-light  text-center" style={{fontSize:"16px"}} >Correo:</label>

          <FormEmailLogin name="email" className="font-normal text-base text-center !important"
          />
        </div>
        <div className=" text-center text-base">
          <label className="font-light  text-center"  style={{fontSize:"16px"}}>Contraseña:</label>
          <FormPassLogin
            name="password"
            className="font-normal text-base text-center !important"
          />
        </div>
        <div className=" text-center">
          <a
            href="#" 
            onClick={()=>handlerForgotPass()} className="font-light text-sm text-center text-lightBlue  hover:text-blue-700">¿Olvidaste tu contraseña?</a>

        </div>
      </div>
      
      <Form.Item>
        <div className="flex justify-center text-center">
          <SubmitButton
            className="font-normal text-center"
            nameButton="Inicio de Sesión"
            loading={loading}
            onClick={() => onSubmitForm(form.getFieldsValue())}
            form={form}
          />
        </div>
      </Form.Item>
    </Form>

  );
}

export default LoginForm;
