/* eslint-disable jsx-a11y/label-has-associated-control */
// import React, { useState } from "react";
// import "../../../assets/css/stylesLogin.css";
import { useState } from "react";
import { Form } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import SubmitButton from "../../atoms/Buttons/SubmitButton";
import FormEmailLogin from "../../molecules/FormInput/FormEmailLogin";
import Config from "../../../config";

// import AppTitle from "../../atoms/Title/AppTitle";

function ForgotPasForm({ handleForgot }) {
  const [form] = Form.useForm();
  const [capVal, setCapVal] = useState(false);
  const { keyRecap } = Config;

  return (
    <Form
      form={form}
      onFinish={handleForgot}
      layout="vertical"
      className="font-Poppins py-4 space-y-4 md:space-y-6  "
      scrollToFirstError
    >
      <div className="justify-items-center">
        <div className=" text-center  ">
          {/* // eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="font-light text-sizeBase text-color_text text-center  ">
            Correo:
          </label>

          <FormEmailLogin
            name="email"
            className="font-normal text-sizeBase text-black_custom text-center !important"
          />
        </div>
        <div className="flex justify-center">
          <ReCAPTCHA sitekey={keyRecap} onChange={(val) => setCapVal(val)} />
        </div>
      </div>
      <Form.Item>
        <div className="flex justify-center text-center">
          <SubmitButton
            className="font-normal   text-center"
            nameButton="Enviar"
            htmlType="submit"
            form={form}
            doubleCheckValidation={capVal}
          />
        </div>
      </Form.Item>
    </Form>
  );
}

export default ForgotPasForm;
