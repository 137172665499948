const ensureJson = (input) => {
  try {
    // Try to parse the input to check if it's already valid JSON
    const parsed = JSON.parse(input);

    // If parsing is successful, return the parsed JSON object
    return parsed;
  } catch (e) {
    // If parsing fails (i.e., input is not valid JSON), assume it's a plain string
    // and transform it to JSON by wrapping it into an object or other desired structure.
    return { data: input };
  }
};

export default ensureJson;
