import React from "react";
import { ManOutlined, WomanOutlined } from "@ant-design/icons";

const GenderDistribution = () => (
  <div className="text-center p-4 bg-white shadow-md rounded-lg h-full">
    {/* Total de población */}
    <div className="py-2 h-2/6">
      <h3 className="font-semibold">Total de población</h3>
      <div className="flex justify-center items-center">
        <ManOutlined className="text-gray-700" />
        <p className="mx-2">49,0% Hombres</p>
        <div className="border-l border-gray-400 mx-2 h-6"/>
        <WomanOutlined className="text-gray-700" />
        <p className="mx-2">51,0% Mujeres</p>
      </div>
    </div>

    {/* Población de cabecera */}
    <div className="py-2 border-t border-gray-200 h-2/6">
      <h3 className="font-semibold">Población de cabecera</h3>
      <div className="flex justify-center items-center">
        <ManOutlined className="text-gray-700" />
        <p className="mx-2">49,0% Hombres</p>
        <div className="border-l border-gray-400 mx-2 h-6"/>
        <WomanOutlined className="text-gray-700" />
        <p className="mx-2">51,0% Mujeres</p>
      </div>
    </div>

    {/* Población rural */}
    <div className="py-2 border-t border-gray-200 h-2/6">
      <h3 className="font-semibold">Población rural</h3>
      <div className="flex justify-center items-center">
        <ManOutlined className="text-gray-700" />
        <p className="mx-2">49,0% Hombres</p>
        <div className="border-l border-gray-400 mx-2 h-6"/>
        <WomanOutlined className="text-gray-700" />
        <p className="mx-2">51,0% Mujeres</p>
      </div>
    </div>
  </div>
);


export default GenderDistribution;
