/* eslint-disable no-underscore-dangle */
import {  CalendarOutlined,UserOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Avatar, List, Space } from "antd";
import AppPagination from "../../organisms/AppTable/AppPagination";
// import ValidatePermissions from "../../../helpers/ValidatePermissions";

const ListTemplate = ({
  header,
  data,
  className = "md:max-w-[600px] md:w-full lg:max-w-[920px] 2xl:max-w-[calc(100vw-120px)] 2xl:px-10",
  currentPage,
  total,
  page_size = 10,
  onClickRow = () => {},
  handlePageChange = () => {},
  hasPagination = true,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState({});

  // const onDownloadFile = (item) => {
  //   // eslint-disable-next-line no-console

  //   setLoading((prevLoading) => ({ ...prevLoading, [item.key]: true }));
  //   setTimeout(() => {
  //     setLoading((prevLoading) => ({ ...prevLoading, [item.key]: false }));
  //   }, 2000);
  // };

  const authorTitle = ({ author, title }) => (
    <Space direction="vertical">
      <span className=" text-[13px]">{author}</span>
      {title}
    </Space>
  );

  return (
    <div className={`max-w-[calc(100vw-20px)]${className} `}>
      <List
        itemLayout="vertical"
        header={header && header}
        dataSource={data}
        className="font-Poppins"
        renderItem={(item, rowIndex) => (
          <List.Item
            key={item._id}
            actions={[
              <Space>
                <CalendarOutlined />
                {item.date}
              </Space>,
              // <ValidatePermissions
              //   container={false}
              //   func="oneButton"
              //   permission="download"
              //   modulePermission="researchpublished"
              // >
              //   <Button
              //     className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none"
              //     icon={!loading[item.key] ? <DownloadOutlined /> : <Spin />}
              //     onClick={() => onDownloadFile(item)}
              //   />
              // </ValidatePermissions>,
            ]}
            extra={
              <img
                width={272}
                style={{ maxHeight: "201px" }}
                alt="Logo"
                src={item.imageUrl}
              />
            }
          >
            <List.Item.Meta
              onClick={() => onClickRow(item, rowIndex)}
              avatar={<Avatar icon={<UserOutlined />} />}
              title={authorTitle({ author: item.author, title: item.title })}
              description={item.abstract}
            />
          </List.Item>
        )}
      />

      {hasPagination ? (
        <AppPagination
          page={currentPage}
          total={total}
          page_size={page_size}
          onChange={() => {
            handlePageChange();
            setLoading((prev) => ({ ...prev, list: true }));
          }}
        />
      ) : null}
    </div>
  );
};
export default ListTemplate;
