import React, { useEffect, useState } from "react";
import { Form, ConfigProvider, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import AppText from "../../atoms/Text/AppText";
import SubmitButton from "../../atoms/Buttons/SubmitButton";
import UploadExcel from "../../molecules/Upload/UploadExcel";
import { selectWhiteStyle } from "../../../assets/theme/themeConfig";

const JsonUploadForm = ({ onSubmitFile, onDownloadTemplate }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [uploadValidated, setUploadValidated] = useState(false);

  useEffect(() => {
    if (fileList.length > 0) {
      setUploadValidated(true);
    } else {
      setUploadValidated(false);
    }
  }, [fileList]);

  const handleSubmitButton = (data) => {
    const auxData = { ...data, upload: fileList[0] };
    onSubmitFile(auxData);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: selectWhiteStyle,
        },
      }}
    >
      <Form form={form} onFinish={handleSubmitButton} className="p-5">
        <div className="flex flex-col items-center border rounded-[32px] border-gray_custom px-2 py-5">
          <div className="flex flex-col items-center">
            <div className=" text-center mx-8 pb-2 max-w-[542px] ">
              <div className="mb-4">
                <AppText
                  title="El archivo Json debe contener la estructura apropiada. Puedes descargar una plantilla aquí..."
                  className="font-light text-color_text  text-center  max-w-[542px]  "
                />
                <Button
                  className="text-lightBlue2 w-12 hover:text-lightBlue hover:shadow-none "
                  icon={<DownloadOutlined />}
                  onClick={onDownloadTemplate}
                />
              </div>
              <AppText
                title="Cargar archivos de tipo JSON"
                className="text-color_text text-center pb-4 font-semibold "
              />
            </div>
            <div className="w-full sm:w-1/2">
              <Form.Item name="upload">
                <UploadExcel onlyJson title="Importar" settingFileList={setFileList} />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="h-10">
          <SubmitButton
            nameButton="Subir"
            className=" mt-5"
            htmlType="submit"
            buttonColor="bg-lightGreen"
            // loading={loading}
            form={form}
            doubleCheckValidation={uploadValidated}
          />
        </div>
      </Form>
    </ConfigProvider>
  );
};

export default JsonUploadForm;
