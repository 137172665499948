import React, { useState, useEffect } from "react";
import { ConfigProvider, TreeSelect, Form, message } from "antd";
import { selectWhiteStyle } from "../../../assets/theme/themeConfig";
import AppText from "../../atoms/Text/AppText";
import SubmitButton from "../../atoms/Buttons/SubmitButton";

const { SHOW_PARENT } = TreeSelect;

const AssociateIndicatorForm = ({
  columnName,
  onHandleSubmit,
  listGroupIndicator,
  initialValues,
}) => {
  const [form] = Form.useForm();
  const [value, setValue] = useState([]);
  const [parentValue, setParentValue] = useState([]); // Almacena los IDs de los padres
  const [children, setChildren] = useState([]); // Almacena los elementos seleccionados con sus padres
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        indicator: initialValues.indicator,
      });
  
      // Aseguramos que initialValues tenga datos válidos antes de asignar
      if (initialValues.parentValues && initialValues.parentValues.length > 0) {
        setValue(initialValues.indicator || []); // Asignamos los indicadores
        setParentValue(initialValues.parentValues.map((parent) => parent.parent)); // Asignamos los parentIds
  
        // Asignamos los valores de children si están presentes
        setChildren(
          initialValues.parentValues.map((item) => ({
            parentId: item.parent,
            childs: item.childs,
          }))
        );
        setIsEdit(true);
      } else {
        setValue([]);
        setParentValue([]);
        setChildren([]);
        setIsEdit(false);
      }
    } else {
      form.resetFields();
      setValue([]);
      setParentValue([]);
      setChildren([]);
      setIsEdit(false);
    }
  }, [initialValues, form]);

  const onChange = (newValue, label, extra) => {
    const { triggerValue, checked, triggerNode } = extra;

    if (triggerNode) {
      const parent_id = triggerNode.props.parent;

      if (checked) {
        // Verificar si ya hay un elemento seleccionado del mismo grupo
        const selectedInGroup = children.find((item) => item.parentId === parent_id);

        if (selectedInGroup) {
          // Si ya hay un elemento seleccionado en el grupo, mostrar error y revertir la selección
          message.error("Solo puede seleccionar un ítem por grupo.");
          form.setFieldsValue({ indicator: value }); // Revertir la selección visualmente
          return;
        }

        // Agregar el nuevo valor al listado de seleccionados
        setChildren((prev) => [...prev, { parentId: parent_id, childs: [triggerValue] }]);
        setValue((prev) => [...prev, triggerValue]);
        setParentValue((prev) => [...new Set([...prev, parent_id])]); // Agrega parent_id si no está presente
      } else {
        // Remover el ítem si se desmarca
        const updatedChildren = children.filter((item) => item.childs[0] !== triggerValue);
        setChildren(updatedChildren);
        setValue(updatedChildren.map((item) =>item.childs[0]));
        setParentValue(updatedChildren.map((item) => item.parentId)); // Actualiza parentValue
      }
    } else {
      setParentValue([]);
      setChildren([]);
      setValue([]);
    }
  };

  const onSubmit = (values) => {
    const data = {
      parent: parentValue,
      children,
    };
    onHandleSubmit(values, data, isEdit);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          TreeSelect: selectWhiteStyle,
          Select: selectWhiteStyle,
        },
      }}
    >
      <Form
        form={form}
        layout="vertical"
        className="font-Poppins"
        onFinish={(values) => {
          onSubmit(values);
        }}
      >
        <div className="flex flex-col items-center">
          <div className="p-2 flex flex-col">
            <div className="mb-2 grid justify-items-center pt-4">
              <AppText
                title= { columnName? `Asocie la columna "${columnName}" con un indicador o su variable de cálculo`:"Asocie las columnas a un indicador o su variable de cálculo"}
                type="secondary"
                className="text-darkAquamarineBlue font-semibold text-xl"
              />
            </div>

            <Form.Item
              name="indicator"
              colon={false}
              label={
                <span className="font-Poppins font-light text-color_text text-center">
                  Indicadores :
                </span>
              }
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione un grupo de indicador ",
                },
              ]}
              className="font-Poppins text-color_text"
            >
              <TreeSelect
                treeData={listGroupIndicator}
                value={value}
                onChange={onChange}
                treeCheckable
                showSearch={false}
                treeExpandAction="doubleClick"
                placeholder="Seleccione un indicador"
                className="font-Poppins font-medium text-color_text"
                allowClear
                showCheckedStrategy={SHOW_PARENT}
              />
            </Form.Item>
          </div>
          <Form.Item>
            <div className="flex justify-center text-center pt-2">
              <SubmitButton nameButton="Guardar" form={form} htmlType="submit" />
            </div>
          </Form.Item>
        </div>
      </Form>
    </ConfigProvider>
  );
};

export default AssociateIndicatorForm;
