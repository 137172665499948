import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Config from "../../../config";
import SubmitButton from "../../atoms/Buttons/SubmitButton";

const ReCaptchaForm = ({ onHandleCaptcha, loading }) => {
  const { keyRecap } = Config;
  const [capVal, setCapVal] = useState(false);

  return (
    <div className="flex flex-col items-center p-10">
      <ReCAPTCHA sitekey={keyRecap} onChange={(val) => setCapVal(val)} />
      <SubmitButton
        className="font-normal text-center mt-10"
        nameButton="Continuar"
        onClick={() => onHandleCaptcha(capVal)}
        doubleCheckValidation={capVal}
        loading={loading}
      />
    </div>
  );
};

export default ReCaptchaForm;
