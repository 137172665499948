import React, { useEffect, useRef } from "react";
import Plot from "react-plotly.js";
import Plotly from "plotly.js-dist-min";
import useImageChart from "../../../hooks/useImageChart";
// import Colors from "../../../utils/ColorsPage.json";

const ScatterPlot = ({
  dataPlot,
  title,
  titleX,
  titleY,
  height = "100%",
  isInvestigation = false,
}) => {
  const chartRef = useRef(null);
  const plotRef = useRef(null);

  useEffect(() => {
    // Check if the element is in the DOM and displayed
    const resizePlot = () => {
      if (plotRef.current && plotRef.current.offsetParent !== null) {
        Plotly.Plots.resize(plotRef.current);
      }
    };

    // Set initial resize with a small delay
    const initialResizeTimeout = setTimeout(resizePlot, 100);

    // Observe resizing of the chart container
    const resizeObserver = new ResizeObserver(resizePlot);
    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    // Clean up the observer and timeout on component unmount
    return () => {
      clearTimeout(initialResizeTimeout);
      resizeObserver.disconnect();
    };
  }, []);

  const returnDataplot = () => {
    if (!Array.isArray(dataPlot)) {
      return [
        {
          type: "scatter",
          mode: "markers",
          x: [1, 2, 3, 4, 5],
          y: [1, 4, 6, 8, 10],
          name: "Series 2",
        },
      ];
    }
    return dataPlot;
  };
  if (!isInvestigation) useImageChart({ plotRef, chartType: "scatter" });

  return (
    <div ref={chartRef} style={{ width: "100%", height }}>
      <Plot
        ref={plotRef}
        layout={{
          title,
          autosize: true,
          xaxis: {
            title: titleX,
          },
          yaxis: {
            title: titleY,
          },
        }}
        data={returnDataplot()}
        style={{ width: "100%", height: "100%" }}
        onInitialized={(figure, graphDiv) => {
          plotRef.current = graphDiv; // Set plotRef once Plotly plot is initialized
        }}
      />
    </div>
  );
};

export default ScatterPlot;
