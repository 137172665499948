import React from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import AppModal from "./Modal";
import AppButton from "../../atoms/Buttons/AppButton";

const ModalConfirm = ({
  title,
  text,
  modalActive,
  closeModal,
  typeModal = "danger",
  okText = "Eliminar",
  titleCancelButton = "Cancelar",
  handleOK,
  handleCancel,
  centered,
  isModalDelete = false,
  loading = false,
}) =>
  modalActive ? (
    <AppModal
      centered={centered}
      title={title}
      open={modalActive}
      onCancel={closeModal}
      // eslint-disable-next-line react/jsx-boolean-value
      showFooter={false}
      className=" text-lightBlue2 text-lg text-center"
      okType={typeModal}
      icon={<ExclamationCircleFilled />}
    >
      <div className="py-5">
        <div className="flex justify-center">
          <strong className="text-lg text-[#7C7C7C] text-center">{text}</strong>
        </div>
        <div className="flex justify-evenly mt-5">
          <AppButton
            title={isModalDelete ? "Cancelar" : titleCancelButton}
            onClick={handleCancel}
            loading={loading}
            className="bg-dark_gray"
          />
          <AppButton
            title={isModalDelete ? "Eliminar" : okText}
            onClick={handleOK}
            loading={loading}
            className="bg-lightBlue2"
          />
        </div>
      </div>
    </AppModal>
  ) : null;
export default ModalConfirm;
