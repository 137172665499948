import React, { useEffect, useRef } from "react";
import Plot from "react-plotly.js";
import Plotly from "plotly.js-dist-min";
import useImageChart from "../../../hooks/useImageChart";

const BarChart = ({
  axisX,
  axisY,
  title,
  titleX,
  titleY,
  isInvestigation = false,
}) => {
  const chartRef = useRef(null);
  const plotRef = useRef(null);

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (chartRef.current) {
  //       window.requestAnimationFrame(() => {
  //         Plotly.Plots.resize(chartRef.current);
  //       });
  //     }
  //   };

  //   // Set initial resizes
  //   handleResize();

  //   // Add event listener for window resize
  //   window.addEventListener("resize", handleResize);

  //   // Clean up event listener on component unmount
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  useEffect(() => {
    // Check if the element is in the DOM and displayed
    const resizePlot = () => {
      if (plotRef.current && plotRef.current.offsetParent !== null) {
        Plotly.Plots.resize(plotRef.current);
      }
    };

    // Set initial resize with a small delay
    const initialResizeTimeout = setTimeout(resizePlot, 100);

    // Observe resizing of the chart container
    const resizeObserver = new ResizeObserver(resizePlot);
    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    // Clean up the observer and timeout on component unmount
    return () => {
      clearTimeout(initialResizeTimeout);
      resizeObserver.disconnect();
    };
  }, []);
  if (!isInvestigation) useImageChart({ plotRef, chartType: "bar" });

  return (
    <div ref={chartRef} style={{ width: "100%", height: "100%" }}>
      <Plot
        ref={plotRef}
        data={[
          {
            type: "bar",
            x: axisX,
            y: axisY,
            marker: {
              color: "rgba(55,128,191,0.6)",
              width: 1,
            },
          },
        ]}
        layout={{
          title,
          xaxis: {
            title: titleX,
          },
          yaxis: {
            title: titleY,
          },
          bargap: 0.05,
          autosize: true,
        }}
        useResizeHandler
        style={{ width: "100%", height: "100%" }}
        // onInitialized={(_, graphDiv) => {
        //   plotRef.current = graphDiv;
        // }}
        onInitialized={(figure, graphDiv) => {
          plotRef.current = graphDiv; // Set plotRef once Plotly plot is initialized
        }}
      />
    </div>
  );
};

export default BarChart;
