import { Card } from "antd";
import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons";
import PieChart from "../../atoms/Graphs/PieChart";
import BarChart from "../../atoms/Graphs/BarChart";
import StackedBarChart from "../../atoms/Graphs/StackedBarChart";
import TimeSeriesChartLine from "../../atoms/Graphs/TimeSeriesChartLine";
import ScatterPlot from "../../atoms/Graphs/ScatterPlot";
import MapCard from "../../organisms/MapCard/MapCard";
import datammap from "../../../assets/Json/coordinatesDummy.json";

const CardChart = ({
  typeChart,
  title,
  titleX,
  titleY,
  data,
  handleEdit,
  handleDelete,
  isInvestigation = false,
  isViewInvestigation = false,
}) => {
  const renderChart = () => {
    if (typeChart === "stackedBar")
      return (
        <StackedBarChart
          title={title}
          dataChart={data || []}
          titleX={titleX || ""}
          titleY={titleY || ""}
          isInvestigation={isInvestigation}
        />
      );
    if (typeChart === "bar")
      return (
        <BarChart
          title={title}
          axisX={data ? data.x : []}
          axisY={data ? data.y : []}
          titleX={titleX || ""}
          titleY={titleY || ""}
          isInvestigation={isInvestigation}
        />
      );
    if (typeChart === "line")
      return (
        <TimeSeriesChartLine
          title={title}
          axisX={data ? data.x : []}
          axisY={data ? data.y : []}
          titleX={titleX || ""}
          titleY={titleY || ""}
          isInvestigation={isInvestigation}
        />
      );
    if (typeChart === "pie")
      return (
        <PieChart
          title={title}
          values={data ? data.y : []}
          labels={data ? data.x : []}
          titleX={titleX || ""}
          titleY={titleY || ""}
          isInvestigation={isInvestigation}
        />
      );
    if (typeChart === "scatter")
      return (
        <ScatterPlot
          title={title}
          dataPlot={data || []}
          titleX={titleX || ""}
          titleY={titleY || ""}
          isInvestigation={isInvestigation}
        />
      );
    if (typeChart === "map")
      return (
        <MapCard
          data={datammap}
          initialLng={-77.269577}
          initialLat={1.258999}
          initialZoom={7}
          mapStyle="mapbox://styles/mapbox/light-v10"
          accessToken="pk.eyJ1Ijoic2FtaTkxNSIsImEiOiJjbG94dnNzMGQxZjRoMmluemo5N3JicWZxIn0.qf1xB4qUh2iam2aWrWbmRQ"
          height={300}
        />
      );

    return null;
  };

  const cardClass = isInvestigation
    ? "bg-white !rounded-large w-full h-[520px] p-4"
    : "bg-white !rounded-large w-[90%] lg:w-[45%] h-[520px] ml-2 mb-2";

  return (
    <Card className={cardClass} key={title}>
      <div className="flex space-x-3 w-full justify-end">
        {!isViewInvestigation ? (
          <button type="button" aria-label="button" onClick={handleEdit}>
            <EditOutlined className="text-2xl hover:text-[#615f5e]" />
          </button>
        ) : null}
        {!isInvestigation ? (
          <button type="button" aria-label="button" onClick={handleDelete}>
            <CloseCircleOutlined className="text-2xl hover:text-[#E23B30]" />
          </button>
        ) : null}
      </div>
      {renderChart()}
    </Card>
  );
};

export default CardChart;
