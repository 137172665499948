
import ROUTES from "./router";

const RoutesBreadcrumb = [
  {
    label: "Inicio",
    key: ROUTES.GENERAL.HOME,
  },
  {
    label: "Gestión de archivos",
    key: ROUTES.GENERAL.DOCUMENTS,
    children: [
      {
        label: "Información de archivo",
        key: ROUTES.GENERAL.DOCUMENT_INFO,
      }
    ],
  },
  {
    label: "Informes",
    key: ROUTES.GENERAL.STATEMENT,
  },
  {
    label: "Informe de indicadores trazadores",
    key: ROUTES.GENERAL.STATEMENT_INDICATORS,
  },
  {
    label: "Reportes",
    key: ROUTES.GENERAL.REPORT,
  },
  {
    label: "Gestión de postulaciones",
    key: ROUTES.GENERAL.INDICATORS,
  },
  {
    label: "Gestión de taxonomías",
    key: ROUTES.GENERAL.INDICATORS_ADMIN,
  },
  {
    label: "Gestión de usuarios",
    key: ROUTES.GENERAL.USERS_LIST,
  },
  {
    label: "Investigaciones publicadas",
    key: ROUTES.GENERAL.INVESTIGATION,
  },
  {
    label: "Investigaciones",
    key: ROUTES.GENERAL.INVESTIGATION_ADMIN,
    children: [
      {
        label: "Revisión de investigación",
        key: ROUTES.GENERAL.INVESTIGATION_REVIEW,
      },
      {
        label:"Detalle de investigación",
        key: ROUTES.GENERAL.INVESTIGATION_INFO,
      }
    ],
  },
  {
    label: "Análisis comparativo",
    key: ROUTES.GENERAL.COMPARATIVE_ANALYSIS,
  },
  {
    label: "Detalle de análisis comparativo",
    key: ROUTES.GENERAL.COMPARATIVE_ANALYSIS_DETAIL,
  },
  {
    label: "Solicitudes",
    key: ROUTES.GENERAL.APPLICATIONS,
  },
  {
    label: "Historial",
    key: ROUTES.GENERAL.HISTORY,
  },
  
];

export default RoutesBreadcrumb;
