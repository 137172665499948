import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Tour from "reactour";
import { Space, Card } from "antd";
import AppText from "../../atoms/Text/AppText";
import FormHomeEvent from "../../molecules/FormSelect/FormHomeEvent";
import FormHomeHealthEvents from "../../molecules/CheckBoxGroup/FormHomeHealthEvents";
import FormDateRange from "../../molecules/FormDate/FormDateRange";
import SubmitButtonNotValidate from "../../atoms/Buttons/SubmitButtonNotValidate";
import FormMultiSelect from "../../molecules/FormSelect/FormMultiSelect";

const steps = [
  {
    selector: ".violence-events",
    content: "Aquí puedes seleccionar eventos de violencia para visualizar en el mapa."
  },
  {
    selector: ".zones-select",
    content: "Selecciona una o más zonas para filtrar los eventos."
  },
  {
    selector: ".health-events",
    content: "Selecciona eventos de salud para incluir en la visualización."
  },
  {
    selector: ".date-range",
    content: "Especifica el rango de fechas para los eventos que quieres visualizar."
  },
  {
    selector: ".submit-button",
    content: "Haz clic en 'Buscar' para aplicar los filtros seleccionados."
  },
];

const HomeForm = ({
  onSelectItemEvent,
  onSelectItemZone,
  municipalities,
  events,
  zones,
  healthList,
  violenceList,
  form,
  onClick,
}) => {
  const [isTourOpen, setIsTourOpen] = useState(false);

  useEffect(() => {
    // Verificar el estado de la cookie cuando el componente se monta
    const tutorialStatus = Cookies.get("tutorialHome");
    if (tutorialStatus === "started") {
      setIsTourOpen(true);
    }
  }, []);

  const handleTourClose = () => {
    // Configurar la cookie al final del tour
    Cookies.set("tutorialHome", "end", { expires: 7 });
    // Cerrar el tour
    setIsTourOpen(false);
  };

  return (
    <>
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={handleTourClose}
      />
      <div className="flex justify-normal sm:justify-end mx-4 ">
        <Card bordered className="text-center mt-10 shadow-md  bg-white">
          <Space direction="vertical" className="gap-6">
            <Space direction="vertical" className="gap-0 space-center">
              <AppText
                title="Desea visualizar"
                type="secondary"
                className="text-darkAquamarineBlue text-xl"
              />
              <AppText
                title="Eventos de Violencia:"
                type="secondary"
                className="text-dark_gray text-base font-extralight"
              />
              <AppText
                title="Seleccione una o más opciones para visualizar en el mapa"
                type="Eventos de Violencia"
                className="italic text-dark_gray text-xs font-extralight"
              />
              <FormHomeEvent
                className="mb-0 mt-1 w-80 violence-events"
                onSelectEvent={onSelectItemEvent}
                violenceList={violenceList}
                name="violence"
              />
              <AppText
                title={events.length ? `Has seleccionado ${events.map(event => event.label.toLowerCase()).join(", ")}` : ""}
                type="Eventos"
                className="italic text-dark_gray text-xs font-extralight"
              />
            </Space>
            <Space direction="vertical" className="gap-0 space-center">
              <AppText
                title="Zonas:"
                type="secondary"
                className="text-dark_gray text-base font-extralight"
              />
              <AppText
                title="Seleccione una o más opciones"
                type="Eventos de Violencia"
                className="italic text-dark_gray text-xs font-extralight"
              />
              <FormMultiSelect
                className="mb-0 mt-1 w-80 zones-select"
                onSelectOption={onSelectItemZone}
                options={municipalities}
                name="zones"
                labelInValue
              />
              <AppText
                title={zones.length ? `Has seleccionado ${zones.map(zone => zone.label).join(", ")}` : ""}
                type="Zonas"
                className="italic text-dark_gray text-xs font-extralight"
              />
            </Space>
            <Space direction="vertical" className="gap-0 health-events">
              <AppText
                title="Eventos de Salud:"
                type="secondary"
                className="text-dark_gray text-base font-extralight"
              />
              <AppText
                title="Seleccione una o más opciones"
                type="Eventos de salud"
                className="italic text-dark_gray text-xs font-extralight"
              />
              <FormHomeHealthEvents
                name="health"
                healthList={healthList}
              />
            </Space>
            <Space direction="vertical" className="gap-0 space-center date-range">
              <AppText
                title="Fechas:"
                type="secondary"
                className="text-dark_gray text-base font-extralight"
              />
              <FormDateRange name="dateRange" className="mt-1 w-80" />
            </Space>
          </Space>
          <Space direction="vertical" className="gap-0 submit-button">
            <SubmitButtonNotValidate
              nameButton="Buscar"
              form={form}
              className="bg-darkAquamarineBlue text-white_custom rounded-full w-80 h-10"
              onClick={onClick}
            />
          </Space>
        </Card>
      </div>
    </>
  );
};

export default HomeForm;
