import { useEffect, useState } from "react";
import AppButton from "../../atoms/Buttons/AppButton";
import AppModal from "../../molecules/Modal/Modal";
import formatSize from "../../../helpers/formatSize";
import DocumentFormTemplate from "../DocumentTemplate/DocumentFormTemplate";
import document from "../../../api/document";
import useApi from "../../../api/useApi";
import { useAuth } from "../../../contexts/AuthContext";
import transformToDateFormat from "../../../helpers/transformToDateFormat";
import { localeTime } from "../../../helpers/transformUTCtoColombiaTime";

const GeneralInformation = ({ idDoc }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const updateDescriptionDoc = useApi(document.updateDescriptionDoc);
  const [dataDescription, setDataDescription] = useState({});
  const getDescriptionDoc = useApi(document.getDescriptionDoc);
  const { setMessage } = useAuth();

  const getInfoDoc = () => {
    getDescriptionDoc.request(idDoc);
  };

  useEffect(() => {
    getInfoDoc();
  }, []);

  useEffect(() => {
    if (updateDescriptionDoc.data) {
      setMessage({
        type: "success",
        title: "Resultado",
        description:
          "La descripción del documento se ha actualizado correctamente",
      });
      setShowModal(false);
      getInfoDoc();
      setLoading(false);
      updateDescriptionDoc.setData();
    }
    if (getDescriptionDoc.data) {
      const aux = getDescriptionDoc.data.data;
      setLoading(false);
      setDataDescription(aux);
      getDescriptionDoc.setData();
    }
  }, [updateDescriptionDoc.data, getDescriptionDoc.data]);

  const onClickEdit = () => {
    setShowModal(true);
  };

  const renderSize = () => {
    if (dataDescription) {
      if (dataDescription.size) {
        return formatSize(dataDescription.size);
      }
      return "";
    }
    return "";
  };

  const handlerSubmitDescription = (val) => {
    const { name, description } = val;
    const urlWebSource = val?.urlWebSource || null;
    const keyWords = val?.keyWords || [];
    const cutOffDate = val?.cutOffDate
      ? transformToDateFormat(val?.cutOffDate)
      : null;

    const auxData = {
      name,
      description,
      urlWebSource,
      keyWords,
      cutOffDate,
    };
    updateDescriptionDoc.request(idDoc, auxData);
    setLoading(true);
  };

  return (
    <div className="flex w-full flex-col p-5">
      <div className="flex w-full justify-end py-2 pr-2">
        <AppButton
          className="!bg-lightBlue2 text-white  max-h-[120px] self-center"
          title="Editar"
          onClick={onClickEdit}
        />
      </div>

      <div className="mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-3 sm:gap-x-20 gap-y-2 sm:gap-y-5">
          <div>
            <p>Nombre del archivo:</p>
            <strong className="text-colorBorder">
              {dataDescription?.name}
            </strong>
          </div>
          <div>
            <p>Tamaño:</p>
            <strong className="text-colorBorder">{renderSize()}</strong>
          </div>
          <div>
            <p>Fecha de subida:</p>
            <strong className="text-colorBorder">
              {localeTime(dataDescription?.createdAt)}
            </strong>
          </div>
          <div>
            <p>Tipo de archivo:</p>
            <strong className="text-colorBorder">
              {dataDescription?.fileExtension}
            </strong>
          </div>
          <div>
            <p>Tipo de fuente:</p>
            <strong className="text-colorBorder">
              {dataDescription?.docTypeDetails?.name}
            </strong>
          </div>
          <div>
            <p>Url de fuente:</p>
            <strong className="text-colorBorder">
              {dataDescription?.urlWebSource}
            </strong>
          </div>
          <div>
            <p>Fecha de última actualización:</p>
            <strong className="text-colorBorder">
              {localeTime(dataDescription?.updatedAt)}
            </strong>
          </div>
          <div>
            <p>Código:</p>
            <strong className="text-colorBorder">
              {dataDescription?.code}
            </strong>
          </div>
          <div>
            <p>Palabras clave:</p>
            <strong className="text-colorBorder break-all">
              {dataDescription?.keyWords?.join()}
            </strong>
          </div>
          <div>
            <p>Fecha de corte:</p>
            <strong className="text-colorBorder">
              {dataDescription?.cutOffDate}
            </strong>
          </div>
          <div>
            <p>Descripción:</p>
            <strong className="text-colorBorder">
              {dataDescription?.description}
            </strong>
          </div>
        </div>
      </div>
      {showModal ? (
        <AppModal
          open={showModal}
          onCancel={() => setShowModal(false)}
          footer={false}
          className=" text-lightBlue2 font-bold  text-center !important"
          width={700}
        >
          <DocumentFormTemplate
            documentKey={1}
            action="upload"
            dataDescription={dataDescription}
            handlerSubmit={handlerSubmitDescription}
            loading={loading}
            hasTitle
          />
        </AppModal>
      ) : null}
    </div>
  );
};

export default GeneralInformation;
