const listCreateUserRolesGeneral = [
  {
    id: 1,
    value: "Funcionario de Gobierno",
    label: "Funcionario Público ",
  },
  {
    id: 2,
    value: "Usuario General",
    label: "Usuario General",
  },
  {
    id: 3,
    value: "Investigador",
    label: "Investigador",
  },
];
const listCreateUserAdmin = [
  {
    id: 1,
    value: "Funcionario de Gobierno",
    label: "Funcionario Público ",
  },
  {
    id: 2,
    value: "Usuario General",
    label: "Usuario General",
  },
  {
    id: 3,
    value: "Investigador",
    label: "Investigador",
  },
  {
    id: 3,
    value: "Administrador",
    label: "Administrador",
  },
];

export { listCreateUserAdmin, listCreateUserRolesGeneral };
