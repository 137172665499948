import React, { useEffect, useRef } from "react";
import Plot from "react-plotly.js";
import Plotly from "plotly.js-dist-min";
import useImageChart from "../../../hooks/useImageChart";

const PieChart = ({ values, labels, title, titleX, isInvestigation = false }) => {
  const chartRef = useRef(null);
  const plotRef = useRef(null);

  useEffect(() => {
    // Check if the element is in the DOM and displayed
    const resizePlot = () => {
      if (plotRef.current && plotRef.current.offsetParent !== null) {
        Plotly.Plots.resize(plotRef.current);
      }
    };

    // Set initial resize with a small delay
    const initialResizeTimeout = setTimeout(resizePlot, 100);

    // Observe resizing of the chart container
    const resizeObserver = new ResizeObserver(resizePlot);
    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    // Clean up the observer and timeout on component unmount
    return () => {
      clearTimeout(initialResizeTimeout);
      resizeObserver.disconnect();
    };
  }, []);

  if (!isInvestigation)
    useImageChart({
      plotRef,
      chartType: "pie",
      imageConfig: { height: 500, width: 500 },
    });

  return (
    <div ref={chartRef} style={{ width: "100%", height: "100%" }}>
      <Plot
        ref={plotRef}
        data={[
          {
            type: "pie",
            values,
            labels,
            textinfo: "label+percent",
            textposition: "inside",
            automargin: true,
            hoverinfo: "label+percent+name",
            name: titleX,
          },
        ]}
        layout={{
          title,
          showlegend: false,
        }}
        useResizeHandler
        style={{ width: "100%", height: "100%" }}
        onInitialized={(figure, graphDiv) => {
          plotRef.current = graphDiv; // Set plotRef once Plotly plot is initialized
        }}
      />
    </div>
  );
};

export default PieChart;
