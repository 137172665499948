const setProperties = (userDetails, emailAuth) => {
  if (!userDetails) return null;
  if (!emailAuth) return null;
  if (userDetails.email === emailAuth) {
    return {
      name: "Yo"
    };
  }

  return {
    name: `${userDetails.firstName  } ${  userDetails.lastName}`
  };
}

export default setProperties;