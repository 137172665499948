import { useEffect } from "react";
import Plotly from "plotly.js-dist-min";
import { useData } from "../contexts/DataContext";
import { useAuth } from "../contexts/AuthContext";
import fetchingFiles from "../api/fetchingFiles";

const useImageChart = ({ plotRef, chartType, imageConfig = {} }) => {
  const {
    chartToSnapshot,
    setChartToSnapshot,
    urlToUploadSnapshot,
    setUrlToUploadSnapshot,
  } = useData();
  const { setMessage } = useAuth();

  useEffect(() => {
    if (!plotRef.current) return;

    const takeSnapshot = () => {
      Plotly.toImage(plotRef.current, {
        format: imageConfig.format || "png", // Allow customization via config
        height: imageConfig.height || 500,
        width: imageConfig.width || 700,
      })
        .then((dataUrl) => {
          const byteString = atob(dataUrl.split(",")[1]);
          const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];
          const arrayBuffer = new ArrayBuffer(byteString.length);
          const intArray = new Uint8Array(arrayBuffer);
          for (let i = 0; i < byteString.length; i += 1) {
            intArray[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([arrayBuffer], { type: mimeString });
          fetchingFiles({
            url: urlToUploadSnapshot,
            file: { type: mimeString, originFileObj: blob },
          });
        })
        .catch(() => {
          setMessage({
            type: "error",
            title: "Error",
            description: "Hubo un problema al descargar la imagen.",
          });
        });
    };

    if (chartToSnapshot === chartType && urlToUploadSnapshot) {
      takeSnapshot();
      setChartToSnapshot();
      setUrlToUploadSnapshot();
    }
  }, [plotRef, chartToSnapshot, imageConfig, urlToUploadSnapshot]); // Dependencies are important
};

export default useImageChart;
