import React from "react";
import { Form } from "antd";
import AppCheckBox from "../../atoms/CheckBox/AppCheckBox";
/* eslint-disable react/jsx-props-no-spreading */

const FormHomeHealthEvents = ({ className = "", name, label, onChange,healthList, ...props }) => {
  const combinedClassName = `font-Poppins text-black_custom formhome ${className}`;
  
  return (
    <Form.Item
      name={name}
      label={label}
      {...props}
      className={combinedClassName}
      // rules={[
      //   {
      //     required: true,
      //     message: "Por favor seleccione una opción!",
      //   },
      // ]}
      rules={[
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (value || getFieldValue("violence")) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('Debes seleccionar al menos un evento de "violencia" o "salud"'));
          },
        }),
      ]}
    >
      <AppCheckBox 
        className=""
        onChange={onChange}
        options={healthList}
      />
    </Form.Item>

  );
}

export default FormHomeHealthEvents;