import { Navigate } from "react-router-dom";
import token from "../api/token";

const NotRequireAuth = ({ children }) => {
  const accessToken = token.getToken()
  const isAuthenticated = !!accessToken;

  return isAuthenticated ? <Navigate to="/dashboard" replace /> : children;
};

export default NotRequireAuth;
