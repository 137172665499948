import React ,{useEffect,useState}from "react";
import { ConfigProvider, Form, Input } from "antd";


// import "../../../index.css";
/* eslint-disable react/jsx-props-no-spreading */


const FormAutoSearch = ({
  name,
  label,
  placeholder,
  className = "",
  rules,
  labelCol = "",
  styleForm = "",
  loading = false,
  submitSearch,
  resetValue,
}) => {
  const combinedClassName = `font-Poppins text-white_custom  ${styleForm}`;
  const combinedClassSelect = `font-Poppins font-light text-sizeBase text-black_custom rounded-full h-[40px] ${className}`;
  const [searchValue, setSearchValue] = useState("");
  

  const handleSearch = (value) => {
    // setSearchValue(value);
    // setSearchValue("");
    // Aquí puedes agregar la lógica para realizar la búsqueda
    submitSearch(value);
  };

  useEffect(() => {
    if (resetValue) {
      setSearchValue("");
    }
  }, [resetValue]);

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  

  return (
    <ConfigProvider
      theme={{
        "components": {
          "Input": {
            "colorIcon": "rgb(76, 172, 188)",
            "colorIconHover": "rgb(76, 172, 188)",
            "borderRadius": 9999,
            "borderRadiusLG": 9999
          }
        }
      }}
    >
      <Form.Item
        labelCol={labelCol}
        colon={false}
        name={name}
        label={
          label ? (
            <span className="font-Poppins font-light text-sizeBase text-color_text">
              {label}
            </span>
          ) : null
        }
        rules={rules}
        className={combinedClassName}
      >
        <Input.Search
          // {...props}
          sharpes="round"
          // size="large"
          placeholder={placeholder}
          className={combinedClassSelect}
          // addonBefore={<SearchOutlined />}
          onSearch={handleSearch}
          value={searchValue}
          loading={loading}
          onChange={handleChange}
          

        />

      </Form.Item>
    </ConfigProvider>
  );
};

export default FormAutoSearch;
