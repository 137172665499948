import { Steps } from "antd";
import React from "react";


// eslint-disable-next-line arrow-body-style
const Step = ({ items, current }) => {
  
  return (
    <Steps current={current} items={items} responsive={false} className=" text-black_custom max-w-xl font-Poppins" />
  );
};
export default Step;