import React, { useEffect, useRef } from "react";
import Plot from "react-plotly.js";
import Plotly from "plotly.js-dist-min";
import useImageChart from "../../../hooks/useImageChart";

const StackedBarChart = ({
  dataChart,
  title,
  titleX,
  titleY,
  isInvestigation = false,
}) => {
  const chartRef = useRef(null);
  const plotRef = useRef(null);

  
  useEffect(() => {
    // Check if the element is in the DOM and displayed
    const resizePlot = () => {
      if (plotRef.current && plotRef.current.offsetParent !== null) {
        Plotly.Plots.resize(plotRef.current);
      }
    };

    // Set initial resize with a small delay
    const initialResizeTimeout = setTimeout(resizePlot, 100);

    // Observe resizing of the chart container
    const resizeObserver = new ResizeObserver(resizePlot);
    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    // Clean up the observer and timeout on component unmount
    return () => {
      clearTimeout(initialResizeTimeout);
      resizeObserver.disconnect();
    };
  }, []);

  if (!isInvestigation) useImageChart({ plotRef, chartType: "stackedBar" });

  const returnDataChart = () => {
    if (!Array.isArray(dataChart)) {
      return [
        {
          type: "bar",
          x: ["Category A", "Category B", "Category C"],
          y: [5, 6, 7], // Low range values
          name: "Low",
          marker: {
            color: "rgba(55,128,191,0.6)",
            width: 1,
          },
        },
      ];
    }
    return dataChart;
  };
  return (
    <div ref={chartRef} style={{ width: "100%", height: "100%" }}>
      <Plot
        ref={plotRef}
        data={returnDataChart()}
        layout={{
          title,
          barmode: "stack",
          bargap: 0.05,
          autosize: true,
          xaxis: {
            title: titleX,
          },
          yaxis: {
            title: titleY,
          },
        }}
        useResizeHandler
        style={{ width: "100%", height: "100%" }}
        onInitialized={(figure, graphDiv) => {
          plotRef.current = graphDiv; // Set plotRef once Plotly plot is initialized
        }}
      />
    </div>
  );
};

export default StackedBarChart;
