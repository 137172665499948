import { useNavigate } from "react-router-dom";

const IndicatorDetailColumn = ({
  detailIndicator,
  titleIndicator,
  returnStateCalculation,
  returnValueFormula,
}) => {
  const navigate = useNavigate();
  return (
    <div>
      <p>Listado fuente de calculo:</p>
      <br />
      <p>Estado del indicador: {returnStateCalculation()}</p>
      <p>
        {titleIndicator} = {returnValueFormula()}
      </p>
      <div className="flex flex-col items-start">
        {detailIndicator?.sources?.map((item) => {
          if (item?.state === "assigned") {
            return (
              <div key={item.id} className="flex">
                <p>{item?.formula_name}: </p>
                <button
                  type="button"
                  className="underline text-lightBlue2 ml-1"
                  onClick={() => navigate(`/document/${item.fileId}`)}
                >
                  Link del archivo
                </button>
              </div>
            );
          }
          return <p key={item?.id}>{item?.formula_name}: No asignado</p>;
        })}
      </div>
    </div>
  );
};

export default IndicatorDetailColumn;
