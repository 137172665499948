// import "../../../index.css";
// import "../../../assets/css/stylesLogin.css";
import React from "react";
import { Form, Input, ConfigProvider } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { inputLoginStyle } from "../../../assets/theme/themeConfig";

/* eslint-disable react/jsx-props-no-spreading */
const FormEmailLogin = ({ name, label, placeholder, className = "", ...props }) => {
  const combinedClassName = `font-Poppins text-sizeBase text-black_custom ${className}`;
  return (
    <ConfigProvider
      theme={{
        components: {
          "Input": inputLoginStyle,
        },
      }}
    >

      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            type: "email",
            message: "El correo no es válido",
          },
          {
            required: true,
            message: "Por favor ingrese su correo electrónico",
          },
        ]}
        className={combinedClassName}
        {...props}
      >
        <Input className=" text-black_custom w-11/12  font-Poppins  text-sizeBase rounded-full !important"
          size="large"
          prefix={< UserOutlined style={{ color: "white", "fontSize": "27px", padding: "5px", alignItems: "center", }} />}
          autoComplete="off"
        />

      </Form.Item>
    </ConfigProvider>
  );
}

export default FormEmailLogin;

