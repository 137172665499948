import React from "react";
import { ConfigProvider, Select } from "antd";
/* eslint-disable react/jsx-props-no-spreading */
const SelectSheet = ({
  label,
  // className = "",
  options,
  onChange,
  loading,
  defaultValue,
  // ...props
}) => {
  // const combinedClassSelect = `font-Poppins  rounded-full    text-textCustom  ${selectClass} !important`;

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (

    <div className="pb-8">
      <span className="font-Poppins font-medium text-black_custom text-center  pr-2">
        {label}
      </span>
      <ConfigProvider
        theme={{
          "components": {
            "Select": {
              "clearBg": "rgb(76, 172, 188)",
              "colorBgContainer": "rgb(76, 172, 188)",
              "colorText": "rgb(240, 240, 240)",
              "colorTextQuaternary": "rgb(240, 240, 240)",
              "optionSelectedBg": "rgb(230, 243, 255)",
              "selectorBg": "rgb(76, 172, 188)",
              // "colorBgContainer": "rgb(138, 205, 208)",
              "colorBgElevated": "rgb(76, 172, 188)",
              "optionSelectedColor": "rgba(105, 72, 72, 0.88)",
              // "colorText": "rgb(240, 240, 240)",
              "colorTextPlaceholder": "rgb(240, 240, 240)",
            }
          }
        }}
      >

        <Select
          showSearch
          options={options}
          // className={combinedClassSelect}
          popupClassName="font-Poppins text-black_custom min-w-[200px]"
          className="  shadow-xl text-white_custom min-w-[200px]"
          optionFilterProp="children"
          filterOption={filterOption}
          onChange={onChange}
          loading={loading}
          defaultValue={defaultValue}
          dropdownStyle={{ whiteSpace: "nowrap" , width: "auto" }}
        />
      </ConfigProvider>
    </div>
  );
};

export default SelectSheet;
