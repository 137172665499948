/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
/* eslint-disable arrow-body-style */

const transformToTree = (data) => {
  if (!Array.isArray(data)) return [];

  const dataTransformed = data.map((groupItem) => {
    return {
      title: groupItem?.name,
      value: groupItem?._id,
      key: groupItem?._id,
      checkable: false,
      selectable: false,
      level: 0,
      parent: groupItem?._id,
      children: [...transformChildGroups(groupItem.childGroups, groupItem?._id)].sort(
        (a, b) => a.title.localeCompare(b.title)
      ), // Transforma los grupos de hijos
    };
  });
  return dataTransformed;
};

const transformChildGroups = (childGroups, parent_id) => {
  if (Array.isArray(childGroups)) {
    return childGroups.map((childGroup) => ({
      title: childGroup?.name,
      value: childGroup?._id,
      key: childGroup?._id,
      parent: parent_id,
      level: 1,
      isLeaf: childGroup.childGroups.length === 0 && childGroup.indicators.length === 0,
      children: [
        ...transformChildGroups(childGroup.childGroups, parent_id),
        ...transformIndicators(childGroup.indicators, parent_id),
      ].sort((a, b) => a.title.localeCompare(b.title)), // Ordena los hijos por título
    }));
  }
  return [];
};

const transformIndicators = (indicators, parent_id) => {
  return indicators.map((indicator) => ({
    title: indicator?.name,
    value: indicator?._id,
    key: indicator?._id,
    parent: parent_id,
    level: 2,
    isLeaf: true, // Indica que es una hoja en el árbol
  }));
};

export default transformToTree;
