const listCharts = [
  { label: "Gráfica de barras", value: "bar" },
  { label: "Gráfica de barras apiladas", value: "stackedBar" },
  { label: "Gráfica de torta", value: "pie" },
  { label: "Gráfica de líneas", value: "line" },
  { label: "Gráfico de dispersión", value: "scatter" },
  // { label: "Gráfica Map", value: "map" },
];

export default listCharts;
