import React from "react";
import { Modal, ConfigProvider } from "antd";
import { modalStyle } from "../../../assets/theme/themeConfig";
/* eslint-disable react/jsx-props-no-spreading */

const AppModal = ({ title, open, showFooter = false, onCancel, children, onOk, ok, cancel, ...props }) => {
  const titleSpna = title ? <span className="font-Poppins font-semibold text-2xl text-lightBlue2 text-center  !important ">{title}</span> : null;
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <ConfigProvider
        theme={{
          components: {
            "Modal": modalStyle,
          },
        }}
      >
        {showFooter ? (
          <Modal
            title={titleSpna}
            open={open}
            onCancel={onCancel}
            className="font-Poppins rounded-custom text-center !important"
            onOk={onOk}
            cancelText={cancel}
            okText={ok}
            {...props}
          >
            {children}
          </Modal>
        ) : (
          <Modal
            title={titleSpna}
            open={open}
            footer={null}
            onCancel={onCancel}
            className="font-Poppins rounded-custom text-center  !important"
            onOk={onOk}
            cancelText={cancel}
            okText={ok}
            {...props}
           
          >
            {children}
          </Modal>
        )
        }
      </ConfigProvider>
    </>
  )
};

export default AppModal;
