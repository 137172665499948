import React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const PdfViewer = ({ pdfUrl }) => (
  <div
    style={{
      border: "1px solid rgba(0, 0, 0, 0.3)",
      height: "750px",
    }}
  >
    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
      <Viewer fileUrl={pdfUrl} />
    </Worker>
  </div>
);

export default PdfViewer;