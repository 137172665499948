// Map.js
import "../../../assets/css/mapboxgl.css";
import React, { useRef, useEffect, useState } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = "pk.eyJ1Ijoic2FtaTkxNSIsImEiOiJjbG94dnNzMGQxZjRoMmluemo5N3JicWZxIn0.qf1xB4qUh2iam2aWrWbmRQ";

const Map = ({ data, initialLng, initialLat, initialZoom, height }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng] = useState(initialLng);
  const [lat] = useState(initialLat);
  const [zoom] = useState(initialZoom);

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10",
      center: [lng, lat],
      zoom,
    });

    if (data && data.features && map.current) {
      if (map.current.getSource("eventos")) {
        map.current.getSource("eventos").setData(data);
      } else {
        map.current.on("load", () => {
          map.current.addSource("eventos", {
            type: "geojson",
            data,
            cluster: true,
            clusterMaxZoom: 7,
            clusterRadius: 50,
          });

          map.current.addLayer({
            id: "clusters",
            type: "circle",
            source: "eventos",
            filter: ["has", "point_count"],
            paint: {
              "circle-color": [
                "step",
                ["get", "point_count"],
                "#6DBFA4",
                20,
                "#51bbd6",
                100,
                "#f1f075",
                750,
                "#f28cb1",
              ],
              "circle-radius": [
                "step",
                ["get", "point_count"],
                20,
                100,
                30,
                750,
                40,
              ],
            },
          });

          map.current.addLayer({
            id: "cluster-count",
            type: "symbol",
            source: "eventos",
            filter: ["has", "point_count"],
            layout: {
              "text-field": "{point_count_abbreviated}",
              "text-font": ["Poppins Bold", "Arial Unicode MS Bold"],
              "text-size": 12,
            },
          });

          map.current.addLayer({
            id: "unclustered-point",
            type: "circle",
            source: "eventos",
            filter: ["!", ["has", "point_count"]],
            paint: {
              "circle-color": [
                "match",
                ["get", "id_evento"],
                "AS", "#8B0000",
                "RU", "#800000",
                "MI", "#5E2129",
                "AT", "#FF8C00",
                "AP", "#800080",
                "DB", "#8B4513",
                "SE", "#FFD700",
                "VS", "#D02090",
                "DF", "#A9A9A9",
                "AF_FI", "#ADD8E6",
                "AF_EC", "#808000",
                "MRT", "#000000",
                "AF_SO", "#40E0D0",
                "AF_PSLO/PSSO", "#E6E6FA",
                "POADM", "#00FF00",
                "#66ABBA",
              ],
              "circle-radius": 10,
              "circle-stroke-width": 1,
              "circle-stroke-color": "#fff",
            },
          });

          const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: true,
            closeOnMove: true,
          });

          map.current.on("click", "unclustered-point", (e) => {
            const coordinates = e.features[0].geometry.coordinates.slice();
            const { properties } = e.features[0];
            let total_Eventos = 0;
            let eventos = "";

            const eventCounts = JSON.parse(properties.eventCounts);
            Object.entries(eventCounts).forEach(([eventName, count]) => {
              eventos += `<strong>${eventName.trim()} :</strong> ${count}<br>`;
              total_Eventos += count;
            });
            let description = `<strong> MUNICIPIO :</strong> ${properties.municipio}<br> 
            <strong>Total de Eventos : ${total_Eventos} </strong><br> Detalle de Eventos: <br>`;
            description += eventos;

            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }
            new mapboxgl.Popup()
              .setLngLat(coordinates)
              .setHTML(description)
              .addTo(map.current);
          });

          map.current.on("mouseenter", "clusters", (e) => {
            const features = map.current.queryRenderedFeatures(e.point, {
              layers: ["clusters"],
            });
            const clusterId = features[0].properties.cluster_id;
            const coordinates = e.features[0].geometry.coordinates.slice();

            map.current.getSource("eventos").getClusterLeaves(clusterId, Infinity, 0, (err, aFeatures) => {
              const eventCounts = {};

              aFeatures.forEach((feature) => {
                const eventType = feature.properties.evento;
                if (!eventCounts[eventType]) {
                  eventCounts[eventType] = 0;
                }
                eventCounts[eventType] += 1;
              });

              let eventos = "";
              let totalEventos = 0;
              Object.entries(eventCounts).forEach(([type, count]) => {
                eventos += `<strong>${type}:</strong> ${count}<br>`;
                totalEventos += count;
              });
              let description = `<strong>Total de Eventos : ${totalEventos}</strong><br>Detalle de Eventos:<br>`;
              description += eventos;

              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
              }

              popup.setLngLat(coordinates).setHTML(description).addTo(map.current);
            });
          });

          map.current.on("mouseleave", "clusters", () => {
            map.current.getCanvas().style.cursor = "";
            popup.remove();
          });

          map.current.on("click", "clusters", (e) => {
            const features = map.current.queryRenderedFeatures(e.point, {
              layers: ["clusters"],
            });
            const clusterId = features[0].properties.cluster_id;
            map.current
              .getSource("eventos")
              .getClusterExpansionZoom(clusterId, (err, z) => {
                if (err) return;

                map.current.easeTo({
                  center: features[0].geometry.coordinates,
                  zoom: z,
                });
              });
          });

          map.current.on("mouseenter", "clusters", () => {
            map.current.getCanvas().style.cursor = "pointer";
          });
          map.current.on("mouseleave", "clusters", () => {
            map.current.getCanvas().style.cursor = "";
          });

          map.current.on("mouseenter", "unclustered-point", () => {
            map.current.getCanvas().style.cursor = "pointer";
          });
        });
      }
    }
  }, [data, lng, lat, zoom]);

  const variableHeight = height; 
  const newheight = (variableHeight == null) ? 600 : variableHeight;

  const style = {
    height: `${newheight}px`,
  };

  const centerMap = () => {
    map.current.flyTo({
      center: [lng, lat],
      essential: true,
      zoom,
    });
  };

  return (
    <div>
      <div
        ref={mapContainer}
        className="map-container"
        style={style}
      />
      <div className="flex flex-row-reverse pt-4">
        <button onClick={centerMap} className="center-map-button" type="button">
          Centrar Mapa
        </button>
      </div>
    </div>
  );
};

export default Map;
