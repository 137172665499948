import { format } from "date-fns";

const convertDate = (newDate, dateFormat = "dd/LL/yyyy hh:mm a") => {
  try {
    if (!newDate) return "";
    let tempDate = newDate;
    if (tempDate.includes("T")) {
      const [date, hour] = newDate.split("T");
      let newHour = hour.includes("Z") ? hour.slice(0, -1) : hour;
      // Extra para Firefox
      newHour = newHour.replace(":00.000", "");
      const dateConcated = `${date} ${newHour}`;
      tempDate = dateConcated;
    }
    const result = format(new Date(tempDate), dateFormat);
    return result;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return "Error en fecha";
  }
};

export default convertDate;
