import newApi from "./newApi";
// import client_file from "./client_file";

// #region Advertisements

const getAllAnalysis = (keyword, page_size = 200, page = 1) => {
  let url = `/analysis/analysis?limit=${page_size}&offset=${page}`;
  if (keyword) url = url.concat("&", `keyword=${keyword}`);
  return newApi.get(url);
};

const getAnalysisKeyword = (keyWord) => {
  const url = `/analysis/analysis/keyword?keyword=${keyWord}`;
  return newApi.get(url);
};
const getAnalysisById = (id) => {
  const url = `/analysis/analysis/${id}`;
  return newApi.get(url);
};

const createAnalysis = (data) => {
  const url = "/analysis/analysis";
  return newApi.post(url, data);
};

const updateAnalysis = (id, data) => {
  const url = `/analysis/analysis/${id}`;
  return newApi.put(url, data);
};
const deleteAnalysis = (id) => {
  const url = "/analysis/analysis/delete";
  return newApi.post(url, {idAnalysis: id });
};

const getColumnData = (id, typeChart, axisX, axisY, label, agg, values) => {
  let complement = "";
  if (typeChart === "pie" )
    complement = `&column_name=${axisX}`;
  if (typeChart === "bar" )
    complement = `&x_axis=${axisX}&y_axis=${axisY}`;
  if (typeChart === "stackedBar")
    complement = `&x_axis=${axisX}&label=${label}&agg_function=${agg}${axisY === "none" || !axisY ? "" : `&y_axis=${axisY}`}`;
  if (typeChart === "scatter")
    complement = `&x_axis=${axisX}&y_axis=${axisY}${label === "none" || !label ? "" : `&label=${label}`}${values === "none" || !values ? "" : `&values=${values}`}`;
  if (typeChart === "line") complement = `&x_axis=${axisX}&y_axis=${axisY}`;
  const url = `/analysis/analysis/column-data/${id}?graph_type=${typeChart}${complement}`;
  const auxUrl = encodeURI(url);
  return newApi.get(auxUrl);
};

export default {
  getAllAnalysis,
  getAnalysisKeyword,
  getAnalysisById,
  createAnalysis,
  updateAnalysis,
  getColumnData,
  deleteAnalysis
};
