import React from "react";
import { Checkbox, Row, Col } from "antd";
import PropTypes from "prop-types";
/* eslint-disable react/jsx-props-no-spreading */

const AppCheckBox = ({ options, initialValue, className = "", onChange, ...props }) => {
  const combinedClassName = `font-Poppins ${className} w-1/3 ml-10 md:w-full md:ml-0`;
  return (
    <Checkbox.Group  
      defaultValue={initialValue} 
      className={combinedClassName}
      onChange={onChange}
      {...props}>
      <Row className="text-justify">
        {options.map((option) => (
          <Col xs={24} md={12} key={option.id} className="flex	items-center mt-3 ">
            <Checkbox key={option.id} value={option.value} className="text-dark_gray">
              {option.label}
            </Checkbox> 
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  )
}

AppCheckBox.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
};

export default AppCheckBox;