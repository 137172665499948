import React from "react";
import Plot from "react-plotly.js";

const TimeSeriesChartMultiLines = ({ listDataSources, municipality }) => {
  const colors = [
    "#EE7914",
    "#6E411A",
    "#6DBFA4",
    "#8E72A1",
    "#D6569A",
    "#8ACDD0",
    "#FFD831",
    "#2B4E89",
  ];

  const renderDataSets = () =>
    listDataSources.map((item, i) => {
      const auxFields = item?.data;
      if (auxFields?.length > 0) {
        return {
          x: listDataSources[i]?.data.map((val) => val?.date?.split("T")[0]),
          y: listDataSources[i]?.data.map((val) => val?.value),
          type: "line",
          mode: "lines+markers",
          name: listDataSources[i]?.data[0]?.name,
          line: {
            color: colors[i],
          },
        };
      }
      return {};
    });
  
  const layout = {
    title: municipality,
    xaxis: {
      rangeselector: {
        buttons: [
          {
            count: 1,
            label: "1m",
            step: "month",
            stepmode: "backward",
          },
          {
            count: 6,
            label: "6m",
            step: "month",
            stepmode: "backward",
          },
          {
            count: 1,
            label: "YTD",
            step: "year",
            stepmode: "todate",
          },
          {
            count: 1,
            label: "1y",
            step: "year",
            stepmode: "backward",
          },
          {
            step: "all",
          },
        ],
      },
      rangeslider: {
        visible: true,
      },
      type: "date",
    },
    yaxis: {
      title: "Value",
    },
  };

  return (
    <div className="flex justify-center w-full ml-5 ">
      <Plot className="w-4/5" data={renderDataSets()} layout={layout} />
    </div>
  );
};

export default TimeSeriesChartMultiLines;
