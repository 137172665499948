import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Spin, FloatButton } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {useAuth} from "../../contexts/AuthContext";
import useApi from "../../api/useApi";
import research from "../../api/research";
import InvestigationPreviewTemplate from "../../components/templates/InvestigationPreviewTemplate/InvestigationPreviewTemplate";



const ReviewInvestigation = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [idResearch, setIdResearch] = useState("");
  const getResearchById = useApi(research.getResearchMetaById);
  const updateResearch = useApi(research.updateResearchMeta);
  const { setMessage } = useAuth();

  useEffect(() => {
    if (params.key) {
      const auxId = params.key;
      setIdResearch(auxId);
      setLoading(true);
      getResearchById.request(auxId, true);
    }
  }, [params.key]);

  // eslint-disable-next-line no-unused-vars
  const handleApprove = () => {
    updateResearch.request(idResearch, { status: "published" });
    
  };

  const handleReject = () => {
    updateResearch.request(idResearch, { status: "rejected" });
    
  };

  useEffect(() => {
    if (updateResearch.data) {
      setMessage({
        type: "success",
        title: "Resultado",
        description: "La revision fue guardada exitosamente.",
      });
      updateResearch.setData();
    }
  }, [updateResearch.data]);

  useEffect(() => {
    if (updateResearch.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar guardar la revision de la investigación. Inténtelo de nuevo mas tarde.",
      });
    
    
      updateResearch.setData();
    }
  }, [updateResearch.error]);


  return !loading.data ? (
    <div className="min-h-screen flex flex-col pt-10 px-2 md:px-2">
      <div className="flex flex-col justify-center items-center ">
        <InvestigationPreviewTemplate />
      </div>
      <FloatButton
        shape="square"
        className="min-w-fit px-5 py-2 bg-white cursor-default left-1/2 -translate-x-1/2"
        style={{ insetInlineEnd: 24 }}
        description={
          <div className="flex flex-wrap md:flex-nowrap items-center md:space-x-2">
            <p> Revision de Investigación </p>
            <div
              className="bg-gray_custom text-white hover:shadow-xl shadow-none w-[110px] h-6 rounded"
              onClick={handleReject}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                }
              }}
              role="button"
              tabIndex={0}
            >
              <div className="flex items-center justify-center h-full">
                <CloseOutlined className="mr-1" />
                Rechazar
              </div>
            </div>
            <div
              className="bg-lightBlue2 text-white hover:shadow-xl shadow-none w-[110px] h-6 rounded"
              onClick={handleApprove}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                }
              }}
              role="button"
              tabIndex={0}
            >
              <div className="flex items-center justify-center h-full">
                <CheckOutlined className="mr-1" />
                Aprobar
              </div>
            </div>
            
          </div>
        }
      />
      {/* Botón flotante para aprobar */}
      {/* <Button
        type="primary"
        shape="circle"
        icon={<CheckOutlined />}
        size="large"
        onClick={handleApprove}
        className="fixed bottom-20 right-20 bg-green-500 hover:bg-green-700 text-white"
      /> */}

      {/* Botón flotante para rechazar */}
      {/* <Button
        type="primary"
        shape="circle"
        icon={<CloseOutlined />}
        size="large"
        onClick={handleReject}
        className="fixed bottom-20 right-36 bg-red-500 hover:bg-red-700 text-white"
      /> */}
    </div>
  ) : (
    <div className="flex h-96 justify-center items-center">
      <Spin />
    </div>
  );
};

export default ReviewInvestigation;
