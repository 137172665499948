/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from "react";
import { Button, Spin, Tooltip, Tabs } from "antd";
import {
  EditOutlined,
  // DownloadOutlined,
  DeleteOutlined,
  // PlayCircleOutlined,
  // PauseCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AppTitle from "../../components/atoms/Title/AppTitle";
import AppModal from "../../components/molecules/Modal/Modal";
import document from "../../api/document";
import research from "../../api/research";
import fetchingFiles from "../../api/fetchingFiles";
import useApi from "../../api/useApi";
import transformToDateFormat from "../../helpers/transformToDateFormat";
import convertDate from "../../helpers/dateUtil";
import ModalConfirm from "../../components/molecules/Modal/ModalConfirm";
import TableTemplate from "../../components/templates/TableTemplate";
// import downloadFile from "../../helpers/downloadFile";
import MapRequest from "../../api/MapRequest";
import ROUTES from "../../constant/router";
import { useAuth } from "../../contexts/AuthContext";
import InvestigationFormTemplate from "../../components/templates/DocumentTemplate/InvestigationFormTemplate";
// import transformToTreeSelect from "../../helpers/transformToTreeSelect";
import breakCellText from "../../helpers/breakCellText";
import ValidatePermissions from "../../helpers/ValidatePermissions";
import transformToTreeSelectRegions from "../../helpers/transformToTreeSelectRegions";
import { ResearchState } from "../../helpers/enums";

const AdminInvestigation = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const page_size = 10;
  const [loading, setLoading] = useState({
    general: false,
    list: false,
    description: false,
    descriptionModal: false,
    download: false,
    remove: false,
  });
  const [dataDescription, setDataDescription] = useState();
  const [modalActive, setModalActive] = useState(false);
  const [modalActiveConfirm, setModalActiveConfirm] = useState(false);
  const getAllResearch = useApi(research.getAllResearchMeta);
  const getResearch = useApi(research.getResearchMetaById);
  const createResearch = useApi(research.createResearchMeta);
  const downloadDocument = useApi(document.downloadDocument);
  const updateResearchMeta = useApi(research.updateResearchMeta);
  const deleteResearchMeta = useApi(research.updateResearchMeta);
  const uploadImageResearchMeta = useApi(document.uploadImageResearchMeta);
  const previousStateResearchMeta = useApi(document.previousStateResearchMeta);
  const getMunicipalityForRegion = useApi(MapRequest.getMunicipalities);
  const [data, setData] = useState();
  const { setMessage, currentUser } = useAuth();
  const [total, setTotal] = useState(0);
  const [action, setAction] = useState("");
  const [listMunicipalities, setListMunicipalities] = useState([]);
  const [idDocument, setIdDocument] = useState();
  // const [infoFile, setInfoFile] = useState({ name: null, extension: null });
  const [file, setFile] = useState(null);
  const [activeTab, setActiveTab] = useState("1");
  const [title, setTitle] = useState("Investigaciones Académicas");

  // eslint-disable-next-line no-unused-vars
  const items = [
    {
      key: "1",
      label: "Mis investigaciones",
      // children: rendeDocumentList("all-files"),
      // <DocumentList documentView={view} />,
      // <div className="shadow-body ml-[3px] relative top-[-1px]">
      //   <DocumentList documentView="all-files" reloadData={reloadListDocs} />
      // </div>
    },
    {
      key: "2",
      label: "Solicitudes de investigación",
      // children: rendeDocumentList("my-files"),
      // <DocumentList documentView={view} />,
      // <div className="shadow-body ml-[3px] relative top-[-1px]">
      //   <DocumentList documentView="my-files" reloadData={reloadListDocs} />
      // </div>
    },
  ];

  const navigate = useNavigate();

  const reloadListResearch = () => {
    if (activeTab === "1") {
      setTitle("Investigaciones Académicas");
      getAllResearch.request(page_size, currentPage);
    } else {
      getAllResearch.request(page_size, currentPage, "review");
      setTitle("Solicitudes de Investigación");
    }
  };

  useEffect(() => {
    // hacer la petición a la API para obtener los datos de la página
    reloadListResearch();
    // getMunicipalities(52);
    // getMunicipalityForRegion.request();
    getMunicipalityForRegion.request();
    setLoading((prev) => ({ ...prev, list: true }));
  }, [currentPage, activeTab]);

  const handlePageChange = (page) => {
    // hacer la petición a la API para obtener los datos de la página
    setCurrentPage(page);
  };

  const onDeleteResearch = (keyDoc) => {
    setLoading((prev) => ({ ...prev, list: true }));
    deleteResearchMeta.request(keyDoc, {
      state: "deleted",
      isDeleted: true,
    });
  };

  const onView = (key) => {
    // redirigir a la vista de la descripción de la investigación
    if (activeTab === "1") {
      const path = ROUTES.GENERAL.INVESTIGATION_INFO.replace(":key", key);
      navigate(path);
    } else {
      const path = ROUTES.GENERAL.INVESTIGATION_REVIEW.replace(":key", key);
      navigate(path);
    }
  };

  const onEdit = (key) => {
    getResearch.request(key);
    setIdDocument(key);
    setAction("edit");
    setLoading((prev) => ({
      ...prev,
      description: { ...prev.description, [key]: true },
    }));
  };

  const onClickRow = (obj) => {
    if (obj?.state !== "Suspendida") {
      // eslint-disable-next-line no-unused-vars
      const { _id } = obj;

      getResearch.request(_id);
      setLoading((prev) => ({
        ...prev,
        description: { ...prev.description, [_id]: true },
      }));
      setLoading((prev) => ({ ...prev, descriptionModal: true }));
      setIdDocument(_id);
      setAction("view");
    } else {
      setMessage({
        type: "info",
        title: "",
        description:
          "No puedes realizar ninguna acción mientras el estado de la investigación este en 'Suspendida'",
      });
    }
  };

  const closeModal = () => {
    setModalActive(false);
    setLoading((prev) => ({ ...prev, general: false }));
  };

  const confirmCloseModal = () => {
    setModalActive(false);
    setModalActiveConfirm(false);
    setLoading((prev) => ({ ...prev, general: false }));
  };

  const openModal = () => {
    setAction("create");
    setDataDescription();
    setLoading((prev) => ({ ...prev, general: true }));
    setModalActive(true);
  };

  const handlerSubmitDescription = (val) => {
    const auxDate = val?.date;
    const { upload, avatar, location, locationSubRegion, ...rest } = val;
    const startDate = transformToDateFormat(auxDate);
    const auxLocation = location || [];
    let auxVal = { ...rest, location: auxLocation, date: startDate };
    delete auxVal.imageUrl;
    if (action === "create") {
      if (upload) {
        const { name } = upload;
        const extension = `.${name.split(".").pop()}`; // Get the extension of the file
        auxVal = { ...auxVal, imageExtension: extension };
      }
      createResearch.request(auxVal);
      setFile(upload);
    } else {
      // action === "edit"
      updateResearchMeta.request(idDocument, auxVal);
      setFile(upload);
      setLoading((prev) => ({
        ...prev,
        description: { ...prev.description, [idDocument]: true },
      }));
    }
    setLoading((prev) => ({ ...prev, descriptionModal: true }));
    setLoading((prev) => ({ ...prev, list: true }));
  };

  const uploadImage = (url, img) => {
    const { name } = img;

    const extension = name.split(".").pop();

    const fileToUpload = {
      url, // The URL where the image should be uploaded
      file: {
        originFileObj: img.originFileObj, // The actual file object
        type: file.type || `image/${extension}`, // Set the content type (e.g., image/png)
      },
    };

    // Upload using fetchingFiles
    try {
      fetchingFiles(fileToUpload);
    } catch (error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar subir la imagen. Inténtelo de nuevo.",
      });
    }
  };

  useEffect(() => {
    if (getResearch.data) {
      const auxData = getResearch.data.data;
      if (action === "view") {
        onView(idDocument);
      } else if (action === "edit") {
        setModalActive(true);
        setDataDescription(auxData);
      }
      setLoading((prev) => ({ ...prev, descriptionModal: false }));
      setLoading((prev) => ({
        ...prev,
        description: { ...prev.description, [idDocument]: false },
      }));

      getResearch.setData();
    }
    if (getAllResearch.data) {
      const documents = getAllResearch.data.data.items;
      // const pagination = getAllResearch.data?.meta;
      const auxData = documents.map((item) => ({
        ...item,
        key: item.id,
        createdAt: convertDate(item.createdAt),
      }));
      setTotal(getAllResearch.data.data.total);
      setData(auxData);
      setLoading((prev) => ({ ...prev, list: false }));

      getAllResearch.setData();
    }

    // if (downloadDocument.data) {
    //   downloadFile(downloadDocument.data, infoFile.name, ".csv");
    //   setMessage({
    //     type: "success",
    //     title: "Resultado",
    //     description: "El archivo fue descargado exitosamente.",
    //   });
    //   setLoading((prev) => ({ ...prev, download: false }));
    //   downloadDocument.setData();
    // }
    if (updateResearchMeta.data) {
      if (file) {
        const auxData = updateResearchMeta.data.data;

        uploadImage(auxData.url, file);
      }
      setMessage({
        type: "success",
        title: "Resultado",
        description: "La investigación fue actualizada exitosamente.",
      });
      setModalActive(false);
      reloadListResearch();
      setLoading((prev) => ({ ...prev, descriptionModal: false }));
      setLoading((prev) => ({
        ...prev,
        description: { ...prev.description, [idDocument]: false },
      }));
      updateResearchMeta.setData();
    }
    if (deleteResearchMeta.data) {
      setMessage({
        type: "success",
        title: "Resultado",
        description: "La investigación fue eliminada exitosamente.",
      });
      reloadListResearch();
      deleteResearchMeta.setData();
    }
  }, [
    getAllResearch.data,
    getResearch.data,
    downloadDocument.data,
    updateResearchMeta.data,
    deleteResearchMeta.data,
  ]);

  useEffect(() => {
    // if (municipalities) {
    //   console.log("municipios",municipalities);
    //   const aux = transformToTreeSelect(
    //     municipalities,
    //     "municipio",
    //     "municipio"
    //   );
    //   console.log(aux);
    //   setListMunicipalities(aux);
    //   setDataMunicipalities();
    // }

    if (getMunicipalityForRegion.data) {
      const aux = transformToTreeSelectRegions(
        getMunicipalityForRegion.data.data,
        "subRegion",
        "subRegion",
        "Nariño",
        "municipalityName",
        "municipalityName"
      );
      setListMunicipalities(aux);
      getMunicipalityForRegion.setData();
    }

    if (previousStateResearchMeta.data) {
      setMessage({
        type: "success",
        title: "Resultado",
        description: "La investigación fue actualizada exitosamente.",
      });
      reloadListResearch();
      previousStateResearchMeta.setData();
    }
    if (createResearch.data) {
      const auxData = createResearch.data.data;
      if (file) {
        uploadImage(auxData.url, file);
      }
      setMessage({
        type: "success",
        title: "Resultado",
        description: "La investigación se ha creado correctamente",
      });
      setModalActive(false);
      reloadListResearch();
      setLoading((prev) => ({ ...prev, descriptionModal: false }));
      setLoading((prev) => ({ ...prev, general: false }));
      createResearch.setData();
    }
  }, [
    // municipalities,
    getMunicipalityForRegion.data,
    createResearch.data,
    previousStateResearchMeta.data,
  ]);

  useEffect(() => {
    if (getResearch.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar obtener los datos. Vuelva a intentarlo.",
      });
      setLoading((prev) => ({ ...prev, descriptionModal: false }));
      setLoading((prev) => ({
        ...prev,
        description: { ...prev.description, [idDocument]: false },
      }));
    }
    if (getAllResearch.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar obtener los datos. Vuelva a intentarlo.",
      });
      setLoading((prev) => ({ ...prev, list: false }));
    }
    if (createResearch.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar guardar la descripción. Por favor inténtelo nuevamente.",
      });
      setLoading((prev) => ({ ...prev, descriptionModal: false }));
      setLoading((prev) => ({
        ...prev,
        description: { ...prev.description, [idDocument]: false },
      }));
    }
    if (downloadDocument.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar descargar el archivo. Inténtelo de nuevo mas tarde.",
        code: downloadDocument.error,
      });
      setLoading((prev) => ({ ...prev, download: false }));
    }
    if (updateResearchMeta.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar actualizar la investigación. Inténtelo de nuevo mas tarde.",
      });
      setLoading((prev) => ({ ...prev, descriptionModal: false }));
      setLoading((prev) => ({
        ...prev,
        description: { ...prev.description, [idDocument]: false },
      }));
      updateResearchMeta.setData();
    }
    if (uploadImageResearchMeta.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar subir la imagen. Inténtelo de nuevo.",
      });
      uploadImageResearchMeta.setData();
    }
    if (previousStateResearchMeta.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar subir actualizar el estado. Inténtelo de nuevo.",
        code: previousStateResearchMeta.error,
      });
      previousStateResearchMeta.setData();
    }
    if (getMunicipalityForRegion.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar obtener los municipios. Inténtelo de nuevo.",
      });
      getMunicipalityForRegion.setData();
    }
    if (deleteResearchMeta.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar eliminar la investigación. Inténtelo de nuevo.",
      });
      setLoading((prev) => ({ ...prev, list: false }));
      deleteResearchMeta.setData();
    }
  }, [
    getResearch.error,
    createResearch.error,
    getAllResearch.error,
    downloadDocument.error,
    updateResearchMeta.error,
    uploadImageResearchMeta.error,
    previousStateResearchMeta.error,
    getMunicipalityForRegion.error,
    deleteResearchMeta.error,
  ]);

  // const renderIcon = (record) => {
  //   if (!loading.remove[record.key]) {
  //     if (currentUser.role === "Administrador") {
  //       if (record.state === "Suspendida") return <PlayCircleOutlined />;
  //       return <PauseCircleOutlined />;
  //     }
  //     return <DeleteOutlined />;
  //   }
  //   return <Spin />;
  // };

  // const   onChangeState = (record) => {
  //   if (currentUser.role === "Administrador") {
  //     if (record.state === "Suspendida") {
  //       previousStateResearchMeta.request(record.key);
  //     } else {
  //       updateResearchMeta.request(record.key, { state: "Suspendida" });
  //     }
  //   } else if (currentUser.role === "Investigador") {
  //     updateResearchMeta.request(record.key, {
  //       state: "Eliminada",
  //       isDeleted: true,
  //     });
  //   }
  // };

  const onTabChange = (key) => {
    setActiveTab(key);
    setCurrentPage(1); // Resetear a la primera página al cambiar de tab
  };
  // Las columnas de la tabla
  const columns = [
    {
      title: <span className="font-medium text-center text-xl ">Código</span>,
      dataIndex: "code",
      key: "code",
      align: "center",
      render: (text) => (
        <span className="  text-center text-sizeBase text-black_custom ">
          {text}
        </span>
      ),
      className: "border-b-0",
      width: 150,
      sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: <span className="font-medium text-center text-xl ">Título</span>,
      dataIndex: "title",
      key: "title",
      align: "center",
      render: (text) => (
        <span className="  text-center text-sizeBase text-black_custom ">
          {text}
        </span>
      ),
      className: "border-b-0",
      width: 250,

      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: <span className="font-medium text-center text-xl">Autor</span>,
      dataIndex: "author",
      key: "author",
      align: "center",
      render: (text) => (
        <span className="rounded-md text-center text-black_custom">{text}</span>
      ),
      width: 150,
      className: "border-b-0",
      sorter: (a, b) => a.author.localeCompare(b.author),
    },
    {
      title: <span className="font-medium text-center text-xl">Resumen</span>,
      dataIndex: "abstract",
      key: "abstract",
      align: "center",
      width: 300,
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          <span className="rounded-md text-center text-black_custom">
            {breakCellText(text, 100)}
          </span>
        </Tooltip>
      ),
      className: "border-b-0",
    },
    {
      title: <span className="font-medium text-center text-xl">Estado</span>,
      dataIndex: "state",
      key: "state",
      align: "center",
      render: (text) => (
        <span className="rounded-md text-center text-black_custom">
          {ResearchState[text]} {/* Aquí traducimos el estado a español */}
        </span>
      ),
      className: "border-b-0",
      filters: [
        {
          text: "En Creación",
          value: "created", // Utilizamos el valor en inglés
        },
        {
          text: "En Revisión",
          value: "review",
        },
        {
          text: "Publicada",
          value: "published",
        },
        {
          text: "Suspendida",
          value: "suspended",
        },
        {
          text: "Eliminada",
          value: "deleted",
        },
        {
          text: "En Edición",
          value: "draft",
        },
      ],
      filterSearch: true,
      onFilter: (value, record) => record.state === value, // Compara el estado en inglés
    },
    {
      title: (
        <span className="font-medium text-center text-xl">
          Fecha de creación
        </span>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text) => (
        <span className=" rounded-md text-center text-black_custom ">
          {text}
        </span>
      ),
      className: "border-b-0",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: <span className="font-medium text-center text-xl ">Acciones</span>,
      key: "acciones",
      align: "center",
      ellipsis: true,
      render: (text, record) => (
        <div className="flex space-x-2 justify-center  ">
          <ValidatePermissions
            container={false}
            func="actionsTable"
            permission={["edit", "pause", "download"]}
            modulePermission="research"
          >
            <Button
              className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none "
              icon={
                !loading.description[record._id] ? <EditOutlined /> : <Spin />
              }
              onClick={(e) => {
                onEdit(record._id);
                e.stopPropagation();
              }}
              disabled={record.state === "Suspendida"}
            />
            {/* <Button
              className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none "
              icon={renderIcon(record)}
              onClick={(e) => {
                onChangeState(record);
                e.stopPropagation();
              }}
            /> */}
            <Button
              className="text-lightBlue2 shadow-xl  w-12 hover:text-lightBlue hover:shadow-none "
              icon={
                !loading.download[record._id] ? <DeleteOutlined /> : <Spin />
              }
              onClick={(e) => {
                onDeleteResearch(record._id);
                e.stopPropagation();
              }}
              // disabled={record.state === "Suspendida"}
            />
          </ValidatePermissions>
        </div>
      ),
      width: 150,
      className: "border-b-0",
    },
  ];

  const renderModal = () => {
    if (modalActive) {
      return (
        <AppModal
          open={modalActive}
          onCancel={closeModal}
          footer={false}
          className=" text-lightBlue2 text-lg text-center w-full lg:w-1/2"
        >
          <InvestigationFormTemplate
            documentKey={1}
            action={action}
            dataDescription={dataDescription}
            handlerSubmit={handlerSubmitDescription}
            loading={loading.descriptionModal}
            listMunicipalities={listMunicipalities}
            hasTitle
          />
        </AppModal>
      );
    }
    return null;
  };

  return (
    <div className="min-h-screen bg-white_custom flex flex-col items-center pt-10 ">
      <div className="w-10/12 mb-2 grid justify-items-end ">
        <ValidatePermissions
          func="oneButton"
          container={false}
          permission="create"
          modulePermission="research"
        >
          <Button
            shape="round"
            size="large"
            type="primary"
            className="font-Poppins shadow-lg rounded-full bg-lightBlue text-white_custom"
            loading={loading.general}
            onClick={openModal}
          >
            Crear nueva investigación académica
          </Button>
        </ValidatePermissions>
      </div>
      {(currentUser.role === "Administrador" ||
        currentUser.role === "Super Administrador") && (
        <div className="w-10/12  grid justify-items-start ">
          <Tabs
            type="card"
            activeKey={activeTab}
            onChange={onTabChange}
            items={items}
          />
        </div>
      )}
      <div className=" w-11/12 bg-white_custom p-6 rounded-[45px] border border-colorBorder/25 shadow-2xl">
        <div className="flex justify-center items-center mb-4">
          <AppTitle
            title={title}
            level={4}
            className="text-lightBlue2 font-bold  text-center !important"
          />
        </div>
        {!loading.list ? (
          <TableTemplate
            enableScroll
            data={data}
            handlePageChange={handlePageChange}
            total={total}
            columns={columns}
            page_size={page_size}
            currentPage={currentPage}
            className=" "
            onSelectRow={onClickRow}
            isClickRowActive
          />
        ) : (
          <div className="flex justify-center items-center h-56">
            <Spin />
          </div>
        )}
        {renderModal()}
      </div>

      <ModalConfirm
        centered
        okText="Aceptar"
        handleOK={confirmCloseModal}
        closeModal={() => setModalActiveConfirm(false)}
        modalActive={modalActiveConfirm}
        text="¿Estas seguro que deseas eliminar la investigación?"
      />
    </div>
  );
};
export default AdminInvestigation;
