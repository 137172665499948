import React from "react";
import { Form } from "antd";
import AppMultiSelect from "../../atoms/MultiSelect/AppMultiSelect";
/* eslint-disable react/jsx-props-no-spreading */

const FormHomeEvent = ({ className = "", name, label,violenceList, onSelectEvent, onChange,...props }) => {
  const combinedClassName = `font-Poppins text-black_custom formhome ${className}`;
  
  return (
    <Form.Item
      name={name}
      label={label}
      {...props}
      className={combinedClassName}
      // rules={[
      //   {
      //     required: true,
      //     message: "Por favor seleccione una opción!",
      //   },
      // ]}
      rules={[
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (value || getFieldValue("health")) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('Debes seleccionar al menos un evento de "violencia" o "salud"'));
          },
        }),
      ]}
    >
      <AppMultiSelect 
        onSelectOption={onSelectEvent}
        maxTagCount={2}
        maxTagTextLength={10} 
        options={violenceList} 
        placeholder="Selecciona una opción" 
        className="rounded-full text-white_custom"  
        onChange={onChange}
        labelInValue
      />
    </Form.Item>

  );
}

export default FormHomeEvent;