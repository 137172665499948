const TrashIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1021"
    height="947"
    viewBox="0 0 1021 947"
  >
    <defs>
      <clipPath id="clip-icono">
        <rect width="1021" height="947" />
      </clipPath>
    </defs>
    <g id="icono" clipPath="url(#clip-icono)">
      <g
        id="Grupo_559"
        className="Grupo 559"
        transform="translate(-4007.875 18.174)"
      >
        <path
          id="Rectángulo_508"
          className="Rectángulo 508"
          d="M0,0H497a0,0,0,0,1,0,0V549a54,54,0,0,1-54,54H54A54,54,0,0,1,0,549V0A0,0,0,0,1,0,0Z"
          transform="translate(4276.31 236.653)"
          fill="none"
          stroke="current"
          strokeMiterlimit="10"
          strokeWidth="17"
        />
        <rect
          id="Rectángulo_509"
          className="Rectángulo 509"
          width="615.699"
          height="102.617"
          rx="47.5"
          transform="translate(4210.875 131.962)"
          fill="none"
          stroke="current"
          strokeMiterlimit="10"
          strokeWidth="17"
        />
        <rect
          id="Rectángulo_510"
          className="Rectángulo 510"
          width="208.813"
          height="63.216"
          rx="31.608"
          transform="translate(4418.604 66)"
          fill="none"
          stroke="current"
          strokeMiterlimit="10"
          strokeWidth="17"
        />
        <g
          id="Grupo_558"
          className="Grupo 558"
          transform="translate(4417.732 343.149)"
        >
          <line
            id="Línea_95"
            className="Línea 95"
            y2="357"
            transform="translate(-0.422 -0.497)"
            fill="none"
            stroke="current"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="17"
          />
          <line
            id="Línea_96"
            className="Línea 96"
            y2="357"
            transform="translate(106.578 -0.497)"
            fill="none"
            stroke="current"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="17"
          />
          <line
            id="Línea_97"
            className="Línea 97"
            y2="357"
            transform="translate(212.578 -0.497)"
            fill="none"
            stroke="current"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="17"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default TrashIcon