// MapCard.js
import React from "react";
import { Row, Card } from "antd";
import Map from "./Map";

const MapCard = ({ data, height }) => {
  const initialLng = -77.269577;
  const initialLat = 1.258999;
  const initialZoom = 7;

  return (
    <Row>
      <Card
        bordered
        className="my-10 shadow-md w-full sm:mx-0 md:mx-10 lg:mx-10 xl:mx-10 2xl:mx-10 pt-0"
        style={{ height: "100%" }} // Asegura que el card llene el contenedor
      >
        <Map data={data} initialLng={initialLng} initialLat={initialLat} initialZoom={initialZoom} height={height} />
      </Card>
    </Row>
  );
};

export default MapCard;
