import { useAuth } from "../contexts/AuthContext";
import findElementInArray from "./findElementInArray";

const ValidatePermissionUserOwner = ({
  children,
  modulePermission,
  func = "actionsTable",
  container = true,
  permission = "",
  emailCreatorFile, // Añadir un nuevo parámetro para el id del creador del archivo
}) => {
  if (!children) return null;
  const { permissions, currentUser } = useAuth();
  // if (!emailCreatorFile) return null;
  if (!currentUser) return null;
 

 
  const validateActions = () => {
    if (func === "actionsTable") {

      if (Array.isArray(permission)) {
        const arrayHasPermission = [];
        permission.forEach((item) => {

          
          if (item !== "download") {
            arrayHasPermission.push(
              ((findElementInArray(permissions, `${modulePermission}${item}`)
                && currentUser.email === emailCreatorFile) || false)
            );

          } else {
            arrayHasPermission.push(
              findElementInArray(permissions, `${modulePermission}${item}`)
            );
          }

        });
        return arrayHasPermission.map((bool, i) => {
          if (bool) return children[i]
       
          return []
        });
      }
     
      return [];

    }
    
    return [];
  };
  return container ? (
    <div className="flex" style={{ justifyContent: "space-evenly" }}>
      {validateActions()}
    </div>
  ) : (
    validateActions()
  );

};

const IsOwner = (idUser) => {
  const { currentUser } = useAuth();
  return currentUser.sub === idUser;
}

export default { ValidatePermissionUserOwner, IsOwner };