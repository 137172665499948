import React from "react";
import { Radio } from "antd";
import PropTypes from "prop-types";
/* eslint-disable react/jsx-props-no-spreading */

function AppGroupRadio({  options, initialValue, className = "", ...props }) {
  const combinedClassName = `font-Poppins font-light text-color_text ${className} !important`;
  return (
    <Radio.Group  defaultValue={initialValue} className={combinedClassName} {...props} >
      {options.map((option) => (
        <div  key={option.id} className="flex flex-col">
          <Radio key={option.id} value={option.value} className="font-Poppins text-black_custom font-light text-sizeBase !important">
            {option.label}
          </Radio>
        </div>
      ))}
    </Radio.Group>
  )
}

AppGroupRadio.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
};

export default AppGroupRadio;