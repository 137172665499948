const formatSize = (size) => {
  const isNumber = (value) => typeof value === "number" && Number.isFinite(value);
  const rounded = (number) => Math.round(number * 100) / 100;

  if (isNumber(size)) {
    const sizeKB = size / 1024;
    let auxSize;
    if (sizeKB < 1024) {
      auxSize = `${rounded(sizeKB)} KB`;
    } else if (sizeKB < 1048576) {
      auxSize = `${rounded(sizeKB / 1024)} MB`;
    } else {
      auxSize = `${rounded(sizeKB / 1048576)} GB`;
    }
    return auxSize;
  }
  return "";
};

export default formatSize;
