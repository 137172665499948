import axios from "axios";
import Config from "../config";

const instance = axios.create({
  baseURL: `${Config.urlETL}`,
  timeout: Config.timeOut,
  headers: {
    Accept: "application/json; charset=utf-8",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const auxToken = Config.tokenETL
    config.headers.setAuthorization(`Bearer ${auxToken}`, true);
    return config;
  },
  (error) => error
);


export default instance;
