// App.js
import { Form } from "antd";
import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import AppTitle from "../../atoms/Title/AppTitle";
import ColumnForm from "../../organisms/DocumentForm/ColumnForm";
import SubmitButton from "../../atoms/Buttons/SubmitButton";
// import Step from "../../molecules/Step/Step";

const ColumnTemplate = ({
  listTypeData,
  dataDesColumn,
  loading,
  onHandleSubmit,
  multipleEdit,
}) => {
  // const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  // const navigate = useNavigate();
  const [title, setTitle] = useState("Editar Columna");
  // Las columnas de la tabla

  const onSubmit = (values) => {
    form.resetFields();
    onHandleSubmit(values);
  };

  useEffect(() => {
    if (dataDesColumn) {
      setTitle(`Editar Columna ${!multipleEdit ? dataDesColumn.name : ""}`);
      form.setFieldsValue({
        column: dataDesColumn?.name,
        dataType: dataDesColumn?.dataType,
        description: dataDesColumn?.description,
      });
    }
  }, [dataDesColumn]);

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      className="font-Poppins "
      scrollToFirstError
    >
      <div className=" flex flex-col items-center ">
        <div className="p-2">
          <div className=" mb-2 grid justify-items-center pt-4 ">
            <AppTitle
              title={title}
              level={4}
              className="font-bold text-lightBlue"
            />
          </div>
          <div className="flex justify-center items-center mb-2">
            <ColumnForm
              listTypeData={listTypeData}
              multipleEdit={multipleEdit}
            />
          </div>
          <Form.Item>
            <div className="flex justify-center text-center pt-2">
              <SubmitButton
                nameButton="Guardar"
                loading={loading}
                form={form}
                htmlType="submit"
              />
            </div>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};
export default ColumnTemplate;
