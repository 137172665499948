import { Layout } from "antd";
import TabsDocument from "./TabsDocument";

const { Content } = Layout;

const Document = () => (
  <Layout>
    <Content>
      <TabsDocument />
      {/* <DocumentListTemplate dataSource ={dataSource} dataInitial={dataInitial}/> */}
    </Content>
  </Layout>
);

export default Document;
