const transformToTreeSelectRegions = (array, labelID, valueID, titleFather = "Nariño",labelIDChildren="name",valueIDChildren="name") => {
  if (!Array.isArray(array)) return "";
  const newArray = array.map((item) => {
    const newValue = item[valueID] || "";
    const newLabel = item[labelID] || "";
    const { municipalities } = item;
    let newArrayChildren = [];
    if (Array.isArray(municipalities)) {
      newArrayChildren = municipalities.map(municipality => ({
        title: municipality[labelIDChildren],
        key: municipality[valueIDChildren],
        value: municipality[valueIDChildren],

      }))
    }
    return {
      title: newLabel,
      key: newLabel,
      value: newValue,
      children: newArrayChildren,
    };
  });

  return [
    {
      title: titleFather,
      value: "all",
      key: "all",
      children: newArray,
    },
  ];
};

export default transformToTreeSelectRegions;
