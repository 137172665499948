import React, { useEffect, useRef } from "react";
import Plot from "react-plotly.js";
import Plotly from "plotly.js-dist-min";
import Colors from "../../../utils/ColorsPage.json";
import useImageChart from "../../../hooks/useImageChart";

const TimeSeriesChartLine = ({
  axisX,
  axisY,
  name = "",
  title,
  iteration = 0,
  height = "100%",
  bgColorChart = "#FFFFFF",
  titleX,
  titleY,
  isInvestigation = false,
}) => {
  const chartRef = useRef(null);
  const plotRef = useRef(null);

  useEffect(() => {
    // Check if the element is in the DOM and displayed
    const resizePlot = () => {
      if (plotRef.current && plotRef.current.offsetParent !== null) {
        Plotly.Plots.resize(plotRef.current);
      }
    };

    // Set initial resize with a small delay
    const initialResizeTimeout = setTimeout(resizePlot, 100);

    // Observe resizing of the chart container
    const resizeObserver = new ResizeObserver(resizePlot);
    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    // Clean up the observer and timeout on component unmount
    return () => {
      clearTimeout(initialResizeTimeout);
      resizeObserver.disconnect();
    };
  }, []);

  const returnLayout = () => {
    let layout = {
      title,
      plot_bgcolor: bgColorChart,
      paper_bgcolor: bgColorChart,
      xaxis: { title: titleX },
      yaxis: { title: titleY },
    };
    if (Array.isArray(axisX)) {
      const auxValue = Number(axisX[0]);
      if (Number.isNaN(auxValue)) {
        layout = {
          ...layout,
          xaxis: {
            title: titleX,
            rangeselector: {
              buttons: [
                {
                  count: 1,
                  label: "1 mes",
                  step: "month",
                  stepmode: "backward",
                },
                {
                  count: 6,
                  label: "6 meses",
                  step: "month",
                  stepmode: "backward",
                },
                {
                  count: 1,
                  label: "1 año",
                  step: "year",
                  stepmode: "todate",
                },
                {
                  label: "Todos",
                  step: "all",
                },
              ],
            },
            rangeslider: {
              visible: true,
            },
            type: "date",
          },
        };
      }
    }
    return layout;
  };

  if (!isInvestigation) useImageChart({ plotRef, chartType: "line" });

  return (
    <div ref={chartRef} style={{ width: "100%", height }}>
      <Plot
        ref={plotRef}
        data={[
          {
            x: axisX,
            y: axisY,
            type: "line",
            mode: "lines+markers",
            name,
            line: {
              color: Colors[iteration],
            },
          },
        ]}
        layout={returnLayout()}
        useResizeHandler
        style={{ width: "100%", height: "100%" }}
        onInitialized={(figure, graphDiv) => {
          plotRef.current = graphDiv; // Set plotRef once Plotly plot is initialized
        }}
      />
    </div>
  );
};

export default TimeSeriesChartLine;
