/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Form, Button } from "antd";

export default function SubmitButton({
  form,
  onClick,
  loading,
  nameButton = "Guardar",
  className,
  buttonColor = "bg-lightBlue2",
  htmlType = "",
  doubleCheckValidation = true,
}) {
  const [submittable, setSubmittable] = React.useState(false);

  // Watch all values
  const values = Form.useWatch([], form);
  React.useEffect(() => {
    if (values) {
      form
        .validateFields({
          validateOnly: true,
        })
        .then(
          () => {
            setSubmittable(true);
          },
          () => {
            setSubmittable(false);
          }
        );
    } else {
      setSubmittable(true);
    }
  }, [values]);
  return (
    <Button
      shape="round"
      htmlType={htmlType}
      size="large"
      type="primary"
      className={`font-Poppins font-normal shadow-lg rounded-full min-w-28 ${!submittable || !doubleCheckValidation ? "bg-dark_gray" : buttonColor} text-white_custom ${className}`}
      onClick={onClick}
      loading={loading}
      disabled={doubleCheckValidation ? !submittable : !doubleCheckValidation}
    >
      {nameButton}
    </Button>
  );
}
