/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { decodeJWT, resetPassword, signIn, signOut } from "@aws-amplify/auth";
import PropTypes from "prop-types";
import useApi from "../api/useApi";
import token from "../api/token";
import user from "../api/user";
import { USERS_STATUS_NOT_ALLOWED } from "../helpers/enums";
import auth from "../api/auth";

const AuthContext = createContext();
export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [emailRecovery, setEmailRecovery] = useState();
  const [message, setMessage] = useState("");
  const [permissions, setPermissions] = useState([]);
  const getInfoUser = useApi(user.getInfoUser);
  const getPermissions = useApi(auth.getPermissions);

  const login = async (username, password) => {
    try {
      return await signIn({ username, password });
    } catch (error) {
      if (error.toString().includes("There is already a signed in user")) {
        setMessage({
          type: "error",
          title: "Error",
          description:
            "Ya existe una sesión iniciada con esta cuenta. Se debe cerrar sesión desde el navegador que se inicio.",
        });
      } else if (error.toString().includes("Incorrect username or password")) {
        setMessage({
          type: "error",
          title: "Error",
          description: "El correo o la contraseña son incorrectos.",
        });
      } else {
        setMessage({
          type: "error",
          title: "Error",
          description:
            "Hubo un error al intentar iniciar sesión. Vuelva a intentarlo más tarde.",
        });
      }
    }
    return "error";
  };

  const logout = async () => {
    try {
      await signOut();
      setMessage({
        type: "warning",
        title: "Sesión terminada",
        description: "Su sesión ha finalizado.",
        duration: 2,
      });
      setCurrentUser();
    } catch (er) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar cerrar sesión. Vuelva a intentarlo más tarde.",
      });
    }
  };

  const handleAllowedUser = useCallback(async () => {
    await logout();
    sessionStorage.clear();
    localStorage.clear();
    setMessage({
      type: "info",
      title: "Error",
      description:
        "Tu usuario no se encuentra activo. Por favor, contacta con el administrador.",
    });
  }, [getInfoUser]);
  useEffect(() => {
    if (getInfoUser.data) {
      const userFound = getInfoUser.data.data;
      const { sub, email, first_name, last_name, role, status } = userFound;
      if (USERS_STATUS_NOT_ALLOWED.includes(status)) {
        handleAllowedUser();
      }
      setCurrentUser({
        sub,
        email,
        firstName: first_name,
        lastName: last_name,
        role,
        status,
      });
      sessionStorage.setItem("firstName", first_name);
      sessionStorage.setItem("lastName", last_name);
      sessionStorage.setItem("role", role);
      sessionStorage.setItem("email", email);
      sessionStorage.setItem("sub", sub);
    }
    if (
      getPermissions.data &&
      getPermissions.data.data &&
      getPermissions.data.data.permissions
    ) {
      let auxPermissions = getPermissions.data.data.permissions.permissions;
      auxPermissions = auxPermissions.map((item) => item.action);
      setPermissions(auxPermissions);
      sessionStorage.setItem("permissions", auxPermissions);
    }
  }, [getInfoUser.data, getPermissions.data]);

  function refreshDataUser() {
    const firstName = sessionStorage.getItem("firstName");
    const lastName = sessionStorage.getItem("lastName");
    const role = sessionStorage.getItem("role");
    const email = sessionStorage.getItem("email");
    const sub = sessionStorage.getItem("sub");
    const auxPermissions = sessionStorage.getItem("permissions");

    if (firstName && lastName && role && email && sub && auxPermissions) {
      setCurrentUser({ firstName, lastName, role, email, sub });
      const auxPerm = auxPermissions.split(",")
      setPermissions(auxPerm)
    } else {
      const accessToken = token.getToken();
      if (accessToken) {
        const tokenDecode = decodeJWT(accessToken);
        const subUser = tokenDecode.payload?.sub;
        getInfoUser.request(subUser);
        getPermissions.request();
      }
    }
  }

  const handleResetPasswordNextSteps = (output, email) => {
    const { nextStep } = output;
    let route = "";
    switch (nextStep.resetPasswordStep) {
      case "CONFIRM_RESET_PASSWORD_WITH_CODE":
        setEmailRecovery(email);
        setMessage({
          type: "info",
          title: "Resultado",
          description:
            "Se enviara un código al correo proporcionado, recuerda haber confirmado tú correo al momento de hacer el registro, de lo contrario, no será posible recuperar la contraseña.",
          // "Si este correo esta confirmado se enviara un código, de lo contrario, confirma tú correo sino no te podemos enviar el código.",
          duration: 6,
        });
        route = "/recovery_password";
        break;
      case "DONE":
        route = "/login";
        setMessage({
          type: "success",
          title: "Resultado",
          description:
            "Tu contraseña se ha cambiado correctamente. Ya puedes iniciar sesión con tus nuevas credenciales.",
          duration: 6,
        });
        break;
      default:
        setMessage({
          type: "error",
          title: "Error",
          description:
            "Hubo un error al enviar el código. Vuelva a intentarlo de nuevo.",
        });
        break;
    }
    return route;
  };

  const handleResetPassword = async (email) => {
    try {
      const output = await resetPassword({ username: email });
      return handleResetPasswordNextSteps(output, email);
    } catch (error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al enviar el código. Vuelva a intentarlo de nuevo.",
      });
    }
    return "";
  };

  /*   function updateUserProfile(user, profile) {
    return updateProfile(user, profile);
  } */

  /*   useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []); */

  const value = useMemo(
    () => ({
      currentUser,
      setCurrentUser,
      emailRecovery,
      setEmailRecovery,
      login,
      logout,
      // register,
      refreshDataUser,
      message,
      setMessage,
      permissions,
      setPermissions,
      handleResetPasswordNextSteps,
      handleResetPassword,
      // updateUserProfile,
    }),
    [
      currentUser,
      setCurrentUser,
      emailRecovery,
      setEmailRecovery,
      login,
      logout,
      // register,
      refreshDataUser,
      message,
      setMessage,
      permissions,
      setPermissions,
      handleResetPasswordNextSteps,
      handleResetPassword,
      // updateUserProfile,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
