import { Card, Image } from "antd";


// eslint-disable-next-line arrow-body-style
const CardImg = ({ url, id, alignment = "left" }) => {
  // Definir las clases de alineación según la propiedad alignment
  const alignmentClasses = {
    center: "mx-auto",
    left: "ml-0",
    right: "ml-auto mr-0",
  };

  // Clase de alineación por defecto si la alineación proporcionada no es válida
  const alignmentClass = alignmentClasses[alignment] || alignmentClasses.left;

  return (
    <Card
      className={`bg-white !rounded-large p-4 ${alignmentClass}`}
      key={id}
      styles={{ body: { padding: 0 } }} // Remover padding interno del body de la tarjeta
    >
      <div className="flex justify-center items-center h-full w-full">
        <Image
          src={url}
          alt={`Imagen ${id}`}
          className="object-cover"
          width="100%"
          height="100%"
          // preview={false} // Desactivar el preview si no se desea
        />
      </div>
    </Card>
  );
};

export default CardImg;
