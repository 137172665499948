const transformToSelectArray = (array, labelID, valueID , valueIsObject = false) => {
  if (!Array.isArray(array)) return "";
  if (Array.isArray(labelID)) {
    const newArray = array.map((item) => {
      const newItem = item[valueID];
      const objKey = labelID.find((val) => item[val]);
      return {
        label: item[objKey] || "",
        value: newItem || "",
      };
    });
    return newArray;
  }
  if (!labelID && !valueID) return array.map((item) => ({ label: item, value: item }));
  if (valueIsObject) return array.map((item) => ({ label: item[labelID], value: `${item[labelID]}*${item[valueID]}` }));
  const newArray = array.map((item) => {
    let newItem = item[valueID];
    let newLabel = item[labelID];
    if (valueID === "c_digo_dane_del_municipio") {
      newItem = item[valueID].replace(".", "");
    }
    if(labelID.includes("joinValues")){
      const auxSplit = labelID.split("-")[1]
      const auxSplitLabel = auxSplit.split(",") 
      newLabel = `${item[auxSplitLabel[0]]} ${item[auxSplitLabel[1]]} (${item[auxSplitLabel[2]]})`
    }
    return {
      label: newLabel || "",
      value: newItem || "",
    };
  });
  return newArray;
};

export default transformToSelectArray;
