const ItemButton = ({ className, text, onClick, disabled }) => (
  <button
    className={`bg-aquamarineBlue text-white_custom rounded-full px-2 ${className}`}
    type="button"
    onClick={onClick}
    disabled={disabled}
  >
    {text}
  </button>
);

export default ItemButton;
