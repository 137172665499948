/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import Card from "../../atoms/Card/Card";
import AppTitle from "../../atoms/Title/AppTitle";
import useApi from "../../../api/useApi";
import TableTemplate from "../TableTemplate";
import { useAuth } from "../../../contexts/AuthContext";
import document from "../../../api/document";
import Flecha from "../../../assets/image/flecha.svg";
import { addLevels } from "../../../helpers/depthArray";
import RenderCards from "../../molecules/CardsTaxonomies/RenderCards";
import indicator from "../../../api/indicator";

const DocumentIndex = () => {
  const navigate = useNavigate();
  const getIndicatorGroups = useApi(indicator.getIndicatorGroups);
  const getDocumentsIndex = useApi(document.getAllDocuments);
  const [listGroupIndicator, setListGroupIndicator] = useState([]);
  const [loading, setLoading] = useState({
    taxonomies: false,
    dataIndicator: false,
    calculation: false,
    priority: false,
  });
  const [selected, setSelected] = useState({
    group: false,
    indicator: false,
  });
  const [idIndicator, setIdIndicator] = useState(null);
  const [titleIndicator, setTitleIndicator] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [metaPagination, setMetaPagination] = useState({
    total: 10,
    page: 1,
    page_size: 10,
  });
  const { setMessage } = useAuth();

  const columns = [
    {
      title: (
        <div className="font-medium text-center text-base">
          Código del archivo
        </div>
      ),
      dataIndex: "code",
      key: "code",
      align: "center",
      render: (text) => (
        <div className="rounded-md text-center text-black_custom">
          {text && text.slice(-6)}
        </div>
      ),
      className: " ",
    },
    {
      title: (
        <div className="font-medium text-center text-base">
          Nombre del archivo
        </div>
      ),
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text) => (
        <div className="rounded-md text-center text-black_custom">{text}</div>
      ),
      className: " ",
    },
    {
      title: <div className="font-medium text-center text-base">Link</div>,
      dataIndex: "_id",
      key: "_id",
      align: "center",
      render: (text) => (
        <button
          type="button"
          className="underline text-lightBlue2"
          onClick={() => navigate(`/document/${text}`)}
        >
          Link del archivo
        </button>
      ),
      className: " ",
    },
    {
      title: (
        <div className="font-medium text-center text-base">Propietario</div>
      ),
      dataIndex: "userDetails",
      key: "userDetails",
      align: "center",
      render: (value) => (
        <div className="rounded-md text-center text-black_custom">{`${value?.firstName} ${value?.lastName}`}</div>
      ),
      className: " ",
    },
  ];

  const handlePageChange = (page) =>
    setMetaPagination((prev) => ({ ...prev, page }));

  useEffect(() => {
    if (idIndicator) {
      getDocumentsIndex.request(
        metaPagination.page_size,
        metaPagination.page,
        null,
        null,
        idIndicator
      );
    }
  }, [metaPagination.page, idIndicator]);

  useEffect(() => {
    setLoading((prev) => ({ ...prev, taxonomies: true }));
    getIndicatorGroups.request();
  }, []);

  useEffect(() => {
    if (getIndicatorGroups.data) {
      setLoading((prev) => ({ ...prev, taxonomies: false }));
      const auxTree = getIndicatorGroups.data?.data;
      const auxAddingLevels = addLevels(auxTree);
      const sortedIndicator = [...auxAddingLevels].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      // console.log("🚀 ~ useEffect ~ sortedIndicator:", sortedIndicator);
      setListGroupIndicator(sortedIndicator);
      getIndicatorGroups.setData();
    }
    if (getDocumentsIndex.data) {
      const dataIndicator = getDocumentsIndex.data.data;
      setLoading((prev) => ({ ...prev, dataIndicator: false }));
      const dataTable = dataIndicator.items || [];
      setDataSource(dataTable);
      const { page, page_size, total } = dataIndicator;
      setMetaPagination({
        page,
        page_size,
        total,
      });
      getDocumentsIndex.setData();
    }
  }, [getIndicatorGroups.data, getDocumentsIndex.data]);

  useEffect(() => {
    if (getIndicatorGroups.error) {
      setLoading((prev) => ({ ...prev, taxonomies: false }));
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al obtener los indicadores. Por favor recargue la pagina.",
        code: getIndicatorGroups.error,
      });
    }
    if (getDocumentsIndex.error) {
      setLoading((prev) => ({ ...prev, dataIndicator: false }));
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al obtener los datos del indicador. Por favor recargue la pagina.",
        code: getDocumentsIndex.error,
      });
    }
  }, [getIndicatorGroups.error, getDocumentsIndex.error]);

  const handlerClickGroup = (name) =>
    setSelected((prev) => ({ ...prev, group: name }));

  const handlerClickIndicator = (ind) => {
    // console.log("🚀 ~ handlerClickIndicator ~ ind:", ind);
    setSelected((prev) => ({ ...prev, indicator: true }));
    setTitleIndicator(ind?.name);
    setIdIndicator(ind?._id);
    setLoading((prev) => ({ ...prev, dataIndicator: true }));
    /*     getDocumentsIndex.request(
      metaPagination.page_size,
      metaPagination.page,
      null,
      null,
      ind?._id
    ); */
  };

  const renderDetailsIndicator = () => {
    if (!selected.group) {
      return (
        <div className="flex flex-col-reverse md:flex-col h-full w-full justify-center items-start ">
          <img src={Flecha} className="rotate-[270deg] md:rotate-0" alt="" />
          <p className="ml-20">
            Seleccione el grupo de indicadores que desea visualizar
          </p>
        </div>
      );
    }
    if (!selected.indicator) {
      return (
        <div className="flex flex-col-reverse md:flex-col h-full w-full justify-center items-start ">
          <img src={Flecha} className="rotate-[270deg] md:rotate-0" alt="" />
          <p className="ml-20">Seleccione el indicador que desea visualizar</p>
        </div>
      );
    }
    if (selected.indicator && !loading.dataIndicator) {
      return (
        <Card className="ml-1 mt-2">
          <TableTemplate
            rowKey="id"
            data={dataSource}
            columns={columns}
            className=" "
            total={metaPagination.total}
            page_size={metaPagination.page_size}
            currentPage={metaPagination.page}
            handlePageChange={handlePageChange}
          />
        </Card>
      );
    }
    return <Spin className="mt-20" />;
  };
  return (
    <div className="shadow-body ml-[3px] relative top-[-1px]">
      <div className="flex flex-col flex-auto items-start justify-start w-full p-10">
        <div className="flex w-full h-[50px] justify-center items-center">
          <AppTitle
            title={titleIndicator}
            level={4}
            className="text-lightBlue2 font-bold text-center !important"
          />
        </div>
        <div className="flex w-full flex-col-reverse md:flex-row">
          <Card className="flex flex-none w-full md:w-1/3 flex-col mt-5 md:mt-0 ml-0 md:ml-5">
            <span className="text-center mb-10">Indicadores Trazadores</span>
            <RenderCards
              list={listGroupIndicator}
              handlerClickIndicator={handlerClickIndicator}
              handlerClickGroup={handlerClickGroup}
              loading={loading.taxonomies}
            />
          </Card>
          <div className="flex flex-1 flex-col w-full justify-start ml-0 md:ml-5">
            {renderDetailsIndicator()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentIndex;
