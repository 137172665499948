import React from "react";
import GroupButton from "../../molecules/GroupButton/GroupButton";
import FormMultiSelect from "../../molecules/FormSelect/FormMultiSelect";
import FormReportYears from "../../molecules/FormSelect/FormReportYears";




const reportItems = ({ handleItemZone,  handleItemYear }) => (
  <div className=" grid justify-items-center">
    {/* Dropdown for selecting a municipality */}
    <div className="mb-4 flex flex-col items-center w-4/5">
      <div className="text-sm  mb-1  italic font-light flex flex-col items-center w-4/5">Seleccione un municipio:</div>
      <FormMultiSelect className="w-4/5" onSelectOption={handleItemZone}/>
    </div>

    {/* Dropdown for selecting a year */}
    <div className="mb-4 flex flex-col items-center w-4/5">
      <div className="text-sm   italic font-light  mb-1 flex flex-col items-center w-4/5"> Seleccione el año:</div>
      <FormReportYears className="w-4/5" onSelectZone={handleItemYear} />
    </div>

    {/* Menu for other options */}
    <div className="text-sm   italic font-light mb-1 w-4/5"> {"Seleccione el informe del tipo de indicador que desea visualizar: "}</div>
    <GroupButton />


  </div>
);

export default reportItems;
