import { ConfigProvider, Divider, Form } from "antd";
import React, { useEffect, useState } from "react";
import FormSelectSearch from "../../molecules/FormSelect/FormSelectSearch";
import FormSelect from "../../molecules/FormSelect/FormSelect";
import { selectWhiteStyle } from "../../../assets/theme/themeConfig";
import transformToSelectArray from "../../../helpers/transformToSelectArray";
import FormInput from "../../molecules/FormInput/FormInput";
import TrashIcon from "../../../assets/image/TrashIcon";
import AppButton from "../../atoms/Buttons/AppButton";

const DataPreviewFilter = ({
  listActiveFilter,
  handleDelete,
  optionsFilter,
  originColumn,
  listColumns,
  actionFilterView,
  setActionFilterView,
}) => {
  const [form] = Form.useForm();
  const [typeFilterValue, setTypeFilterValue] = useState(0);
  const [activeEditFilter, setActiveEditFilter] = useState(false);

  useEffect(() => {
    form.setFieldValue("origin_column", originColumn);
    form.resetFields(["value_filter", "filter_type"]);
    setActiveEditFilter(false);
  }, [originColumn]);

  const handleFiltersApplied = (filterApplied) => {
    const { filterType, text } = filterApplied;
    const orgColumn = filterApplied.originColumn;
    setTypeFilterValue(filterType);
    form.setFieldValue("origin_column", orgColumn);
    form.setFieldValue("filter_type", filterType);
    form.setFieldValue("value_filter", text);
    setActiveEditFilter(true);
    setActionFilterView();
  };

  const renderList = () =>
    listActiveFilter.map((item) => (
      <div className="mb-2" key={item.name}>
        <div
          className="flex justify-between cursor-pointer hover:bg-lightBlue2"
          onClick={() => handleFiltersApplied(item)}
          onKeyDown={() => handleFiltersApplied(item)}
          role="button"
          tabIndex={0}
        >
          <p>{item.name}</p>
          <button
            className="btn-filter"
            type="button"
            aria-label="delete"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
            }}
          >
            <TrashIcon />
          </button>
        </div>
        <Divider className="m-0" />
      </div>
    ));

  const handleColumnChange = () => {
    form.resetFields(["value_filter", "filter_type"]);
    setActiveEditFilter(false);
    /* const filterFound = listActiveFilter.filter(
      (item) => item.originColumn === val
    );
    if (filterFound.length > 0) {
      console.log("found " , filterFound)
      handleFiltersApplied(filterFound[0]);
    } else {
    } */
  };
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: selectWhiteStyle,
        },
      }}
    >
      <Form form={form}>
        <div className="flex flex-col sm:flex-row filter-section">
          <div className="flex flex-col">
            <p className="font-light text-sm">
              Filtros existentes y aplicados:
            </p>
            <div className="border border-[#bdb9b9] rounded-large p-3">
              {renderList()}
            </div>
          </div>
          <Divider
            className="h-auto mx-1 md:mx-10 hidden sm:block"
            style={{
              border: "1px solid #C8C8C8",
            }}
            type="vertical"
          />
          <div className="flex flex-col">
            <p className="font-light text-sm mt-5 sm:mt-0">
              Añade filtros adicionales generales para este documento:
            </p>
            <FormSelectSearch
              name="origin_column"
              label="Columna de origen"
              className="font-semibold text-color_text  "
              selectClass="text-color_text"
              // spanCls="mb-4"
              options={[
                { value: "0", label: "Ninguna" },
                ...transformToSelectArray(listColumns),
              ]}
              labelCol={{ span: 24 }}
              onChange={handleColumnChange}
            />
            <FormSelect
              name="filter_type"
              label="Tipo de filtro"
              className="font-semibold text-color_text"
              options={[{ value: 0, label: "Ninguno" }, ...optionsFilter]}
              labelCol={{ span: 24 }}
              onChange={setTypeFilterValue}
              // rules={[{ required: true, message: "Por favor seleccione una opción" }]}
            />
            {typeFilterValue !== 0 ? (
              <FormInput
                name="value_filter"
                className=" text-color_text"
                placeholder="Valor"
              />
            ) : null}
            {actionFilterView === "add" ? (
              <AppButton
                type="primary"
                className="mx-10 bg-[#4596A3]"
                title={
                  activeEditFilter ? "Actualizar filtro" : "Agregar filtro"
                }
                size="large"
              />
            ) : null}
          </div>
        </div>
      </Form>
    </ConfigProvider>
  );
};

export default DataPreviewFilter;
