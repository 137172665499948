import { Layout } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAuthSession /* , resetPassword */ } from "@aws-amplify/auth";
import { sessionStorage } from "aws-amplify/utils";
import LoginTemplate from "../../components/templates/LoginTemplate/LoginTemplate";
import auth from "../../api/auth";
import useApi from "../../api/useApi";
import token from "../../api/token";
import { useAuth } from "../../contexts/AuthContext";

const { Content } = Layout;

function LoginPage() {
  const [loading, setLoading] = useState({ login: false, sendEmail: false });
  const sendEmail = useApi(auth.recoveryPswNoAuth);
  const loginRequest = useApi(auth.login);

  const {
    setCurrentUser,
    setMessage,
    handleResetPassword,
    /* logout, */ login /* setEmailRecovery */,
  } = useAuth();

  // const { pathname } = useLocation();
  const navigate = useNavigate();
  // const [, setUser] = useLocalStorage("user")

  const handleFormSubmit = async (e) => {
    // logout();
    setLoading((prev) => ({ ...prev, login: true }));

    const response = await login(e.email, e.password);
    if (response && response.isSignedIn) {
      const { tokens: session } = await fetchAuthSession();
      const accessToken = session.idToken.toString();
      token.saveToken(accessToken);
      loginRequest.request();
    } else {
      setLoading((prev) => ({ ...prev, login: false }));
    }

    /*     try {
      await signIn({ username: e.email, password: e.password });
    } catch (error) {
      console.log("🚀 ~ handleFormSubmit ~ error:", error);
    } */
  };

  /*   const handleFormSubmit = async (e) => {
    loginRequest.request({ email: e.email, password: e.password });
    setLoading((prev) => ({ ...prev, login: true }));
  }; */
  useEffect(() => {
    if (loginRequest.data) {
      const auxData = loginRequest.data.data?.user;
      const { sub, email, firstName, lastName, role } = auxData;
      sessionStorage.setItem("firstName", firstName);
      sessionStorage.setItem("lastName", lastName);
      sessionStorage.setItem("role", role);
      sessionStorage.setItem("email", email);
      sessionStorage.setItem("sub", sub);

      setCurrentUser({ sub, email, firstName, lastName, role });
      navigate("/dashboard");
      setMessage({
        type: "success",
        title: "Resultado",
        description: "Su inicio de sesión fue exitoso.",
      });
      loginRequest.setData();
      setLoading((prev) => ({ ...prev, login: false }));
    }
    if (sendEmail.data) {
      setMessage({
        type: "success",
        title: "Resultado",
        description:
          "Si el correo electrónico esta registrado se enviara un correo para recuperar su contraseña",
      });
      setLoading((prev) => ({ ...prev, sendEmail: false }));
      sendEmail.setData();
    }
  }, [loginRequest.data, sendEmail.data]);

  useEffect(() => {
    if (loginRequest.error) {
      if (loginRequest.error === "User is pending") {
        setMessage({
          type: "error",
          title: "Error",
          description:
            "El usuario esta pendiente de aprobación por el administrador.",
        });
      } else {
        setMessage({
          type: "error",
          title: "Error",
          description:
            "Hubo un error al intentar iniciar sesión revise sus credenciales.",
          // code: loginRequest.error,
        });
        // setLoading((prev) => ({ ...prev, login: false }));
      }
      setLoading((prev) => ({ ...prev, login: false }));
    }
    if (sendEmail.error) {
      setMessage({
        type: "success",
        title: "Resultado",
        description:
          "Si el correo electrónico esta registrado se enviara un correo para recuperar su contraseña",
        code: sendEmail.error,
      });
      setLoading((prev) => ({ ...prev, sendEmail: false }));
    }
  }, [loginRequest.error, sendEmail.error]);

  const handleSendCode = async (data) => {
    const { email } = data;
    const route = await handleResetPassword(email);
    navigate(route);
  };

  /*   const handleResetPasswordNextSteps = (output, email) => {
    const { nextStep } = output;
    if (nextStep.resetPasswordStep === "CONFIRM_RESET_PASSWORD_WITH_CODE") {
      setEmailRecovery(email);
      setMessage({
        type: "info",
        title: "Resultado",
        description:
          "Se enviara un código al correo proporcionado, recuerda haber confirmado tú correo al momento de hacer el registro, de lo contrario, no será posible recuperar la contraseña.",
        // "Si este correo esta confirmado se enviara un código, de lo contrario, confirma tú correo sino no te podemos enviar el código.",
        duration: 6,
      });
      navigate("/recovery_password");
    } else {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al enviar el código. Vuelva a intentarlo de nuevo.",
      });
    }
  }; */

  return (
    <Layout>
      <Content>
        <LoginTemplate
          loading={loading.login}
          loadingRecovery={loading.sendEmail}
          onSendEmail={handleSendCode}
          onSubmitForm={(e) => handleFormSubmit(e)}
        />
      </Content>
    </Layout>
  );
}

export default LoginPage;
